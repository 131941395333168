<app-header></app-header>
<div class="container">
    <!-- <div class="col-lg-3"></div> -->
    <div class="col-lg-12 text-center" style="padding-top: 100.9688px; padding-bottom: 50px;">
        <div class="page-not-found">
            <img src="https://cdn.dribbble.com/users/143350/screenshots/14006097/media/6d7806731a1d2cfff7b37598338f5502.png?compress=1&resize=400x300">
            <p><b>Nothing to eat here...</b></p>
            <p>Let’s discover something delicious.</p>
            <button class="button border" (click)="goToHome()">Go Home</button>
        </div>
    </div>
    <!-- <div class="col-lg-3"></div> -->
</div>
<app-footer></app-footer>


import { Component, OnInit, Renderer2, HostListener, Inject, ViewChild, ElementRef, ChangeDetectorRef, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute, } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { MessageService } from '../message.service';
import { HeaderCartComponent } from '../header-cart/header-cart.component';
import { BusinessService } from '../../layouts/business/Services/app-business.service';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Constants } from 'src/app/providers/global-constants';
import { AppAddressLocationService } from 'src/app/child-layouts/address-location/Services/app-address-location.service';
import { AppProfileService } from 'src/app/child-layouts/profile/Services/app-profile.service';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { mergeMap, retry } from 'rxjs/operators';
import { SocialAuthService } from 'angularx-social-login';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'underscore';
import * as moment from 'moment';
import { AppHeaderCartService } from '../header-cart/Services/app-header-cart.service';
import { TranslateService } from '@ngx-translate/core';
declare var google;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [
    SocialAuthService,
    TranslateService,
  ]
})
export class HeaderComponent implements OnInit {

  //#region Variables

  headerWidth: boolean;
  nonHeadWidth: boolean;
  showHeader: boolean;
  showMenu: boolean;
  isLogin: boolean;
  myCart: boolean;

  public navbarOpen = false;

  public cartDetails: number;
  @ViewChild('stickyMenu') menuElement: ElementRef;

  public headerMessage: string;
  public headerCheckMessage: string;
  public searchLocationValue = '';

  public filterListSubscription: Subscription;
  public filterList: any = [];

  @ViewChild('gMap', { static: true }) gMap: ElementRef;

  public places: any = [];
  public autoCompleteService;
  public placeService;
  public map: any;

  public currentLocation: string;

  public placeComponents = Constants.placeComponents;
  public autoCompleteElements = Constants.autoCompleteElements;

  sticky = false;

  public opened: boolean;

  @ViewChild('navBurger') navBurger: ElementRef;
  @ViewChild('navMenu') navMenu: ElementRef;

  public hamburgerCondition: boolean;
  public businessLink = Constants.businessLink;
  public businessRegistLink = Constants.businessRegistLink;

  public allAddressDetails: any = [];

  public avatar: string;
  public avatarFirstName: string;

  public getGroupId = ''; // for already group order
  public groupOrderList: any = [];
  public groupCartId = '';

  public notificationList: any = [];
  public notificationListLength: any;

  public isNotification: boolean;

  public routeViewOption: any;

  public hasLocation = false;
  //#endregion

  //#region Life-Cycle hook
  public mobileMenu = false;
  constructor(
      private router: Router,
      @Inject(DOCUMENT) document,
      private messageService: MessageService,
      private businessService: BusinessService,
      public dialog: MatDialog,
      public cdr: ChangeDetectorRef,
      private addressLocationService: AppAddressLocationService,
      private profileService: AppProfileService,
      private renderer: Renderer2,
      private afMessaging: AngularFireMessaging,
      private socialAuthService: SocialAuthService,
      private toasterService: ToastrService,
      public headerCartService: AppHeaderCartService,
      private translateService: TranslateService
      
      ,
    ) {
      this.translateService.addLangs(['en', 'es']);
      this.translateService.setDefaultLang('en');
      this.translateService.use('en');

      this.onSearchLocation = _.debounce(this.onSearchLocation, 1000);
    }

  ngOnInit(): void {
    if (window.innerWidth >= 1030){
      this.hamburgerCondition = true;
    } else {
      this.hamburgerCondition = false;
    }

    // ------------------------- Map services -------------------------
    this.map = new google.maps.Map(this.gMap.nativeElement);
    this.autoCompleteService = new google.maps.places.AutocompleteService();
    this.placeService = new google.maps.places.PlacesService(this.map);

    this.messageService.getMessage().subscribe(message => {
      if (message === 'signin' || message === 'signup' || message === 'home' || message === 'checkout' ||
        message === 'Email-Verification' || message === 'group-thankyou' || message === 'page-not-found')
      {
        this.headerWidth = false;
        this.nonHeadWidth = false;
        this.showHeader = false;
        this.headerMessage = message;

      } else if (message === 'forgot-password'){
        this.showHeader = false;
        this.nonHeadWidth = false;
        this.headerWidth = false;
        this.headerMessage = message;

      } else if (message === 'business-details'){
        this.headerWidth = false;
        this.nonHeadWidth = false;
        this.showHeader = false;
        this.headerMessage = message;

      } else if (message === 'contactus' || message === 'business') {
        this.headerWidth = true;
        this.nonHeadWidth = false;
        this.showHeader = false;
        this.headerMessage = message;

      } else if (message === 'add-business') {
        this.headerWidth = false;
        this.nonHeadWidth = false;
        this.showHeader = false;
        this.headerMessage = message;

      //  this.headerWidth = false;

      } else if (message === 'verify-otp') {
        this.headerWidth = false;
        this.nonHeadWidth = false;
        this.showHeader = false;
        this.headerMessage = message;

      } else if (message === 'reset-password'){
        this.headerWidth = false;
        this.nonHeadWidth = false;
        this.showHeader = false;
        this.headerMessage = message;

      } else if (message === 'privacy-policy' || message === 'terms-condition'){
        this.headerWidth = true;
        this.nonHeadWidth = true;
        this.showHeader = true;
        this.headerMessage = message;

      } else if (message === 'add-cart' || message === 'delete-cart' || message === 'clear-cart' || message === 'Cancel-Group-Order'
                  || message === 'Leave-Group' || message === 'change-order-type' || message === 'Place-order'){
        if (this.headerMessage === 'home' || this.headerMessage === 'business'){
          this.headerMessage = 'home';
        }
        this.isLoggedin();
      }

      this.isLoggedin();
      this.userLocationFilter(message);
    });
  }

  //#endregion

  //#region Public methods

  @HostListener('window:scroll', [])
  handleScroll() {
    const windowScroll = window.pageYOffset;
    if (windowScroll < 250) {
      this.sticky = false;
    } else {
      // this.sticky = true;
    }
  }

  toggleNav() {
    this.navBurger.nativeElement.classList.toggle('is-active');
    this.navMenu.nativeElement.classList.toggle('is-active');
  }

  switchLanguage(language: string) {
    this.translateService.use(language);
  }

  // ------------------------ Go to add business page ------------------------
  addBusiness() {
    this.router.navigate(['/add-business']);
    this.renderer.removeClass(document.body, 'transparent-header');
  }

  // ------------------------ Check for logged in user ------------------------
  isLoggedin() {
    if (localStorage.getItem('chat-my-order:token')) {
      this.isLogin = true;
      this.isNotification = true;
      this.avatar = localStorage.getItem('cmo:account-avatar');
      this.avatarFirstName = localStorage.getItem('cmo:profile-firstname');
      this.cartDetails = 0;
      if (window.location.pathname.includes('/group')){
        this.routeViewOption = 'group';
        this.getAllGroupOrderCart();
      } else {
        this.routeViewOption = 'normal';
        this.getCartData();
      }
      this.onClickNotifications();
    } else {
      this.isLogin = false;
      this.isNotification = false;
    }
  }

  // ------------------------ Menu toggle ------------------------
  openMenu() {
    this.showMenu = !this.showMenu;
  }

  // ------------------------ Logout method ------------------------
  logout() {
    localStorage.removeItem('chat-my-order:token');
    localStorage.removeItem('cmo:uid');
    localStorage.removeItem('cmo:account-avatar');
    localStorage.removeItem('cmo:profile-firstname');
    localStorage.removeItem('cmo:groupId');
    localStorage.removeItem('cmo:varified');
    localStorage.removeItem('cmo:id');
    localStorage.removeItem('bugsnag-anonymous-id');
    this.messageService.sendMessage('logout');
    this.socialAuthService.authState.subscribe((user) => {
      if (user != null){
        this.socialAuthService.signOut();
      }
    });
    this.router.navigate(['/signin']);
    if (window.location.protocol === 'https:') {
      this.deleteFCMToken();
    }
  }

  // ------------------------ Delete FCM Token method ------------------------
  deleteFCMToken() {
    this.afMessaging.getToken
      .pipe(mergeMap(token => this.afMessaging.deleteToken(token)))
      .subscribe(
        (token) => {
          localStorage.removeItem('cmo:fcmToken');

        },
      );
  }

  // ------------------------ Open header cart ------------------------
  onClickMyCart(){
    const dialogRef = this.dialog.open(HeaderCartComponent, {
      // height: 'auto',
      // width: '50%',
      minHeight: 'calc(50vh - 50px)',
      panelClass: 'custom-modalbox'
    });

    this.router.events.subscribe(() => {
      dialogRef.close();
    });
  }

  // ----------------------------------- Get Group Order All Cart -----------------------------------
  getAllGroupOrderCart(){
    this.businessService.getGroupOrderAll().subscribe(
      (res) => {

        this.groupOrderList = res.data;
        this.getGroupId = res.data.group_id;
        if (this.groupOrderList.carts){
          this.groupOrderList.carts.forEach(element => {
            if (element.customer_id === localStorage.getItem('cmo:id')){
              this.groupCartId = element.id;
              this.cartDetails = element.items.length;
            }
          });
        }
      }, (err) => {

      }
    );
  }

  // ------------------------ Get header cart length ------------------------
  getCartData(){
    this.businessService.getCartDetail().subscribe(
      res => {
        const result: any = res.data;
        this.cartDetails = result.items.length;
      }
    );
  }

  onClickNotifications(){
    if (localStorage.getItem('chat-my-order:token')) {
      this.businessService.getAllNotifications().subscribe(
        res => {
          const result: any = res.data;
          this.notificationList = res.data.slice(0,5);
          const readLength = this.notificationList.filter(list => list.read === false);
          this.notificationListLength = readLength.length ? readLength.length : 0;
        }
      );
    }
  }

  // ------------------------ All Mark as read method ------------------------
  onClickAllMarkAsReadNotification(){
    this.businessService.getReadAllNotifications().subscribe(
      (res) => {

        this.onClickNotifications();
      }
    );
  }

  // ------------------------ Mark as read method ------------------------
  markAsReadNotification(id){
    this.businessService.setAsReadNotifications(id).subscribe(
      (res) => {

        this.onClickNotifications();
      }
    );
  }

  // ------------------------ Delete singal notification method ------------------------
  deleteNotification(id){
    this.businessService.deleteNotifications(id).subscribe(
      (res) => {

        this.onClickNotifications();
        setTimeout(() => {
          this.toasterService.success(res.message, Constants.toastSuccess);
        }, 1000);
      }, (err) => {
        this.dialog.closeAll();
        setTimeout(() => {
          this.toasterService.error(err.error.message, Constants.toastError);
        }, 1000);
      }
    );
  }

  // ------------------------ On Click Delete method ------------------------
  onClickDeleteAllNotification(deleteNotificationRef: TemplateRef<any>){
    this.dialog.open(deleteNotificationRef);
  }

  // ------------------------ Delete all notification method ------------------------
  deleteAllNotifications(){
    this.businessService.deleteAllNotifications().subscribe(
      (res) => {

        this.dialog.closeAll();
        this.onClickNotifications();
        setTimeout(() => {
          this.toasterService.success(res.message, Constants.toastSuccess);
        }, 1000);
      }, (err) => {
        this.dialog.closeAll();
        setTimeout(() => {
          this.toasterService.error(err.error.message, Constants.toastError);
        }, 1000);
      }
    );
  }

  // ------------------------ Get header cart length ------------------------
  getUserDetails(){
    document.getElementById('avatarName').innerHTML = localStorage.getItem('cmo:account-avatar');
  }

  // ------------------------ Navbar toggle ------------------------
  toggle() {
    this.navbarOpen = !this.navbarOpen;
  }

  // ------------------------ Search methods step ------------------------
  onSearchLocation(event): void {
    this.searchLocationValue = event.target.value;


    if (this.searchLocationValue.length >= 1){
      if (!this.currentLocation){
        const model = {
            query: this.searchLocationValue
        };
        this.getFilterByLocation(model);
      } else {
        const model = {
          query: this.searchLocationValue,
          latitude: localStorage.getItem('chat-my-order:lat'),
          longitude: localStorage.getItem('chat-my-order:lng')
        };
        this.getFilterByLocation(model);
      }
    } else {
      this.filterList = [];
      const model = { };
      this.getFilterByLocation(model);
    }
  }

  getFilterByLocation(location): void {

  if (this.filterListSubscription){
    this.filterListSubscription.unsubscribe();
  }
  this.filterListSubscription = this.businessService.getFilterByLocation(location).subscribe(
    (response) => {
      if (response && response.status) {
          this.filterList = response.data;

      }
    });
  }

  // ------------------------ Go to selected store ------------------------
  selectPlace(data){
    this.router.navigateByUrl(`.`, { skipLocationChange: true }).then(() => {
      this.router.navigate(['business-details/' + data._id]);
    });
    // window.location.replace('/business-details/' + data._id);
  }

  // ------------------------ Location search ------------------------
  getAddress() {
    const that = this;

    if (this.currentLocation) {
      this.autoCompleteService.getPlacePredictions({ types: ['geocode'], input: this.currentLocation, componentRestrictions: {country: 'us'} }, (predictions, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && predictions) {
          that.places = [];
          predictions.forEach(pred => {
            that.places.push(pred);
          });
          if (predictions.length) this.hasLocation = false;
          this.cdr.detectChanges();
        } else {
          that.places = [];
          this.hasLocation = true;
        }
      });
    }
  }

  selectLocation(place) {
    const that = this;
    that.places = [];
    that.placeService.getDetails({ placeId: place.place_id }, (details) => {

      this.currentLocation = details.formatted_address;
      // if (localStorage.getItem('chat-my-order:token')){
        localStorage.setItem('chat-my-order:lat', details.geometry.location.lat());
        localStorage.setItem('chat-my-order:lng', details.geometry.location.lng());
        localStorage.setItem('chat-my-order:currentLocation', this.currentLocation);
      // } else {
      //   localStorage.setItem('chat-my-order:unlogLat', details.geometry.location.lat());
      //   localStorage.setItem('chat-my-order:unlogLng', details.geometry.location.lng());
      //   localStorage.setItem('chat-my-order:unlogCurrentLocation', this.currentLocation);
      // }
      this.cdr.detectChanges();

      const model = {
        // query: this.searchLocationValue,
        latitude: details.geometry.location.lat(),
        longitude: details.geometry.location.lng()
      };
      this.getFilterByLocation(model);
    });
  }

  // ------------------------ Get Current Location ------------------------
  onClickCurrentLocation(){
    navigator.geolocation.getCurrentPosition((potition) => {
      this.getGeoLocation(potition.coords.latitude, potition.coords.longitude);
    })
  }

  getGeoLocation(lat: number, lng: number) {
    if (navigator.geolocation) {
        let geocoder = new google.maps.Geocoder();
        let latlng = new google.maps.LatLng(lat, lng);
        let request = {
          'location': latlng
        };
        geocoder.geocode(request, (results) => {
          if (results[0]) {
            this.currentLocation = results[0].formatted_address;
            this.selectLocation(results[0]);
          }
        });  
    }
  }

  // ----------------------- LoggedIn user current location ------------------------
  userLocationFilter(msg){
    if (msg !== 'home' && msg !== 'business'){
      if (localStorage.getItem('chat-my-order:token')){ // if loggedIn user
        this.addressLocationService.getUserAllAddresses().subscribe(
        (res) => {
          this.allAddressDetails = res.data;

        });
        if (localStorage.getItem('chat-my-order:currentLocation')){ // currentLocation is alreadt stored
          // ------------------------- Get Current location -------------------------
          this.currentLocation = localStorage.getItem('chat-my-order:currentLocation');
          const model = {
            latitude: localStorage.getItem('chat-my-order:lat'),
            longitude: localStorage.getItem('chat-my-order:lng')
          };
          this.getFilterByLocation(model);

        } else if (this.allAddressDetails.length){ // if any of address is default true
          this.allAddressDetails.forEach(address => {
            if (address.default === true){
              this.currentLocation = address.street + ', ' + address.city + ', ' + address.state + ', ' + address.country + ', '
                                      + address.zipcode;
              const model = {
                  latitude: address.latitude,
                  longitude: address.longitude
              };
              this.getFilterByLocation(model);
            }
          });
        } else { // no location is available
            this.currentLocation = '';
            const model = { };
            this.getFilterByLocation(model);
        }
      } else {
        if (localStorage.getItem('chat-my-order:currentLocation')){ // currentLocation is alreadt stored
          // ------------------------- Get Current location -------------------------
          this.currentLocation = localStorage.getItem('chat-my-order:currentLocation');
          const model = {
            latitude: localStorage.getItem('chat-my-order:lat'),
            longitude: localStorage.getItem('chat-my-order:lng')
          };
          this.getFilterByLocation(model);

        } else { // no location is available
          this.currentLocation = '';
          const model = { };
          this.getFilterByLocation(model);
        }
      }
      // else {
      //     if (localStorage.getItem('chat-my-order:unlogCurrentLocation')){
      //         this.currentLocation = localStorage.getItem('chat-my-order:unlogCurrentLocation');
      //         const model = {
      //             latitude: localStorage.getItem('chat-my-order:unlogLat'),
      //             longitude: localStorage.getItem('chat-my-order:unlogLng')
      //         };
      //         this.getFilterByLocation(model);
      //     } else {
      //         this.currentLocation = '';
      //         // const model = { };
      //         // this.getFilterByLocation(model);
      //     }
      // }
    }
  }

  handelResponsiveMenu(){
    this.mobileMenu = !this.mobileMenu;
  }

  getNotificationTime(time) {
    return moment(time).fromNow();
  }

  redirectOnClickLogo() {
    if (localStorage.getItem('chat-my-order:currentLocation')) this.router.navigate(['/business']);
    else this.router.navigate(['/home']);
  }

  //#endregion

}

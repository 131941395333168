import { Component, OnInit, Renderer2, OnDestroy, ViewChild, TemplateRef, Inject, OnChanges } from '@angular/core';
import { BusinessService } from '../Services/app-business.service';
import { Subscription } from 'rxjs';
import { BusinessModel } from '../Models/app-business.Model';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { MessageService } from '../../../shared/message.service';
import { environment } from '../../../../environments/environment';
import { AppProfileService } from '../../../child-layouts/profile/Services/app-profile.service';
import * as Pubnub from 'pubnub';
import * as moment from 'moment';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons/faFacebookSquare';
import { faTwitterSquare } from '@fortawesome/free-brands-svg-icons/faTwitterSquare';
import { ConfirmationBoxComponent } from 'src/app/shared/confirmation-box/confirmation-box.component';
import { MatDialog } from '@angular/material/dialog';
import { Constants } from 'src/app/providers/global-constants';
import Bugsnag from '@bugsnag/js';
import { ToastrService } from 'ngx-toastr';
import { ClipboardService } from 'ngx-clipboard';
import { DecimalPipe, DOCUMENT } from '@angular/common';
import { Location } from '@angular/common';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-business-detail',
  templateUrl: './business-detail.component.html',
  styleUrls: ['./business-detail.component.scss'],
  providers: [
    DecimalPipe,
  ]
})
export class BusinessDetailComponent implements OnInit, OnDestroy {

  //#region variables

  public businessDetailSubscription: Subscription;
  public businessDetail: BusinessModel = new BusinessModel();
  public businessDetailHours = [];

  public businessId: string;
  public selectedtab = Constants.seletedTab;
  public chatMessageInput: string = null;
  public chatChannel: string = null;
  public loggedInUserUid: string = null;
  public storeOpen = Constants.StoreOpen;
  public storeClose = Constants.StoreClose;
  public storeDelay = Constants.StoreDelay;

  public pubnub: any;
  public loggedInUserDetail: any;
  public chatMessages: any[] = [];
  public metaDataPayload: any = {};

  public chatPopup = false;
  public displayTypingIndicator = false;
  public contentLoad = false;
  public toggleShareBox = false;

  fbIcon = faFacebookSquare;
  tweetIcon = faTwitterSquare;

  public date = new Date();
  public isDelay = false;
  public isClose = false;
  public showChat: boolean;

  public groupId = ''; // for new group order
  public getGroupId = ''; // for already group order

  @ViewChild('groupOrderJoin') groupOrderJoinRef: TemplateRef<any>;
  @ViewChild('submittedGroupOrderRef') submittedGroupOrderRef: TemplateRef<any>;
  @ViewChild('viewGroupOrderRef') viewGroupRef: TemplateRef<any>;

  public groupOrderLoginStatus: string;
  public groupHostName: string;
  public groupOrderList: any = [];
  public isCopied = false;
  public isGroupOrder = false;
  public groupCartId = '';

  public routeViewOption: any;
  public switchGroup: boolean;

  public storeLng;
  public storeLat;
  public distanceStore;
  public storeLogo: any;
  //#endregion

  //#region life cycle hook

  constructor(
    private businessService: BusinessService,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private messageService: MessageService,
    private profileService: AppProfileService,
    public dialog: MatDialog,
    public router: Router,
    private toasterService: ToastrService,
    public clipboard: ClipboardService,
    private decimalPipe: DecimalPipe,
    private location: Location,
    @Inject(DOCUMENT) private _document: Document
  ) {
    this.loggedInUserUid = localStorage.getItem('cmo:uid');
    this.pubnub = new Pubnub({
      publishKey: environment.pubnub.pk,
      subscribeKey: environment.pubnub.sk,
      uuid: this.loggedInUserUid
    });
    this._document.defaultView.location.reload;
  }

  ngOnInit(): void {
    this.routeViewOption = this.route.snapshot.data.viewOption;
    if (this.routeViewOption === 'group'){ // check route for group order
      this.switchGroup = true;
      this.messageService.sendMessage('change-order-type');
    } else {
      this.switchGroup = false;
      this.messageService.sendMessage('change-order-type');
    }
    if (this.route.snapshot.queryParams.type){
      localStorage.setItem('cmo:tableType', this.route.snapshot.queryParams.type);

    } else if (this.route.snapshot.queryParams.group_id){ // for invite link opened
      this.groupId = this.route.snapshot.queryParams.group_id;

    } else if (localStorage.getItem('cmo:groupId')){
      this.groupId = localStorage.getItem('cmo:groupId');

    }
    this.businessId = this.route.snapshot.params.id;
    if (localStorage['chat-my-order:token']){
      this.showChat = true;
      this.getLoggedinUserData();
      this.getAllGroupOrderCart();
      if (this.groupId){
        this.getGroupOrderList('loggedIn');
      }
    } else {
      this.showChat = false;
      if (this.groupId){
        this.getGroupOrderList('loggedOut');
      }
    }

    this.renderer.removeClass(document.body, 'transparent-header');
    this.messageService.sendMessage('business-details');

    this.getBusinessDetailById();
    // this.getDiscountDetail();
  }

  //#endregion

  //#region public methods

  // -------------------------------- Get Logged In use Data --------------------------------
  getLoggedinUserData() {
    this.profileService.getProfile().subscribe((res: any) => {
      this.loggedInUserDetail = res.data;
    });
  }

  // -------------------------------- Get Business Details By Store ID --------------------------------
  getBusinessDetailById(): void {
    this.contentLoad = false;
    this.businessDetailSubscription = this.businessService.getBusinessDetailById(this.businessId).subscribe(
    (response) => {
      if (response && response.status) {
        this.contentLoad = true;
        this.businessDetail = response.data;
        console.log('logo:', response.data.logo);
        // this.storeLogo = response.data.logo.url;
        this.businessDetailHours = response.data.business_hours;
        this.storeLat = this.businessDetail.address.latitude;
        this.storeLng = this.businessDetail.address.longitude;
        this.checkCutOffTime(this.businessDetail);
        this.chatChannel = this.businessDetail.uid + '.' + this.loggedInUserUid;
        if (localStorage['chat-my-order:token'] && this.chatChannel){
          // subscribe pubnub events
          this.checkDistanceBetweenStore('login');
          this.subscribePubnubEvents();
        } else {
          this.checkDistanceBetweenStore('logout');
        }
        if (this.businessDetail.fulfillmentTypes) {
          this.businessDetail.fulfillmentTypesJoin = this.businessDetail.fulfillmentTypes.join(', ');
        }
      }
    });
  }

  // ----------------------------------- Get Group Order All Cart -----------------------------------
  getAllGroupOrderCart(){
    this.businessService.getGroupOrderAll().subscribe(
      (res) => {
        this.groupOrderList = res.data;
        this.getGroupId = res.data.group_id;
        if (this.groupOrderList.carts){
          this.groupOrderList.carts.forEach(element => {
            if (element.customer_id === localStorage.getItem('cmo:id')){
              this.groupCartId = element.id;
            }
          });
        }
      }, (err) => {
      }
    );
  }

  // ----------------------------------- Get Group Order List -----------------------------------
  getGroupOrderList(status){
    this.groupOrderLoginStatus = status;
    this.businessService.getGroupOrders(this.groupId).subscribe(
      (res) => {
        this.groupOrderList = res.data;
        if (this.groupOrderList.carts){
          this.groupOrderList.carts.forEach(element => {
            if (element.customer_id === localStorage.getItem('cmo:id')){
              this.groupCartId = element.id;
            }
          });
        }
        if (this.route.snapshot.queryParams.group_id){
          const currentCustomer = res.data.carts.filter(list => list.customer_id === localStorage.getItem('cmo:id'));
          if (currentCustomer[0] !== undefined && currentCustomer[0].submission_time !== null){
            this.dialog.open(this.submittedGroupOrderRef);
          } else {
            this.dialog.open(this.groupOrderJoinRef);
          }
        }
        this.groupHostName = res.data.group_host_name;
      }, (err) => {
        Bugsnag.notify(new Error(err.message)); // bugsnag error handle
      }
    );
  }

  // ----------------------------------- Join Group Order -----------------------------------
  joinGroupOrder(){
    this.businessService.joinGroupOrders(this.groupId).subscribe(
      (res) => {
        setTimeout(() => {
          this.toasterService.success(res.message, Constants.toastSuccess);
        }, 1000);
      }, (err) => {
        setTimeout(() => {
          this.toasterService.error(err.error.error, Constants.toastError);
        }, 1000);
      }
    );
  }

  changeToGroupOrder(){
    this.switchGroup = false;
    this.router.navigate(['/business-details/' + this.businessId + '/group']);
  }

  changeToNormalOrder(){
    this.switchGroup = true;
    this.router.navigate(['/business-details/' + this.businessId]);
  }

  // ----------------------------------- View Group Order -----------------------------------
  viewGroupOrder(viewGroupOrderRef: TemplateRef<any>){
    this.dialog.open(viewGroupOrderRef);
  }

  // ----------------------------------- Get Gorup order created time -----------------------------------
  getGroupOrderTime(time){
    return moment(time).format('MMM DD, hh:mm a');
  }

  // ----------------------------------- Get Group Order All Friends avatar -----------------------------------
  getFriendAvatar(name){
    const word = name.split(' ');
    const initials = word[0].charAt(0).toUpperCase() + '' + word[1].charAt(0).toUpperCase();
    return initials;
  }

  // ----------------------------------- Copy Group Order Link -----------------------------------
  copyLink(){
    this.isCopied = true;
    this.clipboard.copy(environment.WEBSITE_URL + '/business-details/' + this.businessId + '?group_id=' + this.getGroupId);
  }

  // ----------------------------------- Cancel Group Order -----------------------------------
  cancelgroupOrder(cancelOrderWarningRef: TemplateRef<any>){
    this.dialog.open(cancelOrderWarningRef);
  }

  // ----------------------------------- Cancel Group Order Confirmation -----------------------------------
  finalCancleGroupOrder(){
    this.dialog.closeAll();
    this.businessService.cancelGroupOrders(this.getGroupId).subscribe(
      (res) => {
        setTimeout(() => {
          localStorage.removeItem('cmo:groupId');
          this.messageService.sendMessage('Cancel-Group-Order');
          this.getAllGroupOrderCart();
          this.router.navigate(['business-details/' + this.businessId]);
          this.toasterService.success(res.message, Constants.toastSuccess);
        }, 1000);
      }, (err) => {
        setTimeout(() => {
          this.toasterService.error(err.error.error, Constants.toastError);
        }, 1000);
      }
    );
  }

  // ----------------------------------- Goto Signin/ singup page -----------------------------------
  goToSignUpIn(status){
    localStorage.setItem('chat-my-order:unlogGroupId', this.groupId);
    this.dialog.closeAll();
    if (status === 'signin'){
      this.router.navigate(['signin'], {queryParams: { redirectTo: window.location.pathname}});
    } else {
      this.router.navigate(['signup'], {queryParams: { redirectTo: window.location.pathname}});
    }
  }

  goToThankYouPage(){
    this.router.navigate(['group-thankyou'], {queryParams: { groupFriend:  this.groupCartId}});
  }

  /**
   * Chat Functionality
   */
  toggleChatPopup() {
    this.chatPopup = !this.chatPopup;
    // this.readMessageAction();
  }

  readMessageAction(timeToken): void {
    if (this.chatPopup) {
      this.pubnub.addMessageAction(
        {
          channel: this.chatChannel,
          messageTimetoken: timeToken,
          action: {
            type: 'receipt',
            value: 'message_read',
          },
        },
        (status, response) => {
        }
      );
    }
  }

  subscribePubnubEvents() {
    this.pubnub.addListener({
      status: (st: any) => {
      },
      message: (msg: any) => {
        this.chatMessages.push({
          message: msg.message,
          timestamp: moment(msg.timetoken / 10000).format('hh:mm A'),
          personal: (msg.publisher === this.loggedInUserUid)
        });

        if (msg.publisher !== this.loggedInUserUid) {
          this.readMessageAction(msg.timetoken);
        }

        this.setChannelMetaData();
        // set subscription to channel if new message
        this.pubnub.objects.setMemberships({
          channels: [msg.channel],
          uuid: this.loggedInUserUid
        }, (status, response) => {
          this.setChannelMetaData();
        });
        // this.readMessageAction();
      },
      presence: (event) => {
        if (this.chatChannel === event.channel && event.action === 'join') {
          this.businessDetail.status = 'online';
        }

        if (this.chatChannel === event.channel && event.action === 'leave') {
          this.businessDetail.status = 'offline';
        }
      },
      signal: (event) => {
        if (event.channel === this.chatChannel && event.message === 'portal_typing_on') {
          this.displayTypingIndicator = true;
        }

        if (event.message === 'portal_typing_off') {
          this.displayTypingIndicator = false;
        }
      },
      file: (event) => {
        const fileType = event.file ? (event.file.name).substr(((event.file.name).lastIndexOf('.') + 1)) : '';
        this.chatMessages.push({
          message: '',
          timestamp: moment(event.timetoken / 10000).format('hh:mm A'),
          personal: (event.publisher === this.loggedInUserUid),
          file: {
            url: event.file.url,
            type: fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png' ? 'image' : 'file'
          },
        });
        // this.readMessageAction();
      },
    });
    this.subscribeChatChannel();
    this.fetchOldChatMessages();
  }

  subscribeChatChannel() {
    // subscribe to channel
    this.pubnub.subscribe({
      channels: [this.chatChannel],
      withPresence: true
    });
    this.pubnub.hereNow({
      includeUUIDs: true,
      includeState: true
    }, (status, response) => {
      if (response.channels[this.chatChannel]) {
        this.businessDetail.status = 'online';
      }
    });
  }

  setChannelMetaData() {
    let metaData = null;
    if (this.loggedInUserDetail) {
      console.log('Image: ', this.businessDetail.logo)
      metaData = {
        customer: this.loggedInUserDetail.firstname + ' ' + this.loggedInUserDetail.lastname,
        store: this.businessDetail.name,
        customeruid: this.loggedInUserDetail.uid,
        storeuid: this.businessDetail.uid,
        storepic: this.businessDetail.logo === null ? 'https://chatmyorder.blob.core.windows.net/cmo/store/logo/place-placeholder.jpg' : this.businessDetail.logo['url'],
        customerpic: this.loggedInUserDetail.profile_picture,
        storeid: this.businessId,
        customerid: this.loggedInUserDetail._id,
        customermobile: this.loggedInUserDetail.mobile
      };
    }
    this.pubnub.objects.setChannelMetadata({
      channel: this.chatChannel,
      data: {
        custom: metaData
      }
    }).then((response) => {
      const element = document.getElementById('chat-box-down');
      element.scrollTop = element.scrollHeight;
    });
  }

  getFileUrl(channel: any, id: number, name: string): string {
    return this.pubnub['getFileUrl']({
      channel, id, name
    });
  }

  fetchOldChatMessages() {
    // get old messages
    this.pubnub.fetchMessages({
      channels: [this.chatChannel],
      withMessageActions: true,
      count: 100,
      includeMeta: true
    }, (status, response) => {
      if (!status.error && response && response.channels) {
        response.channels[this.chatChannel].forEach((msg: any) => {
          const fileType = msg && msg.message.file ? (msg.message.file.name).substr(((msg.message.file.name).lastIndexOf('.') + 1)) : '';
          this.chatMessages.push({
            message: msg && msg.message.file ? '' : msg.message,
            timestamp: moment(msg.timetoken / 10000).format('ll'),
            personal: (msg.uuid === this.loggedInUserUid),
            file: msg && msg.message.file ? {
              url: this.getFileUrl(this.chatChannel, msg && msg.message.file.id, msg && msg.message.file.name),
              type: fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png' ? 'image' : 'file'
            } : null
          });
        });
      }
    });
  }

  sendMessage() {
    const cm = this.chatMessageInput.trim();
    if (!cm || cm.length === 0) {
      return;
    }
    let metaData = null;
    if (this.loggedInUserDetail) {
      console.log(this.businessDetail)
      metaData = {
        customer: this.loggedInUserDetail.firstname + ' ' + this.loggedInUserDetail.lastname,
        store: this.businessDetail.name,
        customeruid: this.loggedInUserDetail.uid,
        storeuid: this.businessDetail.uid,
        storepic: this.businessDetail.logo === null ? 'https://chatmyorder.blob.core.windows.net/cmo/store/logo/place-placeholder.jpg' : this.businessDetail.logo['url'],
        customerpic: this.loggedInUserDetail.profile_picture,
        storeid: this.businessId,
        customerid: this.loggedInUserDetail._id,
        customermobile: this.loggedInUserDetail.mobile
      };
    }
    this.pubnub.publish({
      channel: this.chatChannel,
      message: cm,
      meta:  metaData
    }, (status, response) => {
      this.pubnub.signal({
        message: 'typing_off',
        channel: this.chatChannel
      });
    });
    this.chatMessageInput = null;
  }

  onTypingMessage(): void {
    if (this.chatMessageInput) {
      this.pubnub.signal({
        message: 'typing_on',
        channel: this.chatChannel
      });
      this.sendMessage();
    } else {
      this.pubnub.signal({
        message: 'typing_off',
        channel: this.chatChannel
      });
    }
  }

  onSelectFile(event): void {
    if (event.target && event.target.files && event.target.files.length > 0) {
      this.pubnub['sendFile']({
        channel: this.chatChannel,
        file: event.target.files[0],
        meta: {storeid: this.businessId}
      }, (status, response) => {
      });
    }
  }

  public unsubscribeAllChannel() {
    this.pubnub.unsubscribeAll();
  }

  ngOnDestroy() {
    this.unsubscribeAllChannel();
  }

  // ----------------------------------- Share box toggle -----------------------------------
  openShareBox(){
    this.toggleShareBox = !this.toggleShareBox;
  }

  // ------------------------------ Open sharebox ------------------------------
  openShareDialogBox(page){
    const dialogRef = this.dialog.open(ConfirmationBoxComponent, {
      height: '200px',
      width: '300px',
      data: {
        page: page,
        store_id: this.businessId
      },
    });

    this.router.events.subscribe(() => {
      dialogRef.close();
    });
  }

  // ------------------------------ Cut off time funcationality ------------------------------
  checkCutOffTime(businessData){
    const dt = moment().format('dddd'); // today's day
    const currentTime = moment().format('HH:mm'); // current time in 24hr format
    let delayStoreTime;

    if (businessData.settings !== null){
      delayStoreTime = businessData.settings.order_cutoff_time; // store cut off time
    }

    businessData.business_hours.forEach((data) => {
      if (data.day === dt){
        if (data.open && data.close){
          const time = data.close;
          const time24Format = moment(time, ['hh:mm A']).format('HH:mm');
          const finalDelayTime = moment(time, ['hh:mm A']).subtract(delayStoreTime, 'minutes').format('hh:mm A');
          const delay24Format = moment(finalDelayTime, ['hh:mm A']).format('HH:mm');
          const openTime24Format = moment(data.open, ['hh:mm A']).format('HH:mm');
          if (currentTime < time24Format && currentTime > delay24Format){
            this.isClose = false;
            this.isDelay = true;
          } else if (currentTime > time24Format || currentTime < openTime24Format){
            this.isClose = true;
            this.isDelay = false;
          } else {
            this.isDelay = false;
            this.isClose = false;
          }
        } else {
          this.isClose = true;
        }
      }
    });
  }

  // ----------------------------------- Create or update Group Order  -----------------------------------
  openGroupOrderDialogBox(status){
    const dialogRef = this.dialog.open(ConfirmationBoxComponent, {
      panelClass: 'custom-modalbox',
      minHeight: 'calc(50vh - 50px)',
      data: {
        store_id: this.businessId,
        page: 'Group-order',
        type: status,
        delayTime: this.isDelay,
        closeTime: this.isClose
      },
    });

    this.router.events.subscribe(() => {
      dialogRef.close();
    });
  }

  // ----------------------------------- apply discount -----------------------------------
  applyDiscount(id){
    let subTotalPrice;
    if (this.getGroupId){ // for group order
      this.businessService.getGroupOrderAll().subscribe(
        (res) => {
          subTotalPrice = res.data.sub_total;
          this.businessDetail.discounts.forEach(data => {
            if (data['_id'] === id){
              if (data['minimum_cart_total'] <= subTotalPrice){
                this.businessService.applyGroupOrderDiscount(this.getGroupId, id).subscribe(
                  (disRes) => {
                    setTimeout(() => {
                      this.toasterService.success(disRes.message, Constants.toastSuccess);
                    }, 1000);
                  }, (err) => {
                    if (err.error.error.length){
                      setTimeout(() => {
                        this.toasterService.error(err.error.error, Constants.toastError);
                      }, 1000);
                    } else {
                      setTimeout(() => {
                        this.toasterService.error(err.error.message, Constants.toastError);
                      }, 1000);
                    }
                  }
                );
              } else {
                setTimeout(() => {
                  this.toasterService.error('Cart total must be greater than $' + data['minimum_cart_total'], Constants.toastError);
                }, 1000);
              }
            }
          });
        }, (err) => {
        }
      );
    } else {
      this.businessService.getCartDetail().subscribe(
        (res) => {
          subTotalPrice = res.data.sub_total;
          this.businessDetail.discounts.forEach(data => {
            if (data['_id'] === id){
              if (data['minimum_cart_total'] <= subTotalPrice){
                this.businessService.getDiscount(id).subscribe(
                  (disRes) => {
                    setTimeout(() => {
                      this.toasterService.success(disRes.message, Constants.toastSuccess);
                    }, 1000);
                  }, (err) => {
                    if (err.error.error.length){
                      setTimeout(() => {
                        this.toasterService.error(err.error.error, Constants.toastError);
                      }, 1000);
                    } else {
                      setTimeout(() => {
                        this.toasterService.error(err.error.message, Constants.toastError);
                      }, 1000);
                    }
                  }
                );
              } else {
                setTimeout(() => {
                  this.toasterService.error('Cart total must be greater than $' + data['minimum_cart_total'], Constants.toastError);
                }, 1000);
              }
            }
          });
        }, (err) => {
      });
    }
  }

  // ----------------------------------- Open Discount Details -----------------------------------
  openOfferDetails(discountData){
    const dialogRef = this.dialog.open(ConfirmationBoxComponent, {
      height: '300px',
      width: '600px',
      data: {
        data: discountData,
        page: 'Discount-Offer'
      },
    });

    this.router.events.subscribe(() => {
      dialogRef.close();
    });
  }

  // ----------------------------------- Out Side Click event -----------------------------------
  onClickedOutside(){
    this.chatPopup = false;
  }

  checkDistanceBetweenStore(status) {
    let lat;
    let lng;
    if (status === 'login') {
      lat = localStorage.getItem('chat-my-order:lat');
      lng = localStorage.getItem('chat-my-order:lng');
    } else {
      lat = localStorage.getItem('chat-my-order:unlogLat');
      lng = localStorage.getItem('chat-my-order:unlogLng');
    }
    if (lat !== null && lng !== null){
      const distanceStore = this.getDistanceBetweenPoints(this.storeLat, this.storeLng, lat, lng);
      this.distanceStore = this.decimalPipe.transform(distanceStore, '1.2-2') + ' miles away';
    }
  }

  // -------------------------------- Find Distance between  --------------------------------
  getDistanceBetweenPoints(lat1, lng1, lat2, lng2){
    return Constants.getDistanceBetweenPoints(lat1, lng1, lat2, lng2);
  }

  goLastLocation() {
    // history.back();
    this.router.navigate(['business']);
    // const path = this._document.defaultView.location.pathname;
    // if (path.includes('business-details')) {
    //   this.router.navigateByUrl(`.`, { skipLocationChange: true }).then(() => {
    //     this.router.navigate([path]);
    //   });
    // }
  }
  //#endregion
}


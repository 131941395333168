import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CommonAPIResponse } from '../../../shared/Models/common.Model';

@Injectable({
  providedIn: 'root'
})
export class AppAddressLocationService {
  private apiUrl = environment.apiUrl;
  public loggedInUser: any;
  constructor(private http: HttpClient) { }

  // Address API
  public getUserAllAddresses(): Observable<CommonAPIResponse> {
    return this.http.get<any>(this.apiUrl + 'user/addresses');
  }

  public getUserSingleAddress(id: string): Observable<CommonAPIResponse> {
    return this.http.get<any>(this.apiUrl + 'user/addresses/' + id);
  }

  public addNewAddress(data: any): Observable<CommonAPIResponse> {
    return this.http.post<any>(this.apiUrl + 'user/addresses' , data);
  }

  public updateAddress(id: string, data: any): Observable<CommonAPIResponse> {
    return this.http.post<any>(this.apiUrl + 'user/addresses/update/' + id , data);
  }

  public deleteAddress(id: string): Observable<CommonAPIResponse> {
    return this.http.post<any>(this.apiUrl + 'user/addresses/delete/' + id, null);
  }
}

import { Component, OnInit, HostListener, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { MessageService } from '../message.service';
import { Constants } from 'src/app/providers/global-constants';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { AppHeaderCartService } from '../header-cart/Services/app-header-cart.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  providers: [
    TranslateService,
    AppHeaderCartService,
  ]
})
export class FooterComponent implements OnInit {
  @ViewChild('visibleButton') menuElement: ElementRef;
  showButton = true;
  sticky = true;
  showFooter = true;

  public phoneNo = Constants.phoneNo;
  public webSite = Constants.webSite;
  public emailAdd = Constants.emailAdd;
  public contAddress = Constants.contAddress;

  public phoneNoLink;
  public emailAddLink;

  public nextGenLink = Constants.nextGenLink;

  public businessLink = Constants.businessLink;
  public businessRegistLink = Constants.businessRegistLink;

  public contactDetails: any = [];
  constructor(
    private messageService: MessageService,
    private translateService: TranslateService,
    public dialog: MatDialog,
    public headerCartService: AppHeaderCartService,
    ) { }

  ngOnInit(): void {
    this.getContactDetails();
    this.messageService.getMessage().subscribe(message => {
      if (message === 'signin' || message === 'signup' || message === 'business') {
        this.showButton = false;
      } else {
        this.showButton = true;
      }

      this.showFooter = message === 'business' ? false : true;
      // this.showButton = message === 'signin' && message === 'signup' ? false : true;
    });
  }
  @HostListener('window:scroll', [])
  handleScroll() {
    const windowScroll = window.pageYOffset;
    if (windowScroll < 600) {
      this.sticky = true;
    } else {
      this.sticky = false;
    }
  }
  scrollHomeTo(){
    // const elementList = document.querySelectorAll('#' + className);
    // const element = elementList[0] as HTMLElement;
    console.log('Top');
    // window.scroll({ behavior: 'smooth' });
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  switchLanguage(language: string) {
    this.translateService.use(language);
  }

  openMap(mapDialogRef: TemplateRef<any>) {
    this.dialog.open(mapDialogRef);
  }

  getContactDetails() {
    this.headerCartService.getContactDetails().subscribe(res => {
      console.log(res);
      this.contactDetails = res.data;
      this.phoneNoLink = 'tel:+1' + this.contactDetails.contact_no;
      this.emailAddLink = 'mailto:' + this.contactDetails.sales_email
    }, err => {
      console.log(err);
    });
  }

  // scrollContactUsTo(className: string): void {
  //   const elementList = document.querySelectorAll('.' + className);
  //   const element = elementList[0] as HTMLElement;
  //   element.scrollIntoView({ behavior: 'smooth' });
  // }
}

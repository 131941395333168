import { BaseLoginProvider, SocialUser } from 'angularx-social-login';
import { environment } from 'src/environments/environment';


declare let AppleID: any;

export class AppleLoginProvider extends BaseLoginProvider {
  public static PROVIDER_ID: string = 'APPLE';

  protected auth2: any;

  constructor(
    private clientId: string,
    private initOptions: any = { scope: 'email name' }
  ) {
    super();
  }

  public initialize(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.loadScript(
        AppleLoginProvider.PROVIDER_ID,
        'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js',
        () => {
          AppleID.auth.init({
            clientId: this.clientId,
            response_type:'form_post',
            scope: 'email name',
            redirectURI: environment.APPLE_REDIRECT_URI,
            state: 'initial', // used to prevent CSFR
            usePopup: true,
            response_mode: 'form_post'
          });
          resolve();
        }
      );
    });
  }

  public getLoginStatus(): Promise<SocialUser> {
    return new Promise((resolve, reject) => {
      // todo: implement
      resolve();
    });
  }

  public async signIn(signInOptions?: any): Promise<SocialUser> {
    try {
      const data = await AppleID.auth.signIn();
      console.log('SignIn Data', data);
      return data;
    } catch (er) {
      console.log(er);
    }
  }

  public signOut(revoke?: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
      // AppleID doesnt have revoke method
      resolve();
    });
  }
}

export const environment = {
  apiUrl: 'https://api.chatmyorder.com/',
  WEBSITE_URL: 'https://021dee912c7924c3f60bd2f079695483.chatmyorder.com',
  BUSINESS_PORTAL_URL: 'https://business.chatmyorder.com/',
  GOOGLE_OAUTH_CLIENT_ID: '157118548742-mneg4ntptnl6af6o58dgvfk866ok7uac.apps.googleusercontent.com',
  production: false,
  stripe: {
    pk: 'pk_live_51HEVLpHovF15i47CQuWiw6rhYq5bAestEoT18xLcioA79ulI7QncJmBSXVhJ9ouhQqaYyZ29w60xWu1CTWMgwFVD003chepQxk'
  },
  pubnub: {
    pk: 'pub-c-7c49fa00-38a9-443c-b9e5-cf428fdf8c52',
    sk: 'sub-c-87931f46-b434-11ea-a40b-6ab2c237bf6e'
  },
  firebaseConfig: {
    apiKey: 'AIzaSyARivr3OdfgIqT4QK-Irg85X8hSa0cjjFE',
    authDomain: 'chatmyorder.firebaseapp.com',
    databaseURL: 'https://chatmyorder.firebaseio.com',
    projectId: 'chatmyorder',
    storageBucket: 'chatmyorder.appspot.com',
    messagingSenderId: '157118548742',
    appId: '1:157118548742:web:6c1b676a57b0530d9bac87',
    measurementId: 'G-P5WC0KDEZS',
  },
  GOOGLE_CLINT_ID: '157118548742-mneg4ntptnl6af6o58dgvfk866ok7uac.apps.googleusercontent.com',
  FB_CLINT_ID: '1019163968601617',
  APPLE_CLINT_ID: 'com.chatmyorder',
  APPLE_REDIRECT_URI: 'https://chatmyorder.com/redirect'
};


<!-- Footer
        ================================================== -->
<div id="footer" class="sticky-footer" *ngIf="showFooter===true">
    <!-- Main -->
    <div class="container">
        <div class="row">
            <!-- <div class="col-md-6 col-sm-6">
                <img class="footer-logo" src="/assets/images/custom/logo.svg" alt="">           
             
            </div> -->
            <div class="col-md-6">
                <!-- <div _ngcontent-oir-c152="" class="download-app-button">
                    <a _ngcontent-oir-c152="" href="https://play.google.com/store" target="blank">
                        <img _ngcontent-oir-c152="" src="/assets/images/custom/playstore.png" alt="Screen 1" class="i google-ione" >
                    </a>
                     <a _ngcontent-oir-c152=""  href="#">
                        <img _ngcontent-oir-c152="" src="/assets/images/custom/appstore.png" alt="Screen 1" class="google-ione" >
                    </a>
                </div> -->
            </div>
        </div>
        <div class="row">
          

            <div class="col-md-3 col-sm-6 ">
                <h4>{{'FOOTER.HELPFUL_LINK.TITLE' | translate}}</h4>
                <ul class="footer-links">
                    <li><a [routerLink]="['/terms-conditions']" target="blank">{{'FOOTER.HELPFUL_LINK.TERMS' | translate}}</a></li>
                    <li><a [routerLink]="['/privacy-policy']" target="blank">{{'FOOTER.HELPFUL_LINK.POLICY' | translate}}</a></li>
                    <li><a href="javascript:void(0);" [routerLink]="['/contactus']" routerLinkActive="current">{{'FOOTER.CONTACT_US.TITLE' | translate}}
                    </a></li>
                </ul>
            </div>

            <div class="col-md-3 col-sm-6 ">
                <h4>{{'FOOTER.SELECT_LANG' | translate}}</h4>
                <ul class="footer-links">
                    <li><a style="cursor: pointer;" (click)="switchLanguage('en')">{{'FOOTER.ENGLISH' | translate}}</a></li>
                    <li><a style="cursor: pointer;" (click)="switchLanguage('es')">{{'FOOTER.SPANISH' | translate}}</a></li>
                </ul>
            </div>
            <div class="col-md-3 col-sm-6 ">
                <h4>{{'FOOTER.BUSINESS.TITLE' | translate}}</h4>
                <ul class="footer-links">
                    <li><a href="{{businessLink}}">{{'SIGN_IN.SUBMIT' | translate}}</a></li>
                    <li><a href="{{businessRegistLink}}">{{'SIGN_UP.SUBMIT' | translate}}</a></li>
                </ul>
            </div>
            <div class="col-md-3 col-sm-6">
                <h4>{{'FOOTER.CONTACT_US.TITLE' | translate}}</h4>
                <div class="text-widget">
                    <span><a (click)="openMap(mapDialogRef)">{{contactDetails.formatted_address}}</a></span> <br> 
                    {{'FOOTER.CONTACT_US.PHONE' | translate}}: <span><a href="{{phoneNoLink}}"> {{contactDetails.formatted_contact_no}}</a></span><br> 
                    {{'FOOTER.CONTACT_US.EMAIL' | translate}}: <span> <a href="{{emailAddLink}}"> {{contactDetails.sales_email}}</a> </span><br>
                </div>

            </div>
            
        </div>
        <div class="row">
            <div class="col-md-3 col-sm-6 "></div>
            <!-- <div class="col-md-3 col-sm-6 ">
                <h4>{{'FOOTER.BUSINESS.TITLE' | translate}}</h4>
                <ul class="footer-links">
                    <li><a href="{{businessLink}}">{{'SIGN_IN.SUBMIT' | translate}}</a></li>
                    <li><a href="{{businessRegistLink}}">{{'SIGN_UP.SUBMIT' | translate}}</a></li>
                </ul>
            </div> -->
        </div>

        <!-- Copyright -->
        <div class="row">
            <div class="col-md-12">
                <div class="copyrights">
                    <i class="fa fa-copyright" aria-hidden="true"></i> 2021 <a href="{{nextGenLink}}" target="blank">NextGen US Corp.</a> 
                </div>
            </div>
        </div>

    </div>

</div>
<!-- Footer / End -->
<!-- Back To Top Button -->
<div id="backtotop" #stickyMenu [ngClass]="sticky===true?'':'visible'" *ngIf="showButton===true">
    <a href="javascript:void(0);" (click)="scrollHomeTo()"></a>
    <!-- <a href="javascript:void(0);" (click)="scrollContactUsTo('container')"></a> -->
</div>


<ng-template #mapDialogRef let-data let-ref="dialogRef">
    <div class="confirm-header text-left">
        <h3><b>{{'INFO.ADDRESS' | translate}}</b>
            <span style="float: right;">
                <a (click)="ref.close()" class="close-cart"><i class="fa fa-times"></i></a>
            </span>
        </h3>
    </div>
    <div class="confirm-body text-center">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3019.577018172864!2d-73.94145248509209!3d40.81528783922794!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2f676fee21c5d%3A0x2dadc47e59c1d237!2s69%20W%20137th%20St%2C%20New%20York%2C%20NY%2010037%2C%20USA!5e0!3m2!1sen!2sin!4v1614828351755!5m2!1sen!2sin" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0" loading="lazy"></iframe>
    </div>
    <div class="confirm-footer text-center">
        
    </div>
</ng-template>

import { Injectable } from '@angular/core';
import {  Observable } from 'rxjs';
import {  HttpClient, } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { CommonAPIResponse } from '../../../shared/Models/common.Model';
import { SignUpModel } from '../Models/app-signup.Model';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class SignUpService {
    private apiUrl = environment.apiUrl;

    constructor(private http: HttpClient, private router: Router) { }

    public signUp(signUpRequest: SignUpModel): Observable<CommonAPIResponse> {
        return this.http.post<any>(this.apiUrl + 'register', signUpRequest);
    }
    public getToken(): any {
        const u = localStorage.getItem('chat-my-order:token');

        if (u) {
            return JSON.parse(u);
        }
    }
}

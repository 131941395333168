import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { CommonAPIResponse } from '../../../shared/Models/common.Model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AppCheckoutService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  public placeOrderFromCheckout(data: object): Observable<CommonAPIResponse> {
    return this.http.post<any>(this.apiUrl + 'cart/place_order', data);
  }

  public placeTableOrderFromCheckout(data: object): Observable<CommonAPIResponse> {
    return this.http.post<any>(this.apiUrl + 'cart/place_table_order', data);
  }

  public updateOrderType(data: object): Observable<CommonAPIResponse> {
    return this.http.post<any>(this.apiUrl + 'cart/update_order_type', data);
  }

  public getAllCoupons(): Observable<CommonAPIResponse> {
    return this.http.get<any>(this.apiUrl + 'coupons/all');
  }

  public applyCouponCode(code): Observable<CommonAPIResponse> {
    return this.http.get<any>(this.apiUrl + 'cart/apply_coupon/' + code);
  }

  public removeCouponCode(): Observable<CommonAPIResponse> {
    return this.http.get<any>(this.apiUrl + 'cart/remove_coupon');
  }
}

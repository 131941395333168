import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, finalize, map, filter } from 'rxjs/operators';
import { throwError } from 'rxjs';
import {Router, NavigationEnd} from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import { SignUpService } from '../layouts/signup/Services/app-signup.service';
import { Constants } from '../providers/global-constants';

@Injectable()
export class AuthInterceptorInterceptor implements HttpInterceptor {
  constructor(private inject: Injector, private router: Router, private toasterService: ToastrService) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const auth = this.inject.get(SignUpService);
    // localStorage.setItem('cmo: last-location', window.location.pathname);
    if (auth.getToken()) {
      const cloned = request.clone({
        setHeaders: {
          Authorization: `Bearer ${auth.getToken()}`
        }
      });
      return next.handle(cloned).pipe(
        map(event => {
          return event;
        }),
        catchError(err => {
          if (err.status === 401) {
            this.toasterService.info(Constants.sessionExpire, Constants.sessionExpireHeader);
            localStorage.removeItem('chat-my-order:token');
            localStorage.removeItem('cmo:uid');
            localStorage.removeItem('cmo:account-avatar');
            localStorage.removeItem('cmo:profile-firstname');
            localStorage.removeItem('cmo:groupId');
            localStorage.removeItem('cmo:varified');
            localStorage.removeItem('cmo:id');
            localStorage.removeItem('bugsnag-anonymous-id');
            this.router.navigate(['signin'], {queryParams: { redirectTo: window.location.pathname}});
          }
          return throwError(err);
        }),
        finalize(() => { })
      );
    } else {
      // return next.handle(request)
      return next.handle(request).pipe(
        map(event => {
          return event;
        }),
        catchError(err => {
          console.log(err);
          if (err.status === 401) {
            this.toasterService.info(Constants.sessionExpire, Constants.sessionExpireHeader);
            localStorage.removeItem('chat-my-order:token');
            localStorage.removeItem('cmo:uid');
            localStorage.removeItem('cmo:account-avatar');
            localStorage.removeItem('cmo:profile-firstname');
            localStorage.removeItem('cmo:groupId');
            localStorage.removeItem('cmo:varified');
            localStorage.removeItem('cmo:id');
            localStorage.removeItem('bugsnag-anonymous-id');
            this.router.navigate(['signin'], {queryParams: { redirectTo: window.location.pathname}});
          }
          return throwError(err);
        }),
        finalize(() => { })
      );
    }
  }
}

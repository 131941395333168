<section class="hero_in detail_page listing-slider mfp-gallery-containerpage-title"
    style="background-image:url(assets/images/custom/Food-Background-Wallpaper.jpg);">

    <div class="wrapper opacity-mask" data-opacity-mask="rgb(203 187 187 / 38%)" style="background-color: rgb(203 187 187 / 38%);">
        <div class="container">
            <div class="main_info">
                <a (click)="goLastLocation()"><i class="fa fa-arrow-left arrow-top" title="Go back" aria-hidden="true"></i></a>
                <div class="row" *ngIf="contentLoad">
                    <div class="col-sm-2">
                        <div class="store-proflie" *ngIf="businessDetail?.logo">
                            <img src={{businessDetail?.logo?.url}} />
                        </div>
                        <div class='store-proflie' *ngIf="!businessDetail?.logo">
                            <img src="https://chatmyorder.blob.core.windows.net/cmo/store/logo/place-placeholder.jpg">
                        </div>
                    </div>

                    <div class="col-xl-4 col-lg-6 col-md-4 col-sm-4">

                        <h1>{{businessDetail.name}}</h1>
                        <span> <b style="color:#333;">{{businessDetail?.address?.street}} |
                                <a href="tel:{{businessDetail.phone}}" style="color:#333;">
                                    {{businessDetail.phone | mask: '(000) 000-0000'}}</a>
                                |
                                <a href="mailto:{{businessDetail.email}}"
                                    style="color:#333;">{{businessDetail.email}}</a>  </b></span> <br />

                        <span>
                            <ng-container *ngIf="isClose">
                                <span style="color: red">
                                    <i class="fa fa-ban fa-bg" aria-hidden="true"></i> <b> {{storeClose}} </b>
                                </span>
                            </ng-container>

                            <ng-container *ngIf="!isClose && !isDelay">
                                <span style="color: #00a4ef">
                                    <i class="im im-icon-Clothing-Store" style="font-weight: 900;"></i><b>
                                        {{storeOpen}} </b>
                                </span>
                            </ng-container>

                            <ng-container *ngIf="isDelay">
                                <span style="color: red">
                                    <i class="fa fa-ban fa-bg" aria-hidden="true"></i> <b> {{storeDelay}} </b>
                                </span>
                            </ng-container>
                        </span>
                    </div>
                    <div class="col-xl-8 col-lg-4 col-md-6 col-sm-6">

                        <div class="buttons clearfix">
                            <span> <a href="javascript:void(0)" class="btn_hero wishlist"
                                    (click)="openGroupOrderDialogBox('add')"
                                    *ngIf="businessId !== groupOrderList?.store_details?._id">
                                    &nbsp; <i class="fa fa-group"></i><b> {{'BUSINESS_DETAILS.START_GROUP' | translate}}</b>
                                </a></span>
                            <span> <a href="javascript:void(0)" class="btn_hero wishlist"
                                    (click)="viewGroupOrder(viewGroupOrderRef)"
                                    *ngIf="(businessId === groupOrderList?.store_details?._id) && groupCartId">
                                    <ng-container *ngIf="loggedInUserDetail?._id === groupOrderList?.customer_id">
                                        &nbsp; <i class="fa fa-group"></i><b> {{'BUSINESS_DETAILS.VIEW_GROUP' | translate}}</b>
                                    </ng-container>
                                </a></span>
                            <span>
                                <a class="b-share btn_hero wishlist" style="margin-left: 5px;" (click)="openShareDialogBox('Share-Button')"> <i
                                        class="fa fa-share-alt"></i>
                                        {{'BUSINESS_DETAILS.SHARE' | translate}}</a>
                            </span>
                        </div>
                    </div>
                </div>

                <div class="row" *ngIf="!contentLoad">
                    <div class="col-sm-2">
                        <div class="store-proflie">
                            <ngx-skeleton-loader appearance="square" [theme]="{ width: '140px', height: '100px' }">
                            </ngx-skeleton-loader>
                        </div>
                    </div>

                    <div class="col-xl-4 col-lg-6 col-md-4 col-sm-4">
                        <ngx-skeleton-loader [theme]="{width: '150px', height: '30px', 'margin-bottom': '1px'}">
                        </ngx-skeleton-loader>
                        <br>
                        <ngx-skeleton-loader [theme]="{width: '350px', height: '25px', 'margin-top': '1px'}">
                        </ngx-skeleton-loader>
                        <br>
                        <ngx-skeleton-loader [theme]="{width: '150px', height: '25px', 'margin-top': '0px'}">
                        </ngx-skeleton-loader>
                    </div>
                    <div class="col-xl-8 col-lg-4 col-md-6 col-sm-6">

                        <ngx-skeleton-loader [theme]="{width: '180px', height: '30px', 'margin-bottom': '1px'}">
                        </ngx-skeleton-loader>
                        <ngx-skeleton-loader [theme]="{width: '100px', height: '30px', 'margin-bottom': '1px'}">
                        </ngx-skeleton-loader>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>

<!-- <section class="fullwidth">

    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 business-top">
                <div id="titlebar" class="listing-titlebar padding-buttom-0">


                    <div class="row  padding-bottom-20" *ngIf="!contentLoad">
                        <div class="col-lg-2 col-md-2">
                            <div class="store-proflie">
                                <ngx-skeleton-loader appearance="square" [theme]="{ width: '140px', height: '100px' }">
                                </ngx-skeleton-loader>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-6 col-md-6">
                            <ngx-skeleton-loader [theme]="{width: '150px', height: '30px', 'margin-bottom': '1px'}">
                            </ngx-skeleton-loader>
                            &nbsp;&nbsp;&nbsp;
                            <ngx-skeleton-loader [theme]="{width: '100px', height: '30px', 'margin-bottom': '1px'}">
                            </ngx-skeleton-loader>
                            <br>
                            <ngx-skeleton-loader [theme]="{width: '550px', height: '25px', 'margin-top': '1px'}">
                            </ngx-skeleton-loader>

                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-6">
                            <ngx-skeleton-loader [theme]="{width: '150px', height: '30px', 'margin-bottom': '1px'}">
                            </ngx-skeleton-loader>
                            &nbsp;&nbsp;&nbsp;
                            <ngx-skeleton-loader [theme]="{width: '100px', height: '30px', 'margin-bottom': '1px'}">
                            </ngx-skeleton-loader>
                            <br>
                            <ngx-skeleton-loader [theme]="{width: '550px', height: '25px', 'margin-top': '1px'}">
                            </ngx-skeleton-loader>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<section class="fullwidth bg-secound" *ngIf="(businessId === groupOrderList?.store_details?._id) && groupCartId">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 text-center swi-color" *ngIf="!switchGroup">
                <h3 class="change-title">{{'BUSINESS_DETAILS.SWITCH_ORDER.SIWTCH_TO_GROUP' | translate}}</h3>
                <span>
                    <button class="button btm" (click)="changeToGroupOrder()">{{'BUSINESS_DETAILS.SWITCH_ORDER.YES_SWITCH' | translate}}</button>
                    <!-- <a class="b-change" (click)="changeToGroupOrder()"><i _ngcontent-ccs-c191=""
                            class="im im-icon-Add-User " style="font-weight: 900;"></i> {{'BUSINESS_DETAILS.SWITCH_ORDER.GROUP_ORDER' | translate}}</a> -->
                </span>
            </div>

            <div class="col-sm-12 text-center swi-color" *ngIf="switchGroup">
                <h3 class="change-title">{{'BUSINESS_DETAILS.SWITCH_ORDER.SIWTCH_TO_NORMAL' | translate}}</h3>
                <span >
                    <button class="button btm" (click)="changeToNormalOrder()">{{'BUSINESS_DETAILS.SWITCH_ORDER.YES_SWITCH' | translate}}</button>
                    <!-- <a class="b-change" (click)="changeToNormalOrder()"> <i _ngcontent-ccs-c191=""
                            class="im im-icon-Male " style="font-weight: 900;"></i> {{'BUSINESS_DETAILS.SWITCH_ORDER.NORMAL_ORDER' | translate}}</a> -->
                </span>
            </div>
        </div>
    </div>
</section>
<section class="fullwidth">
    <div class="container">
        <div class="row" style="margin-top: 20px;">
            <div class="col-lg-4" *ngFor="let discount of businessDetail.discounts">
                <div class="copan parent">
                    <div class="left">
                        <h6 class="cupon">{{discount.title}}
                            <!-- <span *ngIf="discount.type === 'FIXED'" style="color: #5aca56;">{{discount.amount | currency: 'USD'}} OFF</span>
                            <span *ngIf="discount.type === 'PERCENTAGE'" style="color: #5aca56;">{{discount.amount}}% OFF</span> -->
                        </h6>
                        <div class="">
                            <button type="submit" (click)="applyDiscount(discount._id)"
                                class="medium button-border btn-left"
                                [disabled]="discount.end_date < discount.start_date">{{'BUSINESS_DETAILS.DISCOUNT.APPLY_NOW' | translate}}</button>
                            <button type="submit" class="medium button-border btn-right"
                                (click)="openOfferDetails(discount)">{{'BUSINESS_DETAILS.DISCOUNT.OFFER' | translate}}</button>
                        </div>
                    </div>
                    <div class="right">
                        <img src="assets/images/custom/cupan-1.png" alt="cupan" class="img-hed">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="fullwidth">
    <div class="container">
        <div class="col-lg-12 col-md-12  col-sm-12">




            <!-- Tabs Content -->
            <div class="style-1 box-tab padding-10">

                <!-- Tabs Navigation -->
                <ul class="tabs-nav" role="tablist">
                    <li role="presentation" [ngClass]="selectedtab === 'OrderOnline' ? 'active' : ''">
                        <a data-target="#tab5b" role="tab" data-toggle="tab" (click)="selectedtab = 'OrderOnline'">{{'BUSINESS_DETAILS.MENUS.ORDER_ONLINE' | translate}}</a>
                    </li>
                    <li role="presentation" [ngClass]="selectedtab === 'Menu' ? 'active' : ''">
                        <a data-target="#tab1b" role="tab" data-toggle="tab" (click)="selectedtab = 'Menu'">{{'BUSINESS_DETAILS.MENUS.MENU_GALLERY' | translate}}</a>
                    </li>
                    <li role="presentation" [ngClass]="selectedtab === 'StoreGallery' ? 'active' : ''">
                        <a data-target="#tab6b" role="tab" data-toggle="tab"
                            (click)="selectedtab = 'StoreGallery'">{{'BUSINESS_DETAILS.MENUS.STORE_GALLERY' | translate}}</a>
                    </li>
                    <li role="presentation" [ngClass]="selectedtab === 'About' ? 'active' : ''">
                        <a data-target="#tab2b" role="tab" data-toggle="tab" (click)="selectedtab = 'About'">{{'BUSINESS_DETAILS.MENUS.INFO' | translate}}</a>
                    </li>
                </ul>

                <!-- Tabs Content -->
                <div class="tabs-container">
                    <div *ngIf="selectedtab === 'Menu'" class="tab-content" id="tab1b">
                        <app-menu [menu]="businessDetail.productInfo?.menu"></app-menu>
                    </div>
                    <div *ngIf="selectedtab === 'About'" class="tab-content" id="tab2b">
                        <app-about [businessId]="businessId"></app-about>
                    </div>
                    <div *ngIf="selectedtab === 'OrderOnline'" class="tab-content" id="tab5b">


                        <app-order-online [delayTime]="isDelay" [closeTime]="isClose"></app-order-online>
                    </div>
                    <div *ngIf="selectedtab === 'StoreGallery'" class="tab-content" id="tab6b">
                        <app-store-gallery></app-store-gallery>
                    </div>
                </div>
            </div>










        </div>
    </div>
</section>

<section class="fullwidth">
    <div class="container">
        <div class="row">




        </div>
    </div>
</section>


<!-- <div id="chat"> -->

<div id="chat-circle" class="btn btn-raised margin-right-20" *ngIf="!chatPopup" title="Chat with us! " (click)="chatPopup = !chatPopup">
    <div id="chat-overlay"></div>
    <i class="maters fa fa-wechat" style="text-align: center;"></i>
</div>

<div class="chat-box margin-bottom-30" *ngIf="chatPopup" (outSideClick)="onClickedOutside()">
    <div class=" chat-title">
        <figure class="avatar" id="store-chatlogo">
            <ng-container *ngIf="businessDetail.logo !== null">
                <img [src]="businessDetail?.logo?.url">
            </ng-container>
            <ng-container *ngIf="!businessDetail.logo">
                <img src="https://chatmyorder.blob.core.windows.net/cmo/store/logo/place-placeholder.jpg">
            </ng-container>
        </figure>
        <span id="store-chatname">{{businessDetail.name}}</span>
        <span class="chat-box-toggle fa fa-times-circle" (click)="toggleChatPopup()"></span>
    </div>
    <div class="chat-box-body messages" id="chat-box-down">

        <div class="messages-content" id="chat-loginmsg">
            <ng-container *ngIf="showChat">
                <ng-container *ngIf="chatMessages?.length">
                    <div class="message" *ngFor="let msg of chatMessages"
                        [ngClass]="{'message-personal': msg.personal}">
                        <figure class="avatar" *ngIf="!msg.personal">
                            <ng-container *ngIf="businessDetail.logo">
                                <img [src]="businessDetail.logo">
                            </ng-container>
                            <ng-container *ngIf="!businessDetail.logo">
                                <img
                                    src="https://chatmyorder.blob.core.windows.net/cmo/store/logo/place-placeholder.jpg">
                            </ng-container>
                        </figure>
                        <span class="msg-b" *ngIf="!msg.file">{{msg.message}}</span>
                        <figure class="image-chat" *ngIf="msg.file && msg.file?.type === 'image'">
                            <img [src]="msg.file?.url">
                        </figure>
                        <div class="file-chat" *ngIf="msg.file && msg.file?.type === 'file'">
                            <a class="fa fa-file" target="blank" aria-hidden="true" href={{msg.file?.url}} download></a>
                        </div>
                        <div class="timestamp">
                            {{msg.timestamp}}
                        </div>
                    </div>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="!showChat">
                <div class="text-center">
                    <a class="chat-link" [routerLink]="['/signin']"
                        [queryParams]="{ redirectTo: '/business-details/' + businessId }">
                        Please sign in to start chat with store.
                    </a>
                </div>
            </ng-container>
        </div>
        <!--chat-log -->
    </div>
    <div class="message typing-indicator" *ngIf="displayTypingIndicator">Typing...</div>
    <div class="conversation-compose">
        <div class="emoji"></div>
        <input type="text" class="message-input" placeholder="{{'BUSINESS_DETAILS.TYPE_MESSAGE' | translate}}" [(ngModel)]="chatMessageInput"
            (keyup.enter)="onTypingMessage()" />
        <div class="photo">
            <input type="file" (change)="onSelectFile($event)" />
            <label for="chat-file-upload" class="custom-file-upload">
                <i class="fa fa-paperclip"></i>
            </label>
            <input id="chat-file-upload" type="file" (change)="onSelectFile($event)" />
        </div>
        <button type="submit" class=" send message-submit" (click)="sendMessage()" [disabled]="!showChat" autofocus>
            <div class="circle"><i class="fa fa-paper-plane"></i></div>
        </button>
    </div>
</div>

<ng-template #groupOrderJoin let-data let-ref="dialogRef">
    <div class="confirm-header text-left">
        <h3><b>Join {{groupHostName}}'s group order</b>
            <span style="float: right;">
                <a (click)="ref.close()" class="close-cart"><i class="fa fa-times"></i></a>
            </span>
        </h3>
    </div>
    <div class="confirm-body text-center" style="font-weight: lighter;">
        <ng-container *ngIf="groupOrderLoginStatus === 'loggedIn'">
            <h5>{{groupHostName}} will check out and pay after you add items to the bag</h5>
        </ng-container>

        <ng-container *ngIf="groupOrderLoginStatus === 'loggedOut'">
            <h5>New on website? <a class="groupOrderSignIn" style="color: #00a4ef;"
                    (click)="goToSignUpIn('signup')">Sign up</a></h5>
            <p class="divider line one-line">or</p>
            <h5>Already have an account? <a class="groupOrderSignIn" style="color: #00a4ef;"
                    (click)="goToSignUpIn('signin')">Sign in</a></h5>

        </ng-container>
    </div>
    <div class="confirm-footer text-center" *ngIf="groupOrderLoginStatus === 'loggedIn'">
        <button type="button" class="button  btn-outline-secondary" (click)="joinGroupOrder(); ref.close()">Join group
            order</button>
    </div>
</ng-template>

<ng-template #viewGroupOrderRef let-data let-ref="dialogRef">
    <div class="confirm-header text-left">
        <div class="row col-md-12">
            <h3><b>Group order at {{groupOrderList.store_details.name}}</b>

                <span style="float: right;">
                    <a (click)="ref.close()" class="close-cart"><i class="fa fa-times"></i></a>
                </span>
            </h3>
            <p>Check out and pay when everyone has added to the order.</p>
        </div>

    </div>
    <div class="confirm-body text-left">

        <b>Order details</b><a href="javascript:void(0)" class="links-change"
            (click)="openGroupOrderDialogBox('update')">Change</a><br />
        <ng-container *ngIf="groupOrderList.group_order_type === 'Delivery'">
            <p class="remove-gap">Deliver to {{groupOrderList?.delivery_address?.street}},
                {{groupOrderList?.delivery_address?.city}},
                {{groupOrderList?.delivery_address?.country}},
                {{groupOrderList?.delivery_address?.zipcode}}</p>
            <p>{{getGroupOrderTime(groupOrderList.created)}}</p>
        </ng-container>

        <ng-container *ngIf="groupOrderList.group_order_type === 'Pickup'">
            <p class="remove-gap">Pickup from {{groupOrderList?.store_details?.address?.street}},
                {{groupOrderList?.store_details?.address?.city}},
                {{groupOrderList?.store_details?.address?.country}},
                {{groupOrderList?.store_details?.address?.zipcode}}</p>
            <p>{{getGroupOrderTime(groupOrderList.created)}}</p>
        </ng-container>

        <b>{{(groupOrderList.carts.length - 1)}} Friends</b>
        <a class="links-change" (click)="copyLink()" *ngIf="!isCopied">Copy link</a>
        <a class="links-change" *ngIf="isCopied">Link copied!</a>
        <a class="links-change-group" (click)="openShareDialogBox('Share-Button-Group')">Share link</a>&nbsp;
        <p>Invite up to 20 friends. Your friends appear here once they join the group order.</p>
        <div class="u-flex u-flex-wrap">
            <ng-container *ngFor="let friend of groupOrderList.carts">
                <span class="groupOrder-friends" title="You" *ngIf="friend.group_admin">You</span>
                <span class="groupOrder-friends2" title="{{getFriendAvatar(friend.customer_name)}}"
                    *ngIf="!friend.group_admin">{{getFriendAvatar(friend.customer_name)}}</span>
            </ng-container>
        </div>

        <hr>
        <a class="link-but" (click)="cancelgroupOrder(cancelOrderWarningRef)">Cancel group order</a><br />
        <hr>
        <div class="text-center">
            <button class="button margin-top-20" name="submit" (click)="ref.close()">Done</button>
        </div>
    </div>
</ng-template>

<ng-template #cancelOrderWarningRef let-data let-ref="dialogRef">
    <div class="confirm-header text-center">
        <div class="confirm-header-title">
            <h4><b>Confirm Cancel</b></h4>
        </div>
    </div>
    <div class="confirm-body text-center">
        <p>
            <span class="text-danger">Do you want to cancel the group order?</span>
        </p>
    </div>
    <div class="confirm-footer text-center">
        <button type="button" class="button  btn-outline-secondary" (click)="finalCancleGroupOrder()">Yes,
            Cancel</button>
        <button type="button" class="button  btn-danger" (click)="ref.close()">No, Don't Cancel</button>
    </div>
</ng-template>

<ng-template #submittedGroupOrderRef let-data let-ref="dialogRef">
    <div class="confirm-header text-center">
        <div class="confirm-header-title">
            <h4><b>You have already submitted your items to {{groupOrderList.group_host_name}}'s group order</b></h4>
        </div>
    </div>
    <div class="confirm-body text-center">
        <p>
            <span class="text-danger">To modify your items, contact {{groupOrderList.group_host_name}}.</span>
        </p>
    </div>
    <div class="confirm-footer text-center">
        <button type="button" class="button  btn-outline-secondary" (click)="goToThankYouPage();ref.close()">Got
            it</button>
    </div>
</ng-template>
<!-- </div> -->
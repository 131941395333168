import { Component, OnInit, Input, Inject, HostListener, ViewChild, ElementRef, ChangeDetectorRef, TemplateRef, Injector } from '@angular/core';
import { BusinessService } from 'src/app/layouts/business/Services/app-business.service';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Constants } from 'src/app/providers/global-constants';
import * as moment from 'moment';
import { ClipboardService } from 'ngx-clipboard';
import { AppCheckoutService } from 'src/app/layouts/checkout/Services/app-checkout.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { BusinessDetailComponent } from 'src/app/layouts/business/business-detail/business-detail.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-confirmation-box',
  templateUrl: './confirmation-box.component.html',
  styleUrls: ['./confirmation-box.component.scss'],
  providers: [
    BusinessDetailComponent
  ]
})
export class ConfirmationBoxComponent implements OnInit {

  @ViewChild('confirmGroupOrderReplace') groupOrderReplaceRef: TemplateRef<any>;
  public storeName: string;

  public pageName: string;

  submitted = false;
  public isNumber: boolean;

  public mobileNo: string;
  public mask = Constants.maskMobilePattern;
  public emailPattern = Constants.emailPattern;

  public emailPat = Constants.emailPat;
  public mobileReq = Constants.mobileReq;
  public mobileLen = Constants.mobileLen;

  public pageData: any = [];
  public startDate = '';
  public endDate = '';

  public offerView: any = [];
  public couponCode = '';
  public isCopy = false;
  public alreadyAppliedCode = '';

  @ViewChild('gMap', { static: true }) gMap: ElementRef;

  public places: any = [];
  public autoCompleteService;
  public placeService;
  public map: any;

  public currentLocation: string;

  public placeComponents = Constants.placeComponents;
  public autoCompleteElements = Constants.autoCompleteElements;

  public groupOrderForm: FormGroup;
  public invitationList: any = [];

  public tabName = 'Delivery';
  public groupId: string;
  public groupOrderType: string;
  public deliveryObj: any;
  public isShowGroupCreate: boolean;

  public storeLat: any;
  public storeLng: any;
  public storeDeliveryRadiusMiles: any;
  public inRadius: boolean;
  public radiusSpinner = false;

  public groupOrderData: any = [];
  public geoLocation: any;

  public isDelay = false;
  public isClose = false;

  public storeOpen = Constants.StoreOpen;
  public storeClose = Constants.StoreClose;
  public storeDelay = Constants.StoreDelay;

  public shareUrl: string;
  public hasLocation = false;
  // public Image: any = [];
  // public ImageNum: number = 0;
  // public ImageLength: number;
  // public ImageDefault: string;

  constructor(
      private businessService: BusinessService,
      private router: Router,
      private dialogRef: MatDialogRef<ConfirmationBoxComponent>,
      @Inject(MAT_DIALOG_DATA) private matData: any,
      public dialog: MatDialog,
      public clipboard: ClipboardService,
      private checkoutService: AppCheckoutService,
      private toasterService: ToastrService,
      public cdr: ChangeDetectorRef,
      public fb: FormBuilder,
      public inject: Injector
    ) {
      this.deliveryObj = {
        customer_street: '',
        customer_city: '',
        customer_state: '',
        customer_country: '',
        customer_zipcode: '',
        customer_lat: '',
        customer_lng: '',
      };
  }

  invite(){
    this.groupOrderForm = this.fb.group({
      invites: this.fb.array([this.createInvitations()]) ,
    });
    this.invitationList = this.groupOrderForm.get('invites') as FormArray;
  }

  createInvitations(): FormGroup {
    return this.fb.group({
      email: null,
      mobile: null,
    });
  }

  addInvitation() {
    this.invitationList.push(this.createInvitations());
  }

  removeInvitation(i: number) {
    this.invitationList.removeAt(i);
    if (!this.invitationList.length) {
      this.invitationList.push(this.createInvitations());
    }
  }

  get getInvitesControls() {
    return this.groupOrderForm.get('invites') as FormArray;
  }

  ngOnInit(): void {
    this.invite();
    this.storeLng = '';
    this.storeLat = '';
    // ------------------------- Map services -------------------------
    this.map = new google.maps.Map(this.gMap.nativeElement);
    this.autoCompleteService = new google.maps.places.AutocompleteService();
    this.placeService = new google.maps.places.PlacesService(this.map);

    // ---------------------------- Get location ----------------------------
    this.checkCurrentLocation();

    this.pageName = this.matData.page;

    if (this.pageName === 'Order-Online' || this.pageName === 'Share-Button' || this.pageName === 'Group-order' ||
        this.pageName === 'Order-List' || this.pageName === 'Order-Detail' || this.pageName === 'Share-Button-Group'){
        this.groupOrderType = this.matData.type;
        // this.getGroupOrderAllcart();
      if (localStorage.getItem('chat-my-order:token')){
        this.isShowGroupCreate = true;
      } else {
        this.isShowGroupCreate = false;
      }
      if (this.groupOrderType === 'add') {
        this.isDelay = this.matData.delayTime;
        this.isClose = this.matData.closeTime;
      }
      this.businessService.getBusinessDetailById(this.matData.store_id).subscribe(
      (res) => {
        this.storeLat = res.data.address.latitude;
        this.storeLng = res.data.address.longitude;
        this.storeDeliveryRadiusMiles = res.data.settings.delivery_radius_miles;
        this.storeName = res.data.name;
        this.getGroupOrderAllcart();
      }, (err) => {

      });
    } else if (this.pageName === 'Discount-Offer' || this.pageName === 'Offer-code-view'){
      this.pageData = this.matData.data;
      this.startDate = moment(this.matData.data.start_date).format('MM/DD/yyyy');
      this.endDate = moment(this.matData.data.end_date).format('MM/DD/yyyy');
    } else if (this.pageName === 'Offer-view'){
      this.offerView = this.matData.offerData;
      this.getGroupOrderAllcart();
      if (this.matData.code){
        this.alreadyAppliedCode = this.matData.code;
      }
    }
    // else if(this.pageName === 'Menu-Gallery' || this.pageName === 'Store-Gallery'){
    //   this.Image = this.matData.menuImageArray
    //   this.ImageNum = this.matData.imageNumber
    //   this.ImageLength = (this.Image.length - 1)
    //   this.ImageDefault = this.matData.defaultImg
    // }
  }

  modalClose(): void {
    this.submitted = true;
    if (this.pageName === 'Signin' || this.pageName === 'Signup'){
      if (this.mobileNo.length === 10){
        this.dialogRef.close({phoneData: this.mobileNo});
      }
    } else {
      this.dialogRef.close('CONFIRM_YES');
    }
  }

  modalDismiss(){
    this.dialogRef.close('CONFIRM_NO');
  }

  goToStore(){
    this.dialogRef.close('CHANGE_STORE');
  }

  // -------------------------------- Number only method --------------------------------
  numberOnly(event): boolean{
    return Constants.numberOnly(event);
  }

  // ----------------------------------- number validation -----------------------------------
  checkNumberLength(){
    if (this.mobileNo !== undefined){
      if (this.mobileNo.length !== 10){
        return this.isNumber = true;
      } else {
        return this.isNumber = false;
      }
    }
  }

  offerViewFullDetails(offer){
    const dialogRef = this.dialog.open(ConfirmationBoxComponent, {
      height: '300px',
      width: '380px',
      data: {
        data: offer,
        page: 'Offer-code-view'
      },
    });

    this.router.events.subscribe(() => {
      dialogRef.close();
    });
  }

  applyCode(offer){
    this.dialogRef.close({promoCode: offer.code, minCartTotal: offer.minimum_cart_total});
  }

  copyCode(code){
    this.isCopy = true;
    this.clipboard.copy(code);
  }

  onClickDeleteCoupon(){
    if (this.groupId){
      this.businessService.removeGroupOrderCoupon(this.groupId).subscribe(
        (res) => {
          this.alreadyAppliedCode = '';
          setTimeout(() => {
            this.toasterService.success(res.message, Constants.toastSuccess);
          }, 1000);
        }, (err) => {
          setTimeout(() => {
            this.toasterService.error(err.error.error, Constants.toastError);
          }, 1000);
        }
      );
    } else {
      this.checkoutService.removeCouponCode().subscribe(
        (res) => {
          this.alreadyAppliedCode = '';
          setTimeout(() => {
            this.toasterService.success(res.message, Constants.toastSuccess);
          }, 1000);
        }, (err) => {
          setTimeout(() => {
            this.toasterService.error(err.error.error, Constants.toastError);
          }, 1000);
        }
      );
    }
  }

  // -------------------------------------------------- Group Order --------------------------------------------------
  getGroupOrderAllcart(){
    this.businessService.getGroupOrderAll().subscribe(
      (res) => {
        this.groupOrderData = res.data;
        if (res.data.delivery_address){
          this.inRadius = true;
          this.currentLocation = res.data.delivery_address.street + ', ' + res.data.delivery_address.city + ', '
                               + res.data.delivery_address.state + ' ' + res.data.delivery_address.zipcode  + ', '
                               + res.data.delivery_address.country;
            const distance = this.getDistanceBetweenPoints(this.storeLat, this.storeLng, res.data.delivery_address.latitude, res.data.delivery_address.longitude);
            if (distance > this.storeDeliveryRadiusMiles){
              this.inRadius = false;
              this.radiusSpinner = false;
              this.toasterService.info(Constants.toastInfoMessage, Constants.toastInfo);
            } else {
              this.inRadius = true;
              this.radiusSpinner = false;
            }
        }
        this.groupId = res.data.group_id;
        if (this.pageName === 'Share-Button-Group') {
          this.shareUrl = environment.WEBSITE_URL + '/business-details/' + this.matData.store_id + '?group_id=' + this.groupId;
        } else if (this.pageName === 'Share-Button') {
          this.shareUrl = environment.WEBSITE_URL + '/business-details/' + this.matData.store_id;
        }
      }, (err) => {

      }
    );
  }

  onChangeTab(event){
    this.tabName = event.tab.textLabel;
  }

   // ------------------------ Get Current Location ------------------------
   onClickCurrentLocation(){
    navigator.geolocation.getCurrentPosition((potition) => {
      this.getGeoLocation(potition.coords.latitude, potition.coords.longitude);
    })
  }

  getGeoLocation(lat: number, lng: number) {
    if (navigator.geolocation) {
        let geocoder = new google.maps.Geocoder();
        let latlng = new google.maps.LatLng(lat, lng);
        let request = {
          'location': latlng
        };
        geocoder.geocode(request, (results) => {
          if (results[0]) {
            this.currentLocation = results[0].formatted_address;
            this.selectLocation(results[0]);
          }
        });  
    }
  }

  // ------------------------ Location search ------------------------
  getAddress() {
    const that = this;
    if (this.currentLocation) {
      this.autoCompleteService.getPlacePredictions({ types: ['geocode'], input: this.currentLocation, componentRestrictions: {country: 'us'}}, (predictions, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && predictions) {
          that.places = [];
          predictions.forEach(pred => {
            that.places.push(pred);
          });
          if (predictions.length) this.hasLocation = false;
          this.cdr.detectChanges();
        } else {
          that.places = [];
          this.hasLocation = true;
        }
      });
    }
  }

  selectLocation(place) {
    this.radiusSpinner = true;
    const that = this;
    that.places = [];
    that.placeService.getDetails({ placeId: place.place_id }, (details) => {
      this.deliveryObj.customer_street = details.name;
      for (let i = 0; i < details.address_components.length; i++) {
        const addressType = details.address_components[i].types[0];
        if (that.placeComponents[addressType]) {
          const value = details.address_components[i][this.placeComponents[addressType]];
          that.deliveryObj[that.autoCompleteElements[addressType]] = value;
        }
      }
      this.cdr.detectChanges();

      // get customer lat long
      that.deliveryObj.customer_lat = details.geometry.location.lat();
      that.deliveryObj.customer_lng = details.geometry.location.lng();
      const distance = this.getDistanceBetweenPoints(this.storeLat, this.storeLng, that.deliveryObj.customer_lat, that.deliveryObj.customer_lng);
      if (distance > this.storeDeliveryRadiusMiles && this.storeDeliveryRadiusMiles !== 0){
        this.inRadius = false;
        this.radiusSpinner = false;
        this.toasterService.info(Constants.toastInfoMessage, Constants.toastInfo);
        // setTimeout(() => {
        // }, 1000);
      } else {
        this.inRadius = true;
        this.radiusSpinner = false;
      }
    });
  }

  // -------------------------------- Find Distance between  --------------------------------
  getDistanceBetweenPoints(lat1, lng1, lat2, lng2){
    return Constants.getDistanceBetweenPoints(lat1, lng1, lat2, lng2);
  }

  checkCurrentLocation(){
    // if (localStorage.getItem('chat-my-order:currentLocation')){ // currentLocation is already stored
    //   // ------------------------- Get Current location -------------------------
    //   this.currentLocation = localStorage.getItem('chat-my-order:currentLocation');

    // } else if (localStorage.getItem('chat-my-order:unlogCurrentLocation')){ // unlogCurrentLocation is stored
    //   this.currentLocation = localStorage.getItem('chat-my-order:unlogCurrentLocation');

    // } else { // no location is available
    //     this.currentLocation = '';
    // }
  }

  createGroupOrder(createGroupOrderRef: TemplateRef<any>){
    let obj;
    if (this.tabName === 'Delivery'){
      obj = {
        type: 'Delivery',
        delivery_address: {
          street: this.deliveryObj.customer_street ? this.deliveryObj.customer_street : this.currentLocation,
          city: this.deliveryObj.customer_city,
          state: this.deliveryObj.customer_state,
          country: this.deliveryObj.customer_country,
          zipcode: this.deliveryObj.customer_zipcode,
          latitude: this.deliveryObj.customer_lat,
          longitude: this.deliveryObj.customer_lng
        }
      };
    } else {
      obj = {
        type: 'Pickup',
        delivery_address: ''
      };
    }

    if (this.groupOrderType === 'add') {
      if (Object.keys(this.groupOrderData).length > 0) {
        if (this.groupOrderData.store_id !== this.matData.store_id) {
          const dialogRef = this.dialog.open(this.groupOrderReplaceRef, {
            height: 'auto',
            width: '20%',
            minHeight: 'calc(25vh - 50px)'
          });
    
          dialogRef.afterClosed().subscribe(result => {
            if (result === 'YES') {
              this.businessService.startGroupOrder(this.matData.store_id, obj).subscribe(
                (res) => {
                  this.groupId = res.data.group_id;
                  setTimeout(() => {
                    this.toasterService.success(res.message, Constants.toastSuccess);
                  }, 1000);
        
                  // open invitation page
                  this.dialog.closeAll();
                  const dialogRef = this.dialog.open(createGroupOrderRef, {
                    panelClass: 'custom-modalbox',
                    minHeight: 'calc(50vh - 50px)',
                    disableClose: true,
                  });
                }, (err) => {
                  setTimeout(() => {
                    this.toasterService.error(err.error.error, Constants.toastError);
                  }, 1000);
                }
              );
            }
          });
        }
      } else {
        this.businessService.startGroupOrder(this.matData.store_id, obj).subscribe(
          (res) => {
            this.groupId = res.data.group_id;
            setTimeout(() => {
              this.toasterService.success(res.message, Constants.toastSuccess);
            }, 1000);
  
            // open invitation page
            this.dialog.closeAll();
            const dialogRef = this.dialog.open(createGroupOrderRef, {
              panelClass: 'custom-modalbox',
              minHeight: 'calc(50vh - 50px)',
              disableClose: true,
            });
          }, (err) => {
            setTimeout(() => {
              this.toasterService.error(err.error.error, Constants.toastError);
            }, 1000);
          }
        );
      }
    } else {
      this.businessService.updateGroupOrderType(this.groupId, obj).subscribe(
        (res) => {
          this.dialog.closeAll();
          this.router.navigateByUrl(`.`, { skipLocationChange: true }).then(() => {
            this.router.navigate(['business-details/' + this.matData.store_id]);
          });
          setTimeout(() => {
            this.toasterService.success('Group order update successfully', Constants.toastSuccess);
          }, 1000);
        }, (err) => {

        }
      );
    }
    

    // this.dialog.closeAll();
    // if (this.groupOrderType === 'add'){
    //   const dialogRef = this.dialog.open(createGroupOrderRef, {
    //     panelClass: 'custom-modalbox',
    //     minHeight: 'calc(50vh - 50px)',
    //   });
    // }

    // dialogRef.afterClosed().subscribe(result => {
    //   setTimeout(() => {
    //     this.toasterService.success('Group order create successfully', Constants.toastSuccess);
    //   }, 1000);
    // });
  }

  inviteGroupOrder(){
    this.businessService.inviteGroupOrder(this.groupId, this.getInvitesControls.value).subscribe(
      (res) => {
        setTimeout(() => {
          this.toasterService.success(res.message, Constants.toastSuccess);
          this.router.navigateByUrl(`.`, { skipLocationChange: true }).then(() => {
            this.router.navigate(['business-details/' + this.matData.store_id]);
          });
        }, 1000);
      }, (err) => {
        setTimeout(() => {
          this.toasterService.error(err.error.error, Constants.toastError);
        }, 1000);
      }
    );
    // this.dialog.closeAll();
    // const dialogRef = this.dialog.open(inviteGroupOrderRef, {
    //   panelClass: 'custom-modalbox',
    //   minHeight: 'calc(50vh - 50px)',
    // });

  }

  refreshLocation() {
    this.router.navigateByUrl(`.`, { skipLocationChange: true }).then(() => {
      this.router.navigate(['business-details/' + this.matData.store_id]);
    });
  }
  // @HostListener('document:keydown', ['$event'])
  // handleKeyboardEvent(event: KeyboardEvent) {
  //     if(event.key === 'ArrowRight'){
  //       this.right()
  //     } else if(event.key === 'ArrowLeft') {
  //       this.left()
  //     }
  // }
  // right(){
  //   if(this.ImageNum !== this.ImageLength){
  //     this.ImageNum = this.ImageNum + 1
  //   }
  // }
  // left(){
  //   if(this.ImageNum !== 0){
  //     this.ImageNum = this.ImageNum - 1
  //   }
  // }

}

import { Component, OnInit, OnDestroy, TemplateRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonAPIResponse } from '../Models/common.Model';
import { Router, ActivatedRoute } from '@angular/router';
import { BusinessService } from '../../layouts/business/Services/app-business.service';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from '../message.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ConfirmationBoxComponent } from '../confirmation-box/confirmation-box.component';
import { Constants } from 'src/app/providers/global-constants';
import Bugsnag from '@bugsnag/js';
import * as moment from 'moment';
import { ClipboardService } from 'ngx-clipboard';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header-cart',
  templateUrl: './header-cart.component.html',
  styleUrls: ['./header-cart.component.scss']
})
export class HeaderCartComponent implements OnInit, OnDestroy {

  //#region variables

  public cartSubTotal: number;

  public cartItems: any = [];
  public discountDetail: any = [];

  public storeId: string;

  public dataLoader: boolean;
  public isToppings: boolean;
  public isVariants: boolean;

  public headerCartServiceSubscription: Subscription;

  public headerCartEmptyMsg = Constants.headerCartEmptyMsg;

  public getGroupId = ''; // for already group order
  public groupOrderList: any = [];
  public groupCartId = '';

  public groupAdminCart: any = [];
  public groupFriendCart: any = [];

  public loggedInUser = localStorage.getItem('cmo:id');
  public groupCheckout: boolean;
  public isCopied = false;

  public routeViewOption: any;

  public itemId: string;
  public itemDetails: any = [];

  public itemDataName: string;
  public itemDataSalePrice: any;
  public itemDataTopping: any = [];
  public itemDataVariants: any = [];
  public itemDataVariantChild: any = [];
  public itemDataDescription: string;
  public itemDataToppingMinSelection;
  public itemDataIsVeg: boolean;
  public itemDataInStock: boolean;
  public itemDataCalorieAmount: number;
  public itemDataCalorieServing: string;

  public noOfItem: any = 1;
  public totalPrice: number;
  public tempTotalPrice: number;

  public tempSumTopping;
  public tempSumVariant;

  public selectedToppingData: any = [];
  public finalSelectedToppingData: any = [];

  public selectedVariantData: any = [];
  public finalSelectedVariantData: any = [];

  public checkAddRemove: string;

  //#endregion

  //#region life-cycle hook

  constructor(
      private businessService: BusinessService,
      private router: Router,
      private messageService: MessageService,
      private toasterService: ToastrService,
      private spinnerService: NgxSpinnerService,
      private dialogRef: MatDialogRef<HeaderCartComponent>,
      public dialog: MatDialog,
      public clipboard: ClipboardService,
    ) { }

  ngOnInit(): void {
    this.spinnerService.show();
    if (window.location.pathname.includes('/group')){
      this.routeViewOption = 'group';
      if (localStorage.getItem('chat-my-order:token')) this.getAllGroupOrderCart();
      else {
        this.spinnerService.hide();
        this.dataLoader = true;
      }

    } else {
      this.routeViewOption = 'normal';
      if (localStorage.getItem('chat-my-order:token')) this.getCartDetails();
      else {
        this.spinnerService.hide();
        this.dataLoader = true;
      }
    }
    // if (localStorage.getItem('chat-my-order:token')) {
    //   if (this.routeViewOption === 'group') this.getAllGroupOrderCart();
    //   else this.getCartDetails();

    // } else {
    //   this.spinnerService.hide();
    //   this.dataLoader = true;
    // }
  }

  ngOnDestroy(): void {
    if (this.headerCartServiceSubscription) {
      this.headerCartServiceSubscription.unsubscribe();
    }
  }

  //#endregion

  //#region public methods

  // -------------------------------- Get All Group Order Cart --------------------------------
  getAllGroupOrderCart(){
    this.groupFriendCart = [];
    this.spinnerService.show();
    this.businessService.getGroupOrderAll().subscribe(
      (res) => {
        console.log('Get all Group order: ', res.data);
        this.spinnerService.hide();
        this.getGroupId = res.data.group_id;
        this.groupOrderList = res.data;
        this.storeId = res.data.store_details._id;
        // if (!this.getGroupId){
        //   this.getCartDetails();
        // } else {
        //   this.storeId = res.data.store_details._id;
        // }
        // this.getCartDetails();
        if (this.groupOrderList.carts){
          this.groupOrderList.carts.forEach(element => {
            if (element.customer_id === this.loggedInUser){
              this.groupCartId = element.id;
              this.groupAdminCart = element;
            } else {
              this.groupFriendCart.push(element);
            }
          });
        }
        this.groupFriendCart.forEach(element => {
          if (element.submission_time !== null){
            this.groupCheckout = true;
          } else {
            this.groupCheckout = false;
          }
        });
      }, (err) => {

      }
    );
  }

  // ----------------------------------- Get Gorup order created time -----------------------------------
  getGroupOrderTime(time){
    return moment(time).format('MMM DD, hh:mm a');
  }

  // -------------------------------- Get Cart Details --------------------------------
  getCartDetails(){
    this.spinnerService.show();
    if (this.headerCartServiceSubscription) {
      this.headerCartServiceSubscription.unsubscribe();
    }
    this.headerCartServiceSubscription = this.businessService.getCartDetail().subscribe(
    (res: CommonAPIResponse) => {
      console.log(res);
      this.spinnerService.hide();
      const result: any = res.data;
      this.cartItems = result.items;
      if (this.cartItems){
        this.dataLoader = true;
        result.items.forEach(element => {
          if (element.toppings){
            this.isToppings = true;
            this.isVariants = false;
          } else if (element.variants){
            this.isToppings = false;
            this.isVariants = true;
          }
        });
      } else {
        this.dataLoader = false;
      }
      this.cartSubTotal = result.total_price;
      this.storeId = result.store_id;
      this.discountDetail = result.discount;
    }, (err: any) => {
      this.spinnerService.hide();
      console.log(err);
      if (err.status === 401){
        console.log('Done');
        this.dialogRef.close();
      }
    });
  }

  // -------------------------------- Clear all items from Cart --------------------------------
  onClickClearCart() {
    const dialogRef = this.dialog.open(ConfirmationBoxComponent, {
      height: '225px',
      width: '250px',
      data: {
        page: 'Header-Cart',
      },
    });

    this.router.events.subscribe(() => {
      dialogRef.close();
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'CONFIRM_YES'){
        this.spinnerService.show();
        this.businessService.clearItemCartDetailById(null).subscribe(
          (res) => {
            this.spinnerService.hide();
            this.cartItems = res.data.items;
            setTimeout(() => {
              this.messageService.sendMessage('clear-cart');
              this.toasterService.success(res.message, Constants.toastSuccess);
            }, 1000);
          }, (err) => {
            this.spinnerService.hide();
            this.dialogRef.close();
            Bugsnag.notify(new Error(err.error.message)); // bugsnag error handle
            setTimeout(() => {
              this.toasterService.error(err.error.message, Constants.toastError);
            }, 1000);
          }
        );
      }
    });
  }

  // -------------------------------- Delete particular item from Cart --------------------------------
  onClickDeleteItem(item){
    console.log(item);
    this.spinnerService.show();
    const cartItemId = item._id;
    this.businessService.deleteItemCartDetailById(this.storeId, cartItemId, null).subscribe(
      (res) => {
        console.log(res);
        this.spinnerService.hide();
        this.cartItems = res.data.items;
        // this.getCartDetails();
        setTimeout(() => {
          this.messageService.sendMessage('delete-cart');
          this.toasterService.success(res.message, Constants.toastSuccess);
        }, 1000);
      }, (err) => {
        this.spinnerService.hide();
        console.log(err)
        Bugsnag.notify(new Error(err.error.message)); // bugsnag error handle
        setTimeout(() => {
          this.toasterService.error(err.error.message, Constants.toastError);
        }, 1000);
      }
    );
  }

  // -------------------------------- Delete particular item from Group Cart --------------------------------
  onClickDeleteGroupItem(item, cartId){
    this.spinnerService.show();
    const cartItemId = item._id;
    this.businessService.deleteGroupOrderItem(this.getGroupId, cartId, cartItemId).subscribe(
      (res) => {
        console.log(res);
        this.spinnerService.hide();
        this.getAllGroupOrderCart();
        setTimeout(() => {
          this.messageService.sendMessage('delete-cart');
          this.toasterService.success(res.message, Constants.toastSuccess);
        }, 1000);
      }, (err) => {
        this.spinnerService.hide();
        setTimeout(() => {
          this.toasterService.error(err.error.message, Constants.toastError);
        }, 1000);
      }
    );
  }

  onClickDeleteGroupMember(cartCustomerId){
    this.businessService.deleteGroupMember(this.getGroupId, cartCustomerId).subscribe(
      (res) => {
        console.log(res);
        this.getAllGroupOrderCart();
      }, (err) => {
        console.log(err);
      }
    );
  }

  // -------------------------------- Leave from Group Order --------------------------------
  groupOrderLeave(){
    this.storeId = this.groupOrderList.store_details._id;
    this.spinnerService.show();
    this.businessService.leaveGroupOrders(this.getGroupId).subscribe(
      (res) => {
        console.log(res);
        this.dialogRef.close();
        this.spinnerService.hide();
        // window.location.reload();
        this.router.navigate(['business-details/' + this.storeId]);
        setTimeout(() => {
          this.messageService.sendMessage('Leave-Group');
          this.toasterService.success(res.message, Constants.toastSuccess);
        }, 1000);
      }, (err) => {
        this.spinnerService.hide();
        setTimeout(() => {
          this.toasterService.error(err.error.message, Constants.toastError);
        }, 1000);
      }
    );
  }

  // -------------------------------- Submit from Group Order --------------------------------
  submitGroupOrder(){
    const obj = {
      type: this.groupOrderList.group_order_type
    };
    console.log(this.groupAdminCart.id);
    this.spinnerService.show();
    this.businessService.confirmGroupOrderItem(this.getGroupId, this.groupAdminCart.id, obj).subscribe(
      (res) => {
        console.log(res);
        this.dialogRef.close();
        this.spinnerService.hide();
        this.router.navigate(['group-thankyou'], {queryParams: { groupFriend: this.groupAdminCart.id }});
        setTimeout(() => {
          this.toasterService.success(res.message, Constants.toastSuccess);
        }, 1000);
      }, (err) => {
        this.spinnerService.hide();
        setTimeout(() => {
          this.toasterService.error(err.error.message, Constants.toastError);
        }, 1000);
      }
    );
  }

  // -------------------------------- View Group Order --------------------------------
  viewGroupOrder(viewGroupOrderRef: TemplateRef<any>){
    this.dialog.closeAll();
    this.dialog.open(viewGroupOrderRef);
  }

  // -------------------------------- Create and update Group Order --------------------------------
  openGroupOrderDialogBox(status){
    const dialogRef = this.dialog.open(ConfirmationBoxComponent, {
      panelClass: 'custom-modalbox',
      minHeight: 'calc(50vh - 50px)',
      data: {
        store_id: this.storeId,
        page: 'Group-order',
        type: status
      },
    });

    this.router.events.subscribe(() => {
      dialogRef.close();
    });
  }

  // ----------------------------------- Get Group Order All Friends avatar -----------------------------------
  getFriendAvatar(name){
    const word = name.split(' ');
    const initials = word[0].charAt(0).toUpperCase() + '' + word[1].charAt(0).toUpperCase();
    return initials;
  }

  // ----------------------------------- Copy Group Order Link -----------------------------------
  copyLink(){
    this.isCopied = true;
    this.clipboard.copy(environment.WEBSITE_URL + '/business-details/' + this.storeId + '?group_id=' + this.getGroupId);
  }

  // ----------------------------------- Cancel Group Order -----------------------------------
  cancelgroupOrder(cancelOrderWarningRef: TemplateRef<any>){
    this.dialog.open(cancelOrderWarningRef);
  }

  // ----------------------------------- Cancel Group Order Confirmation -----------------------------------
  finalCancleGroupOrder(){
    this.dialog.closeAll();
    this.businessService.cancelGroupOrders(this.getGroupId).subscribe(
      (res) => {
        setTimeout(() => {
          localStorage.removeItem('cmo:groupId');
          this.getAllGroupOrderCart();
          this.messageService.sendMessage('Cancel-Group-Order');
          this.router.navigate(['business-details/' + this.storeId]);
          this.toasterService.success(res.message, Constants.toastSuccess);
        }, 1000);
      }, (err) => {
        setTimeout(() => {
          this.toasterService.error(err.error.error, Constants.toastError);
        }, 1000);
      }
    );
  }

  // -------------------------------- Go for checkout --------------------------------
  goToCheckOut(order) {
    this.dialogRef.close();
    if (order === 'normal') {
      this.router.navigate(['/checkout/' + this.storeId], {queryParams: { cartType: 'normal'}});
    } else {
      this.router.navigate(['/checkout/' + this.storeId], {queryParams: { cartType: 'group'}});
    }
  }

  // -------------------------------- Close Cart --------------------------------
  closeCart() {
    this.dialogRef.close();
  }

  // ----------------------------------- Method for display variant and topping details -----------------------------------
  getOrderVariantToppingDetail(od, name){
    return Constants.getOrderVariantToppingDetail(od, name);
  }

  openShareDialogBox(page){
    const dialogRef = this.dialog.open(ConfirmationBoxComponent, {
      height: '200px',
      width: '300px',
      data: {
        page: page,
        store_id: this.storeId
      },
    });

    this.router.events.subscribe(() => {
      dialogRef.close();
    });
  }

  orderOnlineDialog(orderOnlineRef: TemplateRef<any>, itemId) {
    this.itemId = itemId;
    this.dialog.closeAll();
    this.businessService.getItemById(this.storeId, itemId).subscribe(res => {
      console.log('Item: ', res);
      this.dialog.open(orderOnlineRef, {
        panelClass: 'custom-modalbox',
        minHeight: 'calc(50vh - 50px)',
      });
      
      let cartVariantArray: any = [];
      
      this.itemDetails = res.data;
      this.itemDataName = this.itemDetails.name;
      this.itemDataSalePrice = this.itemDetails.formatted_sale_price;
      
      const cartFilterArray: any = this.cartItems.filter(list => list.item_id === this.itemDetails._id);

      if (this.itemDetails.toppings) { // for get selected toppings
        cartFilterArray[0].toppings.forEach(cT => {
          let ind = this.itemDetails.toppings.options.findIndex(list => list.name === cT.name);
          if (ind > -1) {
            this.itemDetails.toppings.options[ind] = {
              additional_price: cT.additional_price,
              image_url: cT.image_url,
              is_active: cT.is_active,
              is_veg: cT.is_veg,
              name: cT.name,
              check: true
            }
          }
        });
      }

      if (this.itemDetails.variants) { // for get selected variants
        let cartArrayOptions: any = [];
        let vName: any;
        // const cartFilterArray: any = this.cartItems.filter(list => list.item_id === this.itemDetails._id);
        this.itemDetails.variants.forEach(iV => {
          cartFilterArray[0].variants.forEach(cV => {
            cartArrayOptions = [];
            if (iV.name === cV.name){
              vName = iV.name;
              iV.options.forEach(iVOptions => {
                if (iVOptions.name === cV.option.name){
                  cartArrayOptions.push({
                    additional_price: iVOptions.additional_price,
                    final_price: iVOptions.final_price,
                    image_url: iVOptions.image_url,
                    is_active: iVOptions.is_active,
                    is_default: iVOptions.is_default,
                    is_veg: iVOptions.is_veg,
                    name: iVOptions.name,
                    checked: true
                  });
                } else {
                  cartArrayOptions.push({
                    additional_price: iVOptions.additional_price,
                    final_price: iVOptions.final_price,
                    image_url: iVOptions.image_url,
                    is_active: iVOptions.is_active,
                    is_default: iVOptions.is_default,
                    is_veg: iVOptions.is_veg,
                    name: iVOptions.name,
                    checked: false
                  });
                }
              });
              cartVariantArray.push({
                name: vName,
                options: cartArrayOptions
              })
            }
          });
        });
      }
      this.itemDataTopping = this.itemDetails.toppings;
      this.itemDataVariants = cartVariantArray;
      this.itemDataVariantChild = this.itemDataVariants;
      this.itemDataDescription = this.itemDetails.description;
      this.itemDataIsVeg = this.itemDetails.is_veg;
      this.itemDataInStock = this.itemDetails.in_stock;
      if (this.itemDetails.calories){
        this.itemDataCalorieAmount = this.itemDetails.calories.amount;
        this.itemDataCalorieServing = this.itemDetails.calories.serving;
      }

      if (this.itemDataTopping){
        if (this.itemDataTopping.min_selections){
          this.itemDataToppingMinSelection = this.itemDataTopping.min_selections;
        } else {
          this.itemDataToppingMinSelection = 0;
        }

        if (this.itemDataTopping.options){
          const top = this.itemDataTopping.options.filter(option => option.is_active === true);
          this.itemDataTopping.options = top;
        }
      } else {
        this.itemDataToppingMinSelection = 0;
      }

      this.totalPrice = parseFloat(this.itemDataSalePrice.slice(1));
      this.tempTotalPrice = this.totalPrice;
      this.noOfItem = cartFilterArray[0].quantity;

      if (this.itemDataVariants) {
        this.onClickVariant(null);
      }

      if (this.itemDataTopping) {
        this.onClickTopping(null);
      }

      if (!this.itemDetails.toppings && !this.itemDetails.variants.length) {
        this.totalPrice = parseFloat(this.itemDataSalePrice.slice(1)) * cartFilterArray[0].quantity;
        this.tempTotalPrice = this.totalPrice;
      }

    }, (err) => {

    });
    
  }

  // -------------------------------- Topping Selection --------------------------------
  onClickTopping(event){
    if (event === null) {
      console.log(this.itemDataTopping);
      this.itemDataTopping.options.forEach(data => {
        if (data.check === true){
          this.selectedToppingData = {
            additional_price: data.additional_price,
            is_active: data.is_active,
            is_veg: data.is_veg,
            name: data.name,
          };
          let obj = {
            checked: true
          };
          this.onChangeTopping(obj);
        }
      });
    } else {
      this.selectedToppingData = event;
    }
  }

  onChangeTopping(check){
    console.log('Checked: ', check);
    if (check.checked === true){
      if (this.itemDataToppingMinSelection > 0){
        this.itemDataToppingMinSelection = --this.itemDataToppingMinSelection; // decrease min selection value
      }
      this.finalSelectedToppingData.push(this.selectedToppingData);

      this.tempSumTopping = this.finalSelectedToppingData.map(a => a.additional_price).reduce((a, b) =>
      {
        return a + b;
      });

      if (this.tempSumTopping !== undefined && this.tempSumVariant !== undefined){ // for both variant and topping has value
        this.tempSumVariant = this.finalSelectedVariantData.map(a => a.option.additional_price).reduce((a, b) =>
        {
          return a + b;
        });
        this.totalPrice = (parseFloat(this.tempSumTopping) + parseFloat(this.tempSumVariant) + parseFloat(this.itemDataSalePrice.slice(1)))
                          * this.noOfItem;
        this.tempTotalPrice = this.totalPrice;

      } else if (this.tempSumTopping !== undefined && this.tempSumVariant === undefined){ // for only topping has value
        this.totalPrice = (parseFloat(this.tempSumTopping) + parseFloat(this.itemDataSalePrice.slice(1))) * this.noOfItem;
        this.tempTotalPrice = this.totalPrice;
      }
    }
    else {
      if (this.finalSelectedToppingData.length <= this.itemDataTopping.min_selections){
        this.itemDataToppingMinSelection = ++this.itemDataToppingMinSelection; // increase min selection value
      }
      const ind = this.finalSelectedToppingData.indexOf(this.selectedToppingData);
      this.finalSelectedToppingData.splice(ind, 1);

      if (this.finalSelectedToppingData.length){
        this.tempSumTopping = this.finalSelectedToppingData.map(a => a.additional_price).reduce((a, b) =>
        {
          return a + b;
        });
      } else {
        this.tempSumTopping = undefined;
      }

      if (this.tempSumTopping !== undefined && this.tempSumVariant !== undefined){ // for both variant and topping has value
        this.tempSumVariant = this.finalSelectedVariantData.map(a => a.option.additional_price).reduce((a, b) =>
        {
          return a + b;
        });
        this.totalPrice = (parseFloat(this.tempSumTopping) + parseFloat(this.tempSumVariant) + parseFloat(this.itemDataSalePrice.slice(1)))
                          * this.noOfItem;
        this.tempTotalPrice = this.totalPrice;
        console.log(this.totalPrice);

      } else if (this.tempSumTopping !== undefined && this.tempSumVariant === undefined){ // for only topping has value
        this.totalPrice = (parseFloat(this.tempSumTopping) + parseFloat(this.itemDataSalePrice.slice(1))) * this.noOfItem;
        this.tempTotalPrice = this.totalPrice;

      } else if (this.tempSumTopping === undefined && this.tempSumVariant !== undefined){ // for only variant has value
        this.tempSumVariant = this.finalSelectedVariantData.map(a => a.option.additional_price).reduce((a, b) =>
        {
          return a + b;
        });
        this.totalPrice = (parseFloat(this.tempSumVariant) + parseFloat(this.itemDataSalePrice.slice(1))) * this.noOfItem;
        this.tempTotalPrice = this.totalPrice;

      } else if (this.tempSumTopping === undefined && this.tempSumVariant === undefined){ // for both variant and topping has no value
        this.totalPrice = parseFloat(this.itemDataSalePrice.slice(1)) * this.noOfItem;
        this.tempTotalPrice = this.totalPrice;
      }
    }
    console.log(this.finalSelectedToppingData);
  }

  // -------------------------------- Variant Selection --------------------------------
  onClickVariant(option){
    // use after final production
    // this.selectedVariantData = option

    // use before final production
    if (option === null){ // for default selected variant
      console.log(this.itemDataVariants);
      this.itemDataVariants.forEach(data => {
        data.options.forEach(dOption => {
          if (dOption.checked === true){
            this.selectedVariantData = {
              additional_price: dOption.additional_price,
              final_price: dOption.final_price,
              is_active: dOption.is_active,
              is_default: dOption.is_default,
              is_veg: dOption.is_veg,
              name: dOption.name
            };
            this.onChangeVariant(data.name);
          }
        });
      });
    } else { // for no default variant
      this.selectedVariantData = {
        additional_price: option.additional_price,
        final_price: option.final_price,
        is_active: option.is_active,
        is_default: option.is_default,
        is_veg: option.is_veg,
        name: option.name
      };
    }
    console.log(this.selectedVariantData);
  }

  onChangeVariant(data){
    if (data.source){ // if data.source
      if (!this.finalSelectedVariantData.length){ // if finalSelectedVariantData has no data
        this.finalSelectedVariantData.push({
          name: data.source.name,
          option: this.selectedVariantData
        });
      } else { // if finalSelectedVariantData has data
        const tempVariantArray = {
          name: data.source.name,
          option: this.selectedVariantData
        };
        if (this.finalSelectedVariantData.find(item => item.name === tempVariantArray.name)){ // already same variant name present in array
          this.finalSelectedVariantData.find(item => item.name === tempVariantArray.name).option = tempVariantArray.option;
        } else {
          this.finalSelectedVariantData.push({
            name: data.source.name,
            option: this.selectedVariantData
          });
        }
      }
    } else { // if not a data.source
      if (!this.finalSelectedVariantData.length){ // if finalSelectedVariantData has no data
        this.finalSelectedVariantData.push({
          name: data,
          option: this.selectedVariantData
        });
      } else { // if finalSelectedVariantData has data
        const tempVariantArray = {
          name: data,
          option: this.selectedVariantData
        };
        if (this.finalSelectedVariantData.find(item => item.name === tempVariantArray.name)){ // already same variant name present in array
          this.finalSelectedVariantData.find(item => item.name === tempVariantArray.name).option = tempVariantArray.option;
        } else {
          this.finalSelectedVariantData.push({
            name: data,
            option: this.selectedVariantData
          });
        }
      }
    }

    this.tempSumVariant = this.finalSelectedVariantData.map(a => a.option.additional_price).reduce((a, b) =>
    {
      return a + b;
    });

    if (this.tempSumTopping !== undefined && this.tempSumVariant !== undefined){ // for both variant and topping has value
      this.tempSumTopping = this.finalSelectedToppingData.map(a => a.additional_price).reduce((a, b) =>
      {
        return a + b;
      });
      this.totalPrice = (parseFloat(this.tempSumTopping) + parseFloat(this.tempSumVariant) + parseFloat(this.itemDataSalePrice.slice(1)))
                        * this.noOfItem;
      this.tempTotalPrice = this.totalPrice;

    } else if (this.tempSumTopping === undefined && this.tempSumVariant !== undefined){ // for only variant has value
      this.totalPrice = (parseFloat(this.tempSumVariant) + parseFloat(this.itemDataSalePrice.slice(1))) * this.noOfItem;
      this.tempTotalPrice = this.totalPrice;
    }
    console.log(this.finalSelectedVariantData);
  }

  // -------------------------------- Add or Remove Item --------------------------------
  addRemoveItem(event){
    this.checkAddRemove = event;
    if (this.checkAddRemove === 'add'){
      this.noOfItem = ++this.noOfItem;

      if (this.tempSumTopping !== undefined && this.tempSumVariant !== undefined){ // for both variant and topping has value
        this.totalPrice = (parseFloat(this.tempSumTopping) + parseFloat(this.tempSumVariant) + parseFloat(this.itemDataSalePrice.slice(1)))
                          * this.noOfItem;

      } else if (this.tempSumTopping !== undefined && this.tempSumVariant === undefined){ // for only topping has value
        this.totalPrice = (parseFloat(this.tempSumTopping) + parseFloat(this.itemDataSalePrice.slice(1))) * this.noOfItem;

      } else if (this.tempSumTopping === undefined && this.tempSumVariant !== undefined){ // for only variant has value
        this.totalPrice = (parseFloat(this.tempSumVariant) + parseFloat(this.itemDataSalePrice.slice(1))) * this.noOfItem;

      } else if (this.tempSumTopping === undefined && this.tempSumVariant === undefined){ // for both variant and topping has no value
        this.totalPrice = parseFloat(this.itemDataSalePrice.slice(1)) * this.noOfItem;
      }

    } else {
      if (this.noOfItem > 1){
        this.noOfItem = --this.noOfItem;

        if (this.tempSumTopping !== undefined && this.tempSumVariant !== undefined){ // for both variant and topping has value
          this.totalPrice = (parseFloat(this.tempSumTopping) + parseFloat(this.tempSumVariant) +
                            parseFloat(this.itemDataSalePrice.slice(1))) * this.noOfItem;

        } else if (this.tempSumTopping !== undefined && this.tempSumVariant === undefined){ // for only topping has value
          this.totalPrice = (parseFloat(this.tempSumTopping) + parseFloat(this.itemDataSalePrice.slice(1))) * this.noOfItem;

        } else if (this.tempSumTopping === undefined && this.tempSumVariant !== undefined){ // for only variant has value
          this.totalPrice = (parseFloat(this.tempSumVariant) + parseFloat(this.itemDataSalePrice.slice(1))) * this.noOfItem;

        } else if (this.tempSumTopping === undefined && this.tempSumVariant === undefined){ // for both variant and topping has no value
          this.totalPrice = parseFloat(this.itemDataSalePrice.slice(1)) * this.noOfItem;
        }
      } else {

      }
    }
  }

  // // ----------------------------------- Get Group Order All Cart -----------------------------------
  // getAllGroupOrderCart(){
  //   this.businessService.getGroupOrderAll().subscribe(
  //     (res) => {
  //       console.log('Get all Group order: ', res.data);
  //       this.groupOrderList = res.data;
  //       this.getGroupId = res.data.group_id;
  //       if (this.groupOrderList.carts){
  //         this.groupOrderList.carts.forEach(element => {
  //           if (element.customer_id === localStorage.getItem('cmo:id')){
  //             this.groupCartId = element.id;
  //           }
  //         });
  //       }
  //     }, (err) => {
  //       console.log('Group Cart: ',err);
  //     }
  //   );
  // }

  // -------------------------------- Add to cart --------------------------------
  onClickCart(){
    let obj: object;
    console.log(this.getGroupId);
    this.spinnerService.show();

    if (this.finalSelectedVariantData.length && this.finalSelectedToppingData.length){
      obj = {
        variants: this.finalSelectedVariantData,
        toppings: this.finalSelectedToppingData,
        quantity: this.noOfItem
      };
    } else if (this.finalSelectedVariantData.length){
        obj = {
          variants: this.finalSelectedVariantData,
          quantity: this.noOfItem
        };
    } else if (this.finalSelectedToppingData.length){
      obj = {
        toppings: this.finalSelectedToppingData,
        quantity: this.noOfItem
      };
    } else if (!this.finalSelectedVariantData.length && !this.finalSelectedToppingData.length) {
      obj = {
        quantity: this.noOfItem
      };
    }

    if (this.routeViewOption === 'group'){ // if group order exist
      if (this.finalSelectedVariantData.length && this.finalSelectedToppingData.length){
        obj = {
          item_id: this.itemDetails._id,
          category: this.itemDetails.category,
          description: this.itemDetails.description,
          formatted_regular_price: this.itemDetails.formatted_regular_price,
          formatted_sale_price: this.itemDetails.formatted_sale_price,
          image_url: this.itemDetails.image_url,
          in_stock: this.itemDetails.in_stock,
          is_active: this.itemDetails.is_active,
          is_veg: this.itemDetails.is_veg,
          name: this.itemDetails.name,
          packaging: this.itemDetails.packaging,
          regular_price: this.itemDetails.regular_price,
          sale_price: this.itemDetails.sale_price,
          store_id: this.itemDetails.store_id,
          sub_category: this.itemDetails.sub_category,
          tags: this.itemDetails.tags,
          calories: {
              amount: this.itemDetails.calories ? this.itemDetails.calories.amount : 0,
              serving: this.itemDetails.calories ? this.itemDetails.calories.serving : ''
          },
          is_taxable: this.itemDetails.is_taxable,
          toppings: this.finalSelectedToppingData,
          type: this.itemDetails.type,
          variants: this.finalSelectedVariantData,
          updated_at: this.itemDetails.updated_at,
          updated_by: this.itemDetails.updated_at,
          quantity: this.noOfItem
        };
      } else if (this.finalSelectedVariantData.length){
          obj = {
            item_id: this.itemDetails._id,
            category: this.itemDetails.category,
            description: this.itemDetails.description,
            formatted_regular_price: this.itemDetails.formatted_regular_price,
            formatted_sale_price: this.itemDetails.formatted_sale_price,
            image_url: this.itemDetails.image_url,
            in_stock: this.itemDetails.in_stock,
            is_active: this.itemDetails.is_active,
            is_veg: this.itemDetails.is_veg,
            name: this.itemDetails.name,
            packaging: this.itemDetails.packaging,
            regular_price: this.itemDetails.regular_price,
            sale_price: this.itemDetails.sale_price,
            store_id: this.itemDetails.store_id,
            sub_category: this.itemDetails.sub_category,
            tags: this.itemDetails.tags,
            calories: {
                amount: this.itemDetails.calories ? this.itemDetails.calories.amount : 0,
                serving: this.itemDetails.calories ? this.itemDetails.calories.serving : ''
            },
            is_taxable: this.itemDetails.is_taxable,
            type: this.itemDetails.type,
            variants: this.finalSelectedVariantData,
            updated_at: this.itemDetails.updated_at,
            updated_by: this.itemDetails.updated_at,
            quantity: this.noOfItem
          };
      } else if (this.finalSelectedToppingData.length){
        obj = {
          item_id: this.itemDetails._id,
          category: this.itemDetails.category,
          description: this.itemDetails.description,
          formatted_regular_price: this.itemDetails.formatted_regular_price,
          formatted_sale_price: this.itemDetails.formatted_sale_price,
          image_url: this.itemDetails.image_url,
          in_stock: this.itemDetails.in_stock,
          is_active: this.itemDetails.is_active,
          is_veg: this.itemDetails.is_veg,
          name: this.itemDetails.name,
          packaging: this.itemDetails.packaging,
          regular_price: this.itemDetails.regular_price,
          sale_price: this.itemDetails.sale_price,
          store_id: this.itemDetails.store_id,
          sub_category: this.itemDetails.sub_category,
          tags: this.itemDetails.tags,
          calories: {
              amount: this.itemDetails.calories ? this.itemDetails.calories.amount : 0,
              serving: this.itemDetails.calories ? this.itemDetails.calories.serving : ''
          },
          is_taxable: this.itemDetails.is_taxable,
          toppings: this.finalSelectedToppingData,
          type: this.itemDetails.type,
          updated_at: this.itemDetails.updated_at,
          updated_by: this.itemDetails.updated_at,
          quantity: this.noOfItem
        };
      } else if (!this.finalSelectedVariantData.length && !this.finalSelectedToppingData.length) {
        obj = {
          item_id: this.itemDetails._id,
          category: this.itemDetails.category,
          description: this.itemDetails.description,
          formatted_regular_price: this.itemDetails.formatted_regular_price,
          formatted_sale_price: this.itemDetails.formatted_sale_price,
          image_url: this.itemDetails.image_url,
          in_stock: this.itemDetails.in_stock,
          is_active: this.itemDetails.is_active,
          is_veg: this.itemDetails.is_veg,
          name: this.itemDetails.name,
          packaging: this.itemDetails.packaging,
          regular_price: this.itemDetails.regular_price,
          sale_price: this.itemDetails.sale_price,
          store_id: this.itemDetails.store_id,
          sub_category: this.itemDetails.sub_category,
          tags: this.itemDetails.tags,
          calories: {
              amount: this.itemDetails.calories ? this.itemDetails.calories.amount : 0,
              serving: this.itemDetails.calories ? this.itemDetails.calories.serving : ''
          },
          is_taxable: this.itemDetails.is_taxable,
          type: this.itemDetails.type,
          updated_at: this.itemDetails.updated_at,
          updated_by: this.itemDetails.updated_at,
          quantity: this.noOfItem
        };
      }
      console.log(obj);
      this.getGroupOrderConfirmationBox(obj);

    } else { // if group order hasn't exist
      // if (this.finalSelectedVariantData.length && this.finalSelectedToppingData.length){
      //     obj = {
      //       variants: this.finalSelectedVariantData,
      //       toppings: this.finalSelectedToppingData,
      //       quantity: this.noOfItem
      //     };
      // } else if (this.finalSelectedVariantData.length){
      //     obj = {
      //       variants: this.finalSelectedVariantData,
      //       quantity: this.noOfItem
      //     };
      // } else if (this.finalSelectedToppingData.length){
      //   obj = {
      //     toppings: this.finalSelectedToppingData,
      //     quantity: this.noOfItem
      //   };
      // } else if (!this.finalSelectedVariantData.length && !this.finalSelectedToppingData.length) {
      //   obj = {
      //     quantity: this.noOfItem
      //   };
      // }
      // console.log(obj);
      this.getconfirmationBox(obj);
    }
  }

  // -------------------------------- Check for confirmation box --------------------------------
  getconfirmationBox(obj){
    let cartItemId: any;
    this.businessService.getCartDetail().subscribe(
      (res) => {
        console.log(res.data);
        if (!res.data.items.length){
          res.data.store_id = null;
          console.log(res.data.store_id);
        } else {
          res.data.items.forEach(item => {
            if (item.item_id === this.itemDetails._id) cartItemId = item._id;
          });
        }
        if (res.data.store_id === null){ // if no items are added in cart
          this.businessService.updateCartItemDetailById(this.itemDetails.store_id, cartItemId, obj).subscribe(
          (response) => {
            console.log(response);
            this.spinnerService.hide();
            this.dialog.closeAll();
            setTimeout(() => {
              this.messageService.sendMessage('add-cart');
              this.toasterService.success(response.message, Constants.toastSuccess);
            }, 1000);
          }, (err) => {
            console.log(err);
            this.spinnerService.hide();
            Bugsnag.notify(new Error(err.error.message)); // bugsnag error handle
            this.dialog.closeAll();
            setTimeout(() => {
              this.toasterService.error(err.error.message, Constants.toastError);
            }, 1000);
          });

        } else if (this.itemDetails.store_id === res.data.store_id){ // current item's store and already stored data store are same
          console.log('ID SAME');
          console.log('Item: ', this.itemDetails);
          this.businessService.updateCartItemDetailById(this.itemDetails.store_id, cartItemId, obj).subscribe(
          (response) => {
            console.log(response);
            this.spinnerService.hide();
            this.dialog.closeAll();
            setTimeout(() => {
              this.messageService.sendMessage('add-cart');
              this.toasterService.success(response.message, Constants.toastSuccess);
            }, 1000);
          },
          (err) => {
            console.log(err);
            this.spinnerService.hide();
            Bugsnag.notify(new Error(err.error.message)); // bugsnag error handle
            this.dialog.closeAll();
            setTimeout(() => {
              this.toasterService.error(err.error.message, Constants.toastError);
            }, 1000);
          });

        } else if (this.itemDetails.store_id !== res.data.store_id){ // if current item's and added item's store id is not same
          const dialogRef = this.dialog.open(ConfirmationBoxComponent, {
            height: '300px',
            width: '300px',
            data: {
              store_id: res.data.store_id,
              page: 'Order-Online'
            },
          });

          dialogRef.afterClosed().subscribe(result => {
            if (result === 'CONFIRM_YES') {
            this.businessService.updateCartItemDetailById(this.itemDetails.store_id, cartItemId, obj).subscribe(
              (dialogRes) => {
                console.log(dialogRes);
                this.spinnerService.hide();
                this.dialog.closeAll();
                setTimeout(() => {
                  this.messageService.sendMessage('add-cart');
                  this.toasterService.success(dialogRes.message, Constants.toastSuccess);
                }, 1000);
              }, (err) => {
                console.log(err);
                this.spinnerService.hide();
                Bugsnag.notify(new Error(err.error.message)); // bugsnag error handle
                this.dialog.closeAll();
                setTimeout(() => {
                  this.toasterService.error(err.error.message, Constants.toastError);
                }, 1000);
              });
            } else if (result === 'CONFIRM_NO') {
              this.changeStore(res.data.store_id);
            } else if (result === 'CHANGE_STORE') {
              this.changeStore(res.data.store_id);
            }
          });

          // if close with backdrop
          dialogRef.backdropClick().subscribe( result => {
            this.spinnerService.hide();
          });

        } else {
          this.spinnerService.hide();
        }
      }, err => {
        console.log(err);
        this.spinnerService.hide();
        setTimeout(() => {
          this.toasterService.error(err.error.message, Constants.toastError);
        }, 1000);
      }
    );
  }

  // -------------------------------- Check for Group order confirmation box --------------------------------
  getGroupOrderConfirmationBox(obj){
    const currentCustomer = this.groupOrderList.carts.filter(list => list.id === this.groupCartId);
    console.log(currentCustomer[0].submission_time);
    if (currentCustomer[0].submission_time === null){
      this.businessService.addGroupOrderItem(this.getGroupId, this.groupCartId, obj).subscribe(
        (res) => {
          console.log(res);
          this.spinnerService.hide();
          this.dialogRef.close();
          setTimeout(() => {
            this.messageService.sendMessage('add-cart');
            this.toasterService.success(res.message, Constants.toastSuccess);
          }, 1000);
        }, (err) => {
          console.log(err);
          this.spinnerService.hide();
          this.dialogRef.close();
          setTimeout(() => {
            this.toasterService.error(err.error.message, Constants.toastError);
          }, 1000);
        }
      );
    } else {
      this.spinnerService.hide();
      this.dialogRef.close();
      setTimeout(() => {
        this.toasterService.info('You Submitted Your Group Order', Constants.toastInfo);
      }, 1000);
    }
  }

  // -------------------------------- Change store --------------------------------
  changeStore(id){
    this.spinnerService.hide();
    this.dialogRef.close();
    window.location.replace('/business-details/' + id);
  }

  // -------------------------------- Close PopUp --------------------------------
  closeItemPopUp(){
    this.dialogRef.close();
  }

  //#endregion
}

<ng-container *ngIf="pageName === 'Order-Online' || pageName === 'Order-List' || pageName === 'Order-Detail'">
    <div class="confirm-header text-center">
        <div class="confirm-header-title">
            <i class="fa fa-exchange fa-5x " aria-hidden="true"></i>
        </div>
    </div>
    <div class="confirm-body text-center">
        <p>
            <span class="text-danger">{{'CONFIRMATION_BOX.NEW_ORDER' | translate}}</span>
        </p>
        <p>{{'CONFIRMATION_BOX.REPLACE_ITEM' | translate}} <a (click)="goToStore()" class="comport-box">{{storeName}}</a></p>
    </div>
    <div class="confirm-footer text-center">
        <button type="button" class="button  btn-outline-secondary" (click)="modalDismiss()">{{'CONFIRMATION_BOX.NO' | translate}}</button>
        <button type="button" class="button  btn-danger" (click)="modalClose()">{{'CONFIRMATION_BOX.YES' | translate}}</button>
    </div>
</ng-container>

<ng-container *ngIf="pageName === 'Address' || pageName === 'Header-Cart' || pageName === 'Checkout' || pageName === 'Checkout-Remove' || pageName === 'Checkout-Delete'">
    <div class="confirm-header text-center">
        <div class="confirm-header-title">
            <!-- <i class="fa fa-times-circle-o fa-4x fa-bg" aria-hidden="true"></i><br> -->
            <!-- <h4>Are you sure?</h4> -->
            <h4><b>{{'CONFIRMATION_BOX.CONFIRM_DELETE' | translate}}</b></h4>
        </div>
    </div>
    <div class="confirm-body text-center">
        <p *ngIf="pageName === 'Address'">
            <span class="text-danger">{{'CONFIRMATION_BOX.DELETE_ITEM' | translate}}</span>
            <!-- <span class="text-danger">Do you really want to delete these record? This process cannot be undone.</span> -->
        </p>

        <p *ngIf="pageName === 'Header-Cart'">
            <span class="text-danger">{{'CONFIRMATION_BOX.CLEAR_ALL' | translate}}</span>
        </p>

        <p *ngIf="pageName === 'Checkout-Remove'">
            <span class="text-danger">{{'CONFIRMATION_BOX.REMOVE_LAST_ORDER' | translate}}</span>
        </p>

        <p *ngIf="pageName === 'Checkout-Delete'">
            <span class="text-danger">{{'CONFIRMATION_BOX.DELETE_ORDER' | translate}}</span>
        </p>
    </div>
    <div class="confirm-footer text-center">
        <button type="button" class="button  btn-outline-secondary" (click)="modalDismiss()">{{'CONFIRMATION_BOX.CANCEL' | translate}}</button>
        <button type="button" class="button  btn-danger"
            *ngIf="pageName === 'Address' || pageName === 'Checkout-Remove' || pageName === 'Checkout-Delete'"
            (click)="modalClose()">{{'CONFIRMATION_BOX.DELETE' | translate}}</button>
        <button type="button" class="button  btn-danger" *ngIf="pageName === 'Header-Cart'"
            (click)="modalClose()">{{'CONFIRMATION_BOX.CLEAR' | translate}}</button>
    </div>
</ng-container>

<ng-container *ngIf="pageName === 'Share-Button' || pageName === 'Share-Button-Group'">
    <div class="confirm-header text-center">
        <div class="confirm-header-title">
            <h4>{{'CONFIRMATION_BOX.SHARE' | translate}} <a class="comport-box">{{storeName}}</a>'s {{'CONFIRMATION_BOX.LINK' | translate}}</h4>
        </div>
    </div>
    <hr>
    <div class="confirm-body text-center margin-top-20">
        <div class="row" (click)="modalDismiss()">
            <share-button theme="modern-light" button="facebook" title="Facebook" [url]="shareUrl"></share-button>
            <share-button theme="modern-light" button="twitter" title="Twitter" [url]="shareUrl"></share-button>
            <share-button theme="modern-light" button="whatsapp" title="Whatsapp" [url]="shareUrl"></share-button>
            <share-button theme="modern-light" button="email" title="Email" [url]="shareUrl"></share-button>
            <share-button theme="modern-light" button="copy" title="Copy link" [url]="shareUrl"></share-button>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="pageName === 'Group-order'">
    <div class="confirm-header text-left">
        <ng-container *ngIf="groupOrderType === 'add'">
            <h3><b>{{'CONFIRMATION_BOX.CREATE_GROUP_ORDER' | translate}}</b>
                <span style="float: right;">
                    <a (click)="modalDismiss()" class="close-cart"><i class="fa fa-times"></i></a>
                </span>
            </h3>
        </ng-container>

        <ng-container *ngIf="groupOrderType === 'update'">
            <h3><b>{{'CONFIRMATION_BOX.UPDATE_GROUP_ORDER' | translate}}</b>
                <span style="float: right;">
                    <a (click)="modalDismiss()" class="close-cart"><i class="fa fa-times"></i></a>
                </span>
            </h3>
        </ng-container>
        <h5>{{'CONFIRMATION_BOX.INVITE_LINE' | translate}}</h5>
    </div>
    <div class="confirm-body text-center margin-top-20">
        <div class="row">
            <mat-tab-group mat-align-tabs="center" (selectedTabChange)="onChangeTab($event)">
                <mat-tab label="{{'CHECKOUT.DELIVERY' | translate}}">
                    <ng-container *ngTemplateOutlet="deliveryGroupOrder"></ng-container>
                </mat-tab>
                <mat-tab label="{{'CHECKOUT.PICKUP' | translate}}">                
                    <div class="pickup-tab padding-bottom-30">  <h3> <b>{{'CONFIRMATION_BOX.PICKUP_ORDER' | translate}}</b></h3>   </div>
                </mat-tab>
            </mat-tab-group>
            <!-- <button class="s-btn s-btn-primary s-btn--block">Start new group order</button> -->
        </div>
    </div>
    <div class="confirm-footer text-center">
        <!-- <div class="loadingio-spinner-dual-ring-le1vebghp0p" style="padding-top: 3px;">
            <div class="ldio-7btnb04xe5l">
                <div></div>
                <div>
                    <div></div>
                </div>
            </div>
        </div> -->
        <ng-container *ngIf="groupOrderType === 'add'">
            <button type="button" class="button  btn-outline-secondary" (click)="createGroupOrder(createGroupOrderRef)" [title]="!isShowGroupCreate ? 'Please Signin For Continue' : (!currentLocation?.length && tabName === 'Delivery') ? 'Please Add Delivery Address' : (!inRadius && tabName === 'Delivery') ? 'The restaurant is currently not delivering to your location' : isClose ? 'Store is closed' : 'Create Group Order'" 
                    [disabled]="!isShowGroupCreate || (!currentLocation?.length && tabName === 'Delivery') || (!inRadius && tabName === 'Delivery')">{{'CONFIRMATION_BOX.CREATE_GROUP_ORDER' | translate}}
                    <div class="loadingio-spinner-dual-ring-le1vebghp0p" style="padding-top: 3px;" *ngIf="radiusSpinner">
                        <div class="ldio-7btnb04xe5l">
                            <div></div>
                            <div>
                                <div></div>
                            </div>
                        </div>
                    </div>
            </button>
            <div style="color: red;" *ngIf="!isShowGroupCreate || (!currentLocation?.length && tabName === 'Delivery') || (!inRadius && tabName === 'Delivery')">
                {{(!inRadius && tabName === 'Delivery') ? '* The restaurant is currently not delivering to your location' : ''}}</div>
        </ng-container>
        <ng-container *ngIf="groupOrderType === 'update'">
            <button type="button" class="button  btn-outline-secondary" (click)="createGroupOrder(createGroupOrderRef)" [title]="!isShowGroupCreate ? 'Please Signin For Continue' : (!currentLocation?.length && tabName === 'Delivery') ? 'Please Add Delivery Address' : (!inRadius && tabName === 'Delivery') ? 'The restaurant is currently not delivering to your location' : 'Update Group Order'" 
                    [disabled]="!isShowGroupCreate || (!currentLocation?.length && tabName === 'Delivery') || (!inRadius && tabName === 'Delivery')">{{'CONFIRMATION_BOX.UPDATE_GROUP_ORDER' | translate}}
                    <div class="loadingio-spinner-dual-ring-le1vebghp0p" style="padding-top: 3px;" *ngIf="radiusSpinner">
                        <div class="ldio-7btnb04xe5l">
                            <div></div>
                            <div>
                                <div></div>
                            </div>
                        </div>
                    </div>
            </button>
            <div style="color: red;" *ngIf="!isShowGroupCreate || (!currentLocation?.length && tabName === 'Delivery') || (!inRadius && tabName === 'Delivery')">
                {{!isShowGroupCreate ? '* Please Signin For Continue' : (!currentLocation?.length && tabName === 'Delivery') ? '* Please Add Delivery Address' : (!inRadius && tabName === 'Delivery') ? '* The restaurant is currently not delivering to your location' : 'Update Group Order'}}</div>
        </ng-container>
    </div>
</ng-container>

<ng-container *ngIf="pageName === 'Signin' || pageName === 'Signup'">
    <div class="row">
        <div class="col-lg-12 sign_info">
            <h2 class="title-name text-center padding-bottom-30">{{'SIGN_UP.MOBILE' | translate}}</h2>
            <div class="sign-in-form style-1">
                <form class="login" (submit)="modalClose()" accept-charset="utf-8">
                    <div class="form-row form-row-wide">
                        <label for="username">
                            <i class="fa fa-phone"></i>
                            <input type="text" name="mobile" placeholder="{{'SIGN_UP.MOBILE' | translate}}" id="mobile" mask="(000) 000-0000"
                                (keypress)="numberOnly($event)" (keyup)="checkNumberLength()" [(ngModel)]="mobileNo"
                                #mobile="ngModel" required />
                        </label>
                        <div class="text-danger" style="color: red;"
                            *ngIf="(mobile.touched || submitted) && mobile.invalid && mobile.errors?.required">
                            {{mobileReq}}
                        </div>
                        <div class="text-danger" style="color: red;" *ngIf="isNumber">
                            {{mobileLen}}
                        </div>
                    </div>
                    <div class="extra mb_20 padding-top-30 text-center">
                        <div class="d-flex justify-content-between align-items-center">
                            <input type="submit" class="sign-button border margin-top-5" name="submit" value="{{'RESET_PASSWORD.SUBMIT' | translate}}" />

                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="pageName === 'Discount-Offer'">
   
    <div class="confirm-header text-left">
        <span style="float: right;">
            <a (click)="modalDismiss()" class="close-cart"><i class="fa fa-times"></i></a>
        </span>
        <h3><b>{{pageData.title}}</b>
           
        </h3>
        <h4 style="font-weight: 500;">{{pageData.description}}</h4>
    </div>
    <div class="confirm-body text-left" style="font-weight: lighter;">
        <ng-container *ngIf="pageData.type === 'PERCENTAGE'">
            <h5>{{'CONFIRMATION_BOX.CONDITION' | translate}}: {{pageData.condition}}</h5>
            <h5>{{'CONFIRMATION_BOX.START_DATE' | translate}}: {{startDate}}</h5>
            <h5>{{'CONFIRMATION_BOX.END_DATE' | translate}}: {{endDate}}</h5>
            <h5>{{'CONFIRMATION_BOX.AMOUNT' | translate}}: {{pageData.amount}}%</h5>
        </ng-container>

        <ng-container *ngIf="pageData.type === 'FIXED'">
            <h5>{{'CONFIRMATION_BOX.CONDITION' | translate}}: {{pageData.condition}}</h5>
            <h5>{{'CONFIRMATION_BOX.START_DATE' | translate}}: {{startDate}}</h5>
            <h5>{{'CONFIRMATION_BOX.END_DATE' | translate}}: {{endDate}}</h5>
            <h5>{{'CONFIRMATION_BOX.AMOUNT' | translate}}: {{pageData.amount | currency:'USD'}}</h5>
        </ng-container>
    </div>
    <div class="confirm-footer text-center">
        <button type="button" class="button  btn-outline-secondary" (click)="modalDismiss()">{{'CONFIRMATION_BOX.GOT_IT' | translate}}</button>
    </div>
</ng-container>

<ng-container *ngIf="pageName === 'Offer-view'">
    <mat-dialog-title>
        <div class="confirm-header text-left">
            <h3><b>{{'CONFIRMATION_BOX.AVAILABLE_OFFER' | translate}}</b>
                <span style="float: right;">
                    <a (click)="modalDismiss()" class="close-cart"><i class="fa fa-times"></i></a>
                </span>
            </h3>
        </div>
    </mat-dialog-title>

    <mat-dialog-content>
        <ng-container *ngIf="offerView?.length">
            <div class="confirm-body box-cupan text-left" style="font-weight: lighter;" *ngFor="let offer of offerView">
                <!-- <div class="listing-item-container list-layout"> -->
                <h4><b>{{offer.title}} </b>
                    <ng-container *ngIf="offer.code === alreadyAppliedCode">
                        <button class="card-link-secondary small mr-3" (click)="onClickDeleteCoupon()"><i
                                class="fa fa-trash"></i></button>
                    </ng-container>
                </h4>
                <h5>{{offer.condition}}</h5>
                <!-- <h5>{{offer.description}} 
                        <a style="color: #00a4ef;" class="offer-details" (click)="offerViewFullDetails(offer)">view details</a>
                    </h5> -->
                <h5>
                    <div class="coupon-code">{{offer.code}}</div>
                    <!-- <span style="float: right;" *ngIf="offer.code !== alreadyAppliedCode">
                            <a class="apply-code" (click)="applyCode(offer)">Apply</a>
                        </span>
                       
                        <span style="float: right;" *ngIf="offer.code === alreadyAppliedCode">
                            <a style="color: #13aa37;"><i class="fa fa-check"></i> Applied!</a>
                        </span> -->
                    <button class="medium button-border btn-right" style="float: right;"
                        *ngIf="offer.code !== alreadyAppliedCode" (click)="applyCode(offer)"> {{'CONFIRMATION_BOX.APPLY' | translate}}</button>
                    <button class="medium button-border btn-right" style="float: right;"
                        *ngIf="offer.code === alreadyAppliedCode"> <i class="fa fa-check"></i> {{'CONFIRMATION_BOX.APPLIED' | translate}}</button>
                </h5>
                <!-- </div> -->
    
            </div>
        </ng-container>

        <ng-container *ngIf="!offerView?.length">
            <div class="text-center">     
                <img src="/assets/images/custom/menu/undraw_offer1.png" height="300px">
                <h3 class="not-item">{{'CHECKOUT.NO_OFFER' | translate}}</h3>
            </div>
        </ng-container>
    </mat-dialog-content>
    <div class="confirm-footer text-center">
        <!-- <button type="button" class="button  btn-outline-secondary" (click)="modalDismiss()">Ok, got it</button> -->
    </div>
</ng-container>

<ng-container *ngIf="pageName === 'Offer-code-view'">
    <div class="confirm-header text-left">
        <h3><b>{{pageData.title}}</b>
            <span style="float: right;">
                <a (click)="modalDismiss()" class="close-cart"><i class="fa fa-times"></i></a>
            </span>
        </h3>
        <h4 style="font-weight: 500;">{{pageData.description}}</h4>
    </div>
    <div class="confirm-body text-left" style="font-weight: lighter;">
        <ng-container *ngIf="pageData.type === 'PERCENTAGE'">
            <h5>{{'CONFIRMATION_BOX.CONDITION' | translate}}: {{pageData.condition}}</h5>
            <h5>{{'CONFIRMATION_BOX.START_DATE' | translate}}: {{startDate}}</h5>
            <h5>{{'CONFIRMATION_BOX.END_DATE' | translate}}: {{endDate}}</h5>
            <h5>{{'CONFIRMATION_BOX.AMOUNT' | translate}}: {{pageData.amount}}%</h5>
            <h5><b>{{pageData.code}}</b></h5>
        </ng-container>

        <ng-container *ngIf="pageData.type === 'FIXED'">
            <h5>{{'CONFIRMATION_BOX.CONDITION' | translate}}: {{pageData.condition}}</h5>
            <h5>{{'CONFIRMATION_BOX.START_DATE' | translate}}: {{startDate}}</h5>
            <h5>{{'CONFIRMATION_BOX.END_DATE' | translate}}: {{endDate}}</h5>
            <h5>{{'CONFIRMATION_BOX.AMOUNT' | translate}}: {{pageData.amount | currency:'USD'}}</h5>
            <h5><b>{{pageData.code}}</b></h5>
        </ng-container>
    </div>
    <div class="confirm-footer text-center">
        <button type="button" class="button  btn-outline-secondary" (click)="modalDismiss()">{{'CONFIRMATION_BOX.GOT_IT' | translate}}</button>
    </div>
</ng-container>


<ng-template #deliveryGroupOrder>
    <div class="input-with-icon margin-top-25 margin-bottom-25">
        <i class="fa fa-map-marker"></i>
        <input type="text" [(ngModel)]="currentLocation" #customerAddress (keyup)="getAddress()"
            [matAutocomplete]="locationGroup" placeholder="{{'SEARCH.LOCATION' | translate}}"/>
        <mat-autocomplete #locationGroup="matAutocomplete">
            <mat-option (click)="onClickCurrentLocation()">
                <i class="fa fa-map-o"> {{'CONFIRMATION_BOX.CURRENT_LOCATION' | translate}}</i>
            </mat-option>
            <mat-option *ngFor="let place of places" [value]="place.description" (click)="selectLocation(place)">
                {{ place.description }}
            </mat-option>
        </mat-autocomplete>
    </div>
</ng-template>

<ng-template #createGroupOrderRef let-data let-ref="dialogRef">
    <mat-dialog-title>
        <div class="row col-md-12">
            <h3><b>{{'CONFIRMATION_BOX.INVITE_FRIEND' | translate}}</b>

                <span style="float: right;">
                    <a (click)="refreshLocation();ref.close()" class="close-cart"><i class="fa fa-times"></i></a>
                </span>
            </h3>
            <h5>{{'CONFIRMATION_BOX.INVITE_FRIEND_LIMIT' | translate}}</h5>
        </div>

    </mat-dialog-title>
    <!-- <div class="row with-forms">
            <div class="col-md-12 margin-top-10">
                <input type="text" placeholder="Group Order Url" value="https://dev.chatmyorder.com/signin?redirectTo=%2Fhome">
                <span style="float: right;" *ngIf="!isCopy">
                    <a style="color: #00a4ef; cursor: pointer;" (click)="copyCode('https://dev.chatmyorder.com/signin?redirectTo=%2Fhome')">TAP TO COPY LINK</a>
                </span>
                <span style="float: right;" *ngIf="isCopy">
                    <a style="color: #d4d4db; cursor: pointer;">COPIED!</a>
                </span>
            </div>
            <button class="button fullwidth margin-top-10" (click)="ref.close(); modalDismiss()">Done</button>
        </div> -->
    <mat-dialog-content>
        <form [formGroup]="groupOrderForm">
            <div formArrayName="invites">
                <div *ngFor="let order of groupOrderForm.get('invites')['controls']; let i=index; let last = last;" [formGroupName]="i">
                    <!-- <ng-container [formGroupName]="i"> -->
                        <div class="row col-md-12 col-sm-12 col-lg-12  margin-top-20">
                            <div class="col-md-5 col-sm-5">
                                <input type="text" class="form-control" placeholder="{{'SIGN_UP.MOBILE' | translate}}" (keypress)="numberOnly($event)"
                                    mask="(000) 000-0000" formControlName="mobile">
                            </div>
                            <div class="col-md-5 col-sm-5">
                                <input type="text" class="form-control" placeholder="{{'FORGOT_PASSWORD.EMAIL' | translate}}" formControlName="email">
                            </div>
                            <div class="col-md-2 col-sm-2">
                                <button (click)="removeInvitation(i)" class="button gray btn-p"
                                    style="background-color: red;"><i class="fa fa-trash"></i></button>
                                <button (click)="addInvitation()" class="button gray btn-p" *ngIf="last"
                                    [disabled]="i === 19"><i class="fa fa-plus"></i></button>
                            </div>
                        </div>
                    <!-- </ng-container> -->
                </div>
            </div>
        </form>
        <div class="text-center">
            <button class="button margin-top-20" name="submit" (click)="inviteGroupOrder();ref.close()">{{'FORGOT_PASSWORD.SUBMIT' | translate}}</button>
        </div>
    </mat-dialog-content>


</ng-template>

<ng-template #inviteGroupOrderRef let-data let-ref="dialogRef">
    <mat-dialog-title>
        <div class="row col-md-12">
            <h3><b>Group order at Elena's Restaurant</b>

                <span style="float: right;">
                    <a (click)="ref.close()" class="close-cart"><i class="fa fa-times"></i></a>
                </span>
            </h3>
            <p>Check out and pay when everyone has added to the order.</p>
        </div>

    </mat-dialog-title>
    <mat-dialog-content>
        
        <b>Order details</b><a href="javascript:void(0)" class="links-change">Change</a><br />
        <p>Delivery from 5 W Tremont Ave</p>
       
        <b>1 Friends</b>
        <a at-copy-to-clipboard="true" href="javascript:void(0)" class="links-change">Share link</a>
        <p>Invite up to 20 friends. Your friends appear here once they join the group order.</p>
        <div class="u-flex u-flex-wrap">
            <span class="groupOrder-friends" title="You">You</span>
            <span class="groupOrder-friends2" title="AP">AP</span>
        </div>
      
        <hr>
        <a href="#" class="link-but">Cancel group order</a><br/>
        <hr>
        <div class="text-center">
            <button class="button margin-top-20" name="submit">Submit</button>
        </div>
    </mat-dialog-content>
</ng-template>

<div id="gMap" #gMap></div>


<!-- <ng-container *ngIf="pageName === 'Menu-Gallery'">
    <link rel="stylesheet" href="https://www.w3schools.com/w3css/4/w3.css">

            
    <div class="w3-display-container">
        <img [defaultImage]="ImageDefault" [lazyLoad]="Image[ImageNum]" [useSrcset]="true"/>
    </div>
    <button class="w3-button w3-display-right w3-black" (click)="right()" [disabled]="ImageNum === ImageLength" title="right" >&#10095;</button>
    <button class="w3-button w3-display-left w3-black" (click)="left()" [disabled]="ImageNum === 0" title="left">&#10094;</button>
</ng-container> -->

<ng-template #confirmGroupOrderReplace let-data let-ref="dialogRef">
    <div class="confirm-header text-center">
        <div class="confirm-header-title">
            <i class="fa fa-exchange fa-5x " aria-hidden="true"></i>
        </div>
    </div>
    <div class="confirm-body text-center">
        <p>
            <span class="text-danger">{{'CONFIRMATION_BOX.NEW_GROUP_ORDER' | translate}}</span>
        </p>
        <p>{{'CONFIRMATION_BOX.REPLACE_ITEM' | translate}} <a (click)="goToStore()" class="comport-box">{{storeName}}</a></p>
    </div>
    <div class="confirm-footer text-center">
        <button type="button" class="button  btn-outline-secondary" (click)="ref.close('NO')">{{'CONFIRMATION_BOX.NO' | translate}}</button>
        <button type="button" class="button  btn-danger" (click)="ref.close('YES')">{{'CONFIRMATION_BOX.YES' | translate}}</button>
    </div>
</ng-template>
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from '../message.service';

@Component({
  selector: 'app-not-found-page',
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.scss']
})
export class NotFoundPageComponent implements OnInit {

  constructor(
    private router: Router,
    private messageService: MessageService,
  ) {
    this.messageService.sendMessage('page-not-found');
  }

  ngOnInit(): void {
  }

  goToHome(){
    this.router.navigate(['/home']);
  }
}

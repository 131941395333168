<ngx-spinner
    bdColor="rgba(51,51,51,0.8)"
    bdOpacity=0.9
    size="medium"
    color="#fff"
    type="ball-scale-multiple"
    [fullScreen]="false">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
<!--normal cart-->
<ng-container *ngIf="routeViewOption === 'normal'">
    <div data-cart="" class="panel panel-primary no-corners">
        <div data-cart-items-container="" class="panel-body">
            <ng-container *ngIf="dataLoader">
                <ng-container *ngIf="cartItems?.length">
                    <div class="row">
                        <h3 class="cart-p-title">
                            <!-- Your order -->
                            {{'HEADER_CART.YOUR_ORDER' | translate}}
                            <span class="cpop-cart">
                                <a (click)="closeCart()" class="close-cart"><i class="fa fa-times"></i></a>
                            </span>
                        </h3>


                    </div>
                </ng-container>
               
                <mat-dialog-content>
                    <div class="cart-item" *ngIf="cartItems?.length">
                        <ng-container *ngFor="let item of cartItems">
                            <div class="cart-item border-line row">
                                <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 text-left">
                                    <span class="q-pop">
                                        <button class="card-link-secondary small mr-3"
                                            (click)="onClickDeleteItem(item)"><i class="fa fa-trash"></i></button>
                                    </span>

                                </div>
                                <div class="col-lg-9 col-md-9 col-sm-9  col-xs-9 text-left">
                                    <p class="cart-name">
                                        <span class="q-number">{{item.quantity}} ×</span>
                                            <a style="cursor: pointer; color: #006fa2;" (click)="orderOnlineDialog(orderOnlineRef, item.item_id)">{{item.name}}</a>
                                        <!-- if variant and topping are in product -->
                                        <ng-container *ngIf="item.toppings?.length && item.variants?.length">
                                            <p class="extraMsgFontSize">Toppings:
                                                {{getOrderVariantToppingDetail(item,'topping')}}</p>
                                            <p class="extraMsgFontSize">{{getOrderVariantToppingDetail(item,'variant')}}
                                            </p>

                                        </ng-container>

                                        <!-- if variant and topping are not in pritemuct -->
                                        <ng-container *ngIf="!item.toppings?.length && !item.variants?.length">
                                            <!-- <td data-label="Topping">-</td>
                                            <td data-label="Variant">-</td> -->
                                        </ng-container>

                                        <!-- if only topping is in pritemuct -->
                                        <ng-container *ngIf="item.toppings?.length && !item.variants?.length">
                                            <p class="extraMsgFontSize">Toppings:
                                                {{getOrderVariantToppingDetail(item,'topping')}}</p>
                                        </ng-container>

                                        <!-- if only variant is in pritemuct -->
                                        <ng-container *ngIf="!item.toppings?.length && item.variants?.length">
                                            <p class="extraMsgFontSize">{{getOrderVariantToppingDetail(item,'variant')}}
                                            </p>
                                        </ng-container>
                                    </p>
                                </div>
                               
                                <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 text-right">
                                    <b>{{ item.total_amount > 0 ? (item.total_amount | currency:'USD') : 'Free'}}</b>
                                </div>
                                
                            </div>
                            <!-- <hr> -->
                        </ng-container>
                    </div>
                  

                    <ng-container *ngIf="cartItems?.length">
                        <div class="row">
                            <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9 text-left "><b>{{'HEADER_CART.ITEM_SUB_TOTAL' | translate}}:</b></div>
                            <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 text-left"></div>
                            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 text-right">
                                <b>{{ cartSubTotal > 0 ? (cartSubTotal | currency:'USD') : 'Free'}}</b></div>
                        </div>
                        <div class="row" *ngIf="discountDetail.amount !== 0" style="color: #006fa2;">
                            <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9 text-left "><b>{{'CHECKOUT.PROMO' | translate}} ({{discountDetail.title}}):</b>
                            </div>
                            <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 text-left"></div>
                            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 text-right"><b>-
                                    {{(discountDetail.amount | currency:'USD')}}</b></div>
                        </div>
                    </ng-container>


                </mat-dialog-content>
                <ng-container *ngIf="cartItems?.length">
                    <mat-dialog-actions>
                        <div class="text-center margin-top-20">
                            <button type="button" class="medium button-border" (click)="onClickClearCart()"
                                [disabled]="!cartItems?.length"> {{'HEADER_CART.CLEAR_CART' | translate}}</button>
                            <button type="button" class="button" (click)="goToCheckOut('normal')">{{'HEADER_CART.CHECKOUT' | translate}}</button>
                        </div>
                    </mat-dialog-actions>

                </ng-container>
             
              
            </ng-container>
       
            <div data-cart-item="" class="cart-item" *ngIf="!cartItems?.length">
                <span class="cpop-cart">
                    <a (click)="closeCart()" class="close-cart"><i class="fa fa-times"></i></a>
                </span>

                <div class="text-center">
                    <img src="/assets/images/custom/menu/undraw_empty_cart_co35.png" height="300px">
                    <p style="color: black;"><b>{{headerCartEmptyMsg}}</b></p>
                    
                </div>
            </div>
        
            <ng-container *ngIf="!dataLoader">

            </ng-container>


        </div>
    </div>
</ng-container>

<!--groupcart-->
<ng-container *ngIf="routeViewOption === 'group'">
    <div class="panel panel-primary no-corners">
        <div class="panel-body">
            <!-- Group Admin Cart -->
            <ng-container *ngIf="loggedInUser === groupOrderList?.customer_id">

                <!-- exist group order header -->
                <ng-container *ngIf="groupOrderList">
                    <div class="row">
                        <h3 class="cart-p-title">
                            {{'HEADER_CART.YOUR_ORDER' | translate}}
                            <span class="cpop-cart">
                                <a (click)="closeCart()" class="close-cart"><i class="fa fa-times"></i></a>
                            </span>
                        </h3>
                    </div>


                    <h5 style="color: #006fa2;">{{groupOrderList.store_details.name}} <span style="float: right;"> <a
                                class="view-cart" (click)="viewGroupOrder(viewGroupOrderRef)">View Details</a></span>
                    </h5>
                    <h5 style="font-weight: 400;">{{groupOrderList.group_order_type}},
                        {{getGroupOrderTime(groupOrderList.created)}}</h5>

                </ng-container>

                <hr>
                <mat-dialog-content>
                    <!-- Logged in User List -->
                    <div class="orderTitle u-block">
                        <h5>{{'HEADER_CART.YOUR_ITEMS' | translate}}</h5>
                    </div>

                    <ng-container *ngIf="groupAdminCart?.items?.length">
                        <div class="cart-item">
                            <ng-container *ngFor="let item of groupAdminCart.items; let i = index">
                                <div class="cart-item border-line row">
                                    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 text-left">
                                        <span class="q-pop">
                                            <button class="card-link-secondary small mr-3"
                                                (click)="onClickDeleteGroupItem(item, groupAdminCart.id)"><i
                                                    class="fa fa-trash"></i></button>
                                        </span>
                                    </div>
                                    <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9 text-left">
                                        <p class="cart-name">
                                            <span class="q-number">{{item.quantity}} ×</span>{{item.name}}
                                            <!-- if variant and topping are in product -->
                                            <ng-container *ngIf="item.toppings?.length && item.variants?.length">
                                                <p class="extraMsgFontSize">{{'HEADER_CART.TOPPING' | translate}}:
                                                    {{getOrderVariantToppingDetail(item,'topping')}}</p>
                                                <p class="extraMsgFontSize">
                                                    {{getOrderVariantToppingDetail(item,'variant')}}</p>

                                            </ng-container>

                                            <!-- if variant and topping are not in pritemuct -->
                                            <ng-container *ngIf="!item.toppings?.length && !item.variants?.length">
                                                <!-- <td data-label="Topping">-</td>
                                                <td data-label="Variant">-</td> -->
                                            </ng-container>

                                            <!-- if only topping is in pritemuct -->
                                            <ng-container *ngIf="item.toppings?.length && !item.variants?.length">
                                                <p class="extraMsgFontSize">{{'HEADER_CART.TOPPING' | translate}}:
                                                    {{getOrderVariantToppingDetail(item,'topping')}}</p>
                                            </ng-container>

                                            <!-- if only variant is in pritemuct -->
                                            <ng-container *ngIf="!item.toppings?.length && item.variants?.length">
                                                <p class="extraMsgFontSize">
                                                    {{getOrderVariantToppingDetail(item,'variant')}}</p>
                                            </ng-container>
                                        </p>
                                    </div>

                                    <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 text-right">
                                        <b>{{ (item.total_amount > 0) ? (item.total_amount | currency:'USD') : 'Free'}}</b>
                                    </div>

                                </div>
                            </ng-container>
                        </div>
                        <div class="row">
                            <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9 text-left ">{{'HEADER_CART.ITEMS_TOTAL' | translate}}:</div>
                            <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 text-left"></div>
                            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 text-right">
                                {{groupAdminCart.total_price > 0 ? (groupAdminCart.total_price | currency:'USD') : 'Free'}}</div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!groupAdminCart?.items?.length">
                        <p>{{'HEADER_CART.YOUR_ITEM_EMPTY' | translate}}</p>
                    </ng-container>
                    <hr>

                    <!-- Logged in User Friends List -->
                    <ng-container *ngIf="groupFriendCart?.length">
                        <div class="orderTitle u-block">
                            <h5>{{groupFriendCart?.length}} {{'HEADER_CART.FRIEND' | translate}}</h5>
                        </div>
                        <mat-accordion class="style-2" [multi]="true">
                            <mat-expansion-panel class="toggle-wrap" [expanded]="false"
                                *ngFor="let friend of groupFriendCart">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <span class="trigger">
                                            <a href="javascript:void(0);">
                                                <h5 class="title-order">{{friend.customer_name}} -
                                                    <span class="submit-order" *ngIf="!friend.submission_time">{{'HEADER_CART.IN_PROGRESS' | translate}}</span>
                                                    <span class="submit-order"
                                                        *ngIf="friend.submission_time">{{'HEADER_CART.SUBMITTED' | translate}}</span>
                                                    <span class="submit-order">
                                                        <button class="card-link-secondary small mr-3" (click)="onClickDeleteGroupMember(friend.customer_id)"><i class="fa fa-trash"></i></button>
                                                    </span>
                                                </h5>
                                            </a>
                                        </span>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>

                                <!-- friend has item list -->
                                <div class="cart-item" *ngIf="friend.items?.length">
                                    <ng-container *ngFor="let item of friend.items">
                                        <div class="cart-item border-line row">
                                            <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 text-left">
                                                <span class="q-pop">
                                                    <button class="card-link-secondary small mr-3"
                                                        (click)="onClickDeleteGroupItem(item, friend.id)"><i
                                                            class="fa fa-trash"></i></button>
                                                </span>
                                            </div>
                                            <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9 text-left">
                                                <p class="cart-name">
                                                    <span class="q-number">{{item.quantity}} ×</span>{{item.name}}

                                                    <ng-container
                                                        *ngIf="item.toppings?.length && item.variants?.length">
                                                        <p class="extraMsgFontSize">{{'HEADER_CART.TOPPING' | translate}}:
                                                            {{getOrderVariantToppingDetail(item,'topping')}}</p>
                                                        <p class="extraMsgFontSize">
                                                            {{getOrderVariantToppingDetail(item,'variant')}}</p>

                                                    </ng-container>


                                                    <ng-container
                                                        *ngIf="!item.toppings?.length && !item.variants?.length">
                                                    </ng-container>


                                                    <ng-container
                                                        *ngIf="item.toppings?.length && !item.variants?.length">
                                                        <p class="extraMsgFontSize">{{'HEADER_CART.TOPPING' | translate}}:
                                                            {{getOrderVariantToppingDetail(item,'topping')}}</p>
                                                    </ng-container>


                                                    <ng-container
                                                        *ngIf="!item.toppings?.length && item.variants?.length">
                                                        <p class="extraMsgFontSize">
                                                            {{getOrderVariantToppingDetail(item,'variant')}}</p>
                                                    </ng-container>
                                                </p>
                                            </div>

                                            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 text-right">
                                                <b>{{ (item.total_amount > 0) ? (item.total_amount | currency:'USD') : 'Free'}}</b>
                                            </div>

                                        </div>
                                    </ng-container>
                                </div>

                                <!-- friend hasn't item list -->
                                <div class="cart-item" *ngIf="!friend.items?.length">
                                    <p>{{'HEADER_CART.NO_ITEM' | translate}}</p>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </ng-container>
                    <ng-container *ngIf="!groupFriendCart?.length">
                        <p>{{'HEADER_CART.NO_FRIEND_JOIN' | translate}}</p>
                    </ng-container>

                </mat-dialog-content>

                <ng-container *ngIf="groupOrderList">
                    <div class="row">
                        <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9 text-left "><b>{{'HEADER_CART.ITEM_SUB_TOTAL' | translate}}:</b></div>
                        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 text-left"></div>
                        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 text-right">
                            <b>{{ (groupOrderList.sub_total > 0) ? (groupOrderList.sub_total | currency:'USD') : 'Free'}}</b></div>
                    </div>
                    <div class="row" *ngIf="groupOrderList.discount?.amount !== 0" style="color: #006fa2;">
                        <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9 text-left "><b>{{'CHECKOUT.PROMO' | translate}}
                                ({{groupOrderList.discount?.title}}):</b></div>
                        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 text-left"></div>
                        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 text-right"><b>-
                                {{(groupOrderList.discount?.amount | currency:'USD')}}</b></div>
                    </div>
                </ng-container>

                <ng-container *ngIf="groupOrderList">
                    <mat-dialog-actions>
                        <div class="text-center margin-top-20">
                            <!-- <button type="button" class="medium button-border" (click)="onClickClearCart()" [disabled]="!groupOrderList"> Clear Cart</button> -->
                            <button type="button" class="button" (click)="goToCheckOut('group')"
                                [disabled]="!groupCheckout">{{'HEADER_CART.CHECKOUT' | translate}}</button>
                        </div>
                    </mat-dialog-actions>
                </ng-container>

                <div data-cart-item="" class="cart-item" *ngIf="!groupOrderList">
                    <div class="text-center">
                        <img src="/assets/images/custom/menu/undraw_empty_cart_co35.png" height="300px">
                        <p style="color: black;"><b>{{headerCartEmptyMsg}}</b></p>
                        <p></p>
                    </div>
                </div>

            </ng-container>

            <!-- Group Friend Cart -->
            <ng-container *ngIf="loggedInUser !== groupOrderList?.customer_id">

                <!-- exist group order header -->
                <ng-container *ngIf="groupOrderList">
                    <div class="row">
                        <h3 class="cart-p-title">
                            {{'HEADER_CART.YOUR_ORDER' | translate}}
                            <span class="cpop-cart">
                                <a (click)="closeCart()" class="close-cart"><i class="fa fa-times"></i></a>
                            </span>
                        </h3>
                    </div>


                    <h5 style="color: #006fa2;">{{groupOrderList?.store_details?.name}} <span style="float: right;"> <a
                                class="view-cart" (click)="groupOrderLeave()">{{'HEADER_CART.LEAVE' | translate}} </a></span></h5>
                    <h5 style="font-weight: 400;">{{groupOrderList.group_order_type}},
                        {{getGroupOrderTime(groupOrderList.created)}}</h5>

                </ng-container>

                <hr>

                <mat-dialog-content>
                    <!-- Logged in User List -->
                    <div class="orderTitle u-block">
                        <h5>{{'HEADER_CART.YOUR_ITEMS' | translate}}</h5>
                    </div>
                    <!-- <mat-dialog-content> -->
                    <ng-container *ngIf="groupAdminCart?.items?.length">
                        <div class="cart-item">
                            <ng-container *ngFor="let item of groupAdminCart.items; let i = index">
                                <div class="cart-item border-line row">
                                    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 text-left">
                                        <span class="q-pop" *ngIf="!groupAdminCart.submission_time">
                                            <button class="card-link-secondary small mr-3"
                                                (click)="onClickDeleteGroupItem(item, groupAdminCart.id)"><i
                                                    class="fa fa-trash"></i></button>
                                        </span>
                                    </div>
                                    <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9 text-left">
                                        <p class="cart-name">
                                            <span class="q-number">{{item.quantity}} ×</span>{{item.name}}
                                            <!-- if variant and topping are in product -->
                                            <ng-container *ngIf="item.toppings?.length && item.variants?.length">
                                                <p class="extraMsgFontSize">{{'HEADER_CART.TOPPING' | translate}}:
                                                    {{getOrderVariantToppingDetail(item,'topping')}}</p>
                                                <p class="extraMsgFontSize">
                                                    {{getOrderVariantToppingDetail(item,'variant')}}</p>

                                            </ng-container>

                                            <!-- if variant and topping are not in pritemuct -->
                                            <ng-container *ngIf="!item.toppings?.length && !item.variants?.length">
                                                <!-- <td data-label="Topping">-</td>
                                                    <td data-label="Variant">-</td> -->
                                            </ng-container>

                                            <!-- if only topping is in pritemuct -->
                                            <ng-container *ngIf="item.toppings?.length && !item.variants?.length">
                                                <p class="extraMsgFontSize">{{'HEADER_CART.TOPPING' | translate}}:
                                                    {{getOrderVariantToppingDetail(item,'topping')}}</p>
                                            </ng-container>

                                            <!-- if only variant is in pritemuct -->
                                            <ng-container *ngIf="!item.toppings?.length && item.variants?.length">
                                                <p class="extraMsgFontSize">
                                                    {{getOrderVariantToppingDetail(item,'variant')}}</p>
                                            </ng-container>
                                        </p>
                                    </div>

                                    <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 text-right">
                                        <b>{{ (item.total_amount > 0) ? (item.total_amount | currency:'USD') : 'Free'}}</b>
                                    </div>

                                </div>
                            </ng-container>
                        </div>
                        <div class="row">
                            <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9 text-left ">{{'HEADER_CART.ITEM_SUB_TOTAL' | translate}}:</div>
                            <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 text-left"></div>
                            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 text-right">
                                {{ (groupAdminCart.total_price > 0) ? (groupAdminCart.total_price | currency:'USD') : 'Free'}}</div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!groupAdminCart?.items?.length">
                        <p>{{'HEADER_CART.YOUR_ITEM_EMPTY' | translate}}</p>
                    </ng-container>
                    <!-- </mat-dialog-content> -->
                    <hr>

                    <!-- Logged in User Friends List -->
                    <ng-container *ngIf="groupFriendCart?.length">
                        <div class="orderTitle u-block">
                            <h5>{{groupFriendCart?.length}} {{'HEADER_CART.FRIEND' | translate}}</h5>
                        </div>
                        <!-- <mat-dialog-content> -->
                        <mat-accordion class="style-2" [multi]="true">
                            <mat-expansion-panel class="toggle-wrap" [expanded]="false"
                                *ngFor="let friend of groupFriendCart">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <span class="trigger">
                                            <a href="javascript:void(0);">
                                                <h5 class="title-order"
                                                    *ngIf="groupOrderList?.customer_id === friend.customer_id">
                                                    {{friend.customer_name}} (Host)</h5>
                                                <h5 class="title-order"
                                                    *ngIf="groupOrderList?.customer_id !== friend.customer_id">
                                                    {{friend.customer_name}}</h5>
                                            </a>
                                        </span>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>

                                <!-- friend has item list -->
                                <div class="cart-item" *ngIf="friend.items?.length">
                                    <ng-container *ngFor="let item of friend.items">
                                        <div class="cart-item border-line row">
                                            <!-- <div class="col-lg-1 col-md-1 col-sm-1 text-left">
                                                    <span class="q-pop">
                                                        <button class="card-link-secondary small mr-3"(click)="onClickDeleteGroupItem(item)"><i class="fa fa-trash"></i></button>
                                                    </span>
                                                </div> -->
                                            <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10 text-left">
                                                <p class="cart-name">
                                                    <span class="q-number">{{item.quantity}} ×</span>{{item.name}}

                                                    <ng-container
                                                        *ngIf="item.toppings?.length && item.variants?.length">
                                                        <p class="extraMsgFontSize">{{'HEADER_CART.TOPPING' | translate}}:
                                                            {{getOrderVariantToppingDetail(item,'topping')}}</p>
                                                        <p class="extraMsgFontSize">
                                                            {{getOrderVariantToppingDetail(item,'variant')}}</p>

                                                    </ng-container>


                                                    <ng-container
                                                        *ngIf="!item.toppings?.length && !item.variants?.length">
                                                    </ng-container>


                                                    <ng-container
                                                        *ngIf="item.toppings?.length && !item.variants?.length">
                                                        <p class="extraMsgFontSize">{{'HEADER_CART.TOPPING' | translate}}:
                                                            {{getOrderVariantToppingDetail(item,'topping')}}</p>
                                                    </ng-container>


                                                    <ng-container
                                                        *ngIf="!item.toppings?.length && item.variants?.length">
                                                        <p class="extraMsgFontSize">
                                                            {{getOrderVariantToppingDetail(item,'variant')}}</p>
                                                    </ng-container>
                                                </p>
                                            </div>

                                            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 text-right">
                                                <b>{{ (item.total_amount > 0) ? (item.total_amount | currency:'USD') : 'Free'}}</b>
                                            </div>

                                        </div>
                                    </ng-container>
                                </div>

                                <!-- friend hasn't item list -->
                                <div class="cart-item" *ngIf="!friend.items?.length">
                                    <p>{{'HEADER_CART.NO_ITEM' | translate}}</p>
                                </div>
                            </mat-expansion-panel>
                        </mat-accordion>
                        <!-- </mat-dialog-content>  -->
                    </ng-container>
                    <ng-container *ngIf="!groupFriendCart?.length">
                        <p>{{'HEADER_CART.NO_FRIEND_JOIN' | translate}}</p>
                    </ng-container>

                </mat-dialog-content>
                <ng-container *ngIf="groupOrderList">
                    <div class="row">
                        <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9 text-left "><b>{{'HEADER_CART.ITEM_SUB_TOTAL' | translate}}:</b></div>
                        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 text-left"></div>
                        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 text-right">
                            <b>{{ (groupOrderList.sub_total > 0) ? (groupOrderList.sub_total | currency:'USD') : 'Free'}}</b></div>
                    </div>
                    <div class="row" *ngIf="groupOrderList.discount?.amount !== 0" style="color: #006fa2;">
                        <div class="col-lg-9 col-md-9 col-sm-9 col-xs-9 text-left "><b>{{'CHECKOUT.PROMO' | translate}}
                                ({{groupOrderList.discount?.title}}):</b></div>
                        <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1 text-left"></div>
                        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 text-right"><b>-
                                {{(groupOrderList.discount?.amount | currency:'USD')}}</b></div>
                    </div>
                </ng-container>

                <ng-container *ngIf="groupOrderList">
                    <mat-dialog-actions>
                        <div class="text-center margin-top-20">
                            <!-- <button type="button" class="medium button-border" (click)="onClickClearCart()" [disabled]="!groupOrderList"> Clear Cart</button> -->
                            <button type="button" class="button" (click)="submitGroupOrder()"
                                [disabled]="groupAdminCart.submission_time">Submit Order</button>
                        </div>
                    </mat-dialog-actions>
                </ng-container>

                <div data-cart-item="" class="cart-item" *ngIf="!groupOrderList">
                    <div class="text-center">
                        <img src="/assets/images/custom/menu/undraw_empty_cart_co35.png" height="300px">
                        <p style="color: black;"><b>{{headerCartEmptyMsg}}</b></p>
                        <p></p>
                    </div>
                </div>

            </ng-container>


        </div>
    </div>
</ng-container>

<ng-template #viewGroupOrderRef let-data let-ref="dialogRef">
    <div class="confirm-header text-left">
        <div class="row col-md-12">
            <h3><b>Group order at {{groupOrderList.store_details.name}}</b>

                <span style="float: right;">
                    <a (click)="ref.close()" class="close-cart"><i class="fa fa-times"></i></a>
                </span>
            </h3>
            <p>Check out and pay when everyone has added to the order.</p>
        </div>

    </div>
    <div class="confirm-body text-left">

        <b>Order details</b><a href="javascript:void(0)" class="links-change"
            (click)="openGroupOrderDialogBox('update')">Change</a><br />
        <ng-container *ngIf="groupOrderList.group_order_type === 'Delivery'">
            <p class="remove-gap">Deliver to {{groupOrderList?.delivery_address?.street}},
                {{groupOrderList?.delivery_address?.city}},
                {{groupOrderList?.delivery_address?.country}},
                {{groupOrderList?.delivery_address?.zipcode}}</p>
            <p>{{getGroupOrderTime(groupOrderList.created)}}</p>
        </ng-container>

        <ng-container *ngIf="groupOrderList.group_order_type === 'Pickup'">
            <p class="remove-gap">Pickup from {{groupOrderList?.store_details?.address?.street}},
                {{groupOrderList?.store_details?.address?.city}},
                {{groupOrderList?.store_details?.address?.country}},
                {{groupOrderList?.store_details?.address?.zipcode}}</p>
            <p>{{getGroupOrderTime(groupOrderList.created)}}</p>
        </ng-container>

        <b>{{(groupOrderList.carts.length - 1)}} Friends</b>
        <a class="links-change" (click)="copyLink()" *ngIf="!isCopied">Copy link</a>
        <a class="links-change-group" (click)="openShareDialogBox('Share-Button-Group')">Share link</a>&nbsp;
        <a class="links-change" *ngIf="isCopied">Link copied!</a>
        <p>Invite up to 20 friends. Your friends appear here once they join the group order.</p>
        <div class="u-flex u-flex-wrap">
            <ng-container *ngFor="let friend of groupOrderList.carts">
                <span class="groupOrder-friends" title="You" *ngIf="friend.group_admin">You</span>
                <span class="groupOrder-friends2" title="{{getFriendAvatar(friend.customer_name)}}"
                    *ngIf="!friend.group_admin">{{getFriendAvatar(friend.customer_name)}}</span>
            </ng-container>
        </div>

        <hr>
        <a class="link-but" (click)="cancelgroupOrder(cancelOrderWarningRef)">Cancel group order</a><br />
        <hr>
        <div class="text-center">
            <button class="button margin-top-20" name="submit" (click)="ref.close()">Done</button>
        </div>
    </div>
</ng-template>

<ng-template #cancelOrderWarningRef let-data let-ref="dialogRef">
    <div class="confirm-header text-center">
        <div class="confirm-header-title">
            <h4><b>Confirm Cancel</b></h4>
        </div>
    </div>
    <div class="confirm-body text-center">
        <p>
            <span class="text-danger">Do you want to cancel the group order?</span>
        </p>
    </div>
    <div class="confirm-footer text-center">
        <button type="button" class="button  btn-outline-secondary" (click)="finalCancleGroupOrder()">Yes,
            Cancel</button>
        <button type="button" class="button  btn-danger" (click)="ref.close()">No, Don't Cancel</button>
    </div>
</ng-template>

<ng-template #orderOnlineRef let-data let-ref="dialogRef">
    <div class="pop">
        <mat-dialog-title>
            <div class="order-header">
            
                <div class="df-ti"> <span class="closeButton close"><a class="link" (click)="ref.close()">X</a></span></div>
                <h4 class="model-title">{{itemDataName | titlecase}}
                    <span *ngIf="itemDataIsVeg">(Veg)</span>
                    <span *ngIf="!itemDataIsVeg">(Non-Veg)</span>
                    <!-- <span *ngIf="!isDelay && isClose">
                        <p style="color: red; font-size: medium;"><b>{{storeClose}}</b></p>
                    </span>
        
                    <span *ngIf="isDelay && !isClose">
                        <p style="color: red; font-size: medium;"><b>{{storeDelay}}</b></p>
                    </span> -->
                
                </h4>
            
            </div>
        </mat-dialog-title>
    
        <mat-dialog-content> 
            <div class="order-body">
                <h5 class="model-prise">
                    <!-- {{itemDataSalePrice}} -->
                    <span *ngIf="itemDetails?.regular_price !== 0 && itemDetails?.sale_price === 0">{{itemDetails?.formatted_regular_price}}</span>
                    <span *ngIf="itemDetails?.sale_price !== 0">{{itemDetails?.formatted_sale_price}}</span>
                    <span style="color: #5aca56;" *ngIf="itemDetails?.regular_price === 0">Free</span>
                </h5>
                <span class="model-dec">{{itemDataDescription}}</span>
                <p *ngIf="itemDataCalorieAmount">({{itemDataCalorieAmount }} Calories | {{itemDataCalorieServing}} per serving)</p>
                <div *ngIf="itemDataTopping && itemDataVariants">
                    <div *ngIf="itemDataVariants?.length">
                        <ng-container *ngFor="let variant of itemDataVariants; let i = index">
                            <div class="model-rq" *ngIf="variant.options">
                                <div class="item-variant">
                                    <label for=" {{variant.name}}"><h4><b> {{variant.name | titlecase}}</b></h4></label>
                                    <div class="row margin-bottom-20">
                                        <div class="col-sm-4" *ngFor="let option of variant.options; let i = index">
                                            <mat-radio-group [name]="variant.name">
                                                <mat-radio-button [value]="option.name" [checked]="option.checked" (change)="onChangeVariant($event)" (click)="onClickVariant(option)">
                                                    <span class="wrap-mat-radio-label">
                                                        {{option.name | titlecase}} - ${{option.additional_price}}
                                                    </span>
                                                </mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>

                    
                    <div class="model-rq" *ngIf="itemDataTopping.options?.length">
                        <div class="item-toppings">
                            <h4><b>Toppings</b></h4>
                            <span>
                                <ng-container *ngIf="itemDataToppingMinSelection > 0">
                                    <h5><span class="alert-color">*</span> Please select {{itemDataToppingMinSelection}} more toppings</h5>
                                </ng-container>
                            </span>
                            <div class="row margin-bottom-20">
                                <div class="col-md-4" *ngFor="let topping of itemDataTopping.options">
                                    <mat-checkbox (change)="onChangeTopping($event)" [checked]="topping.check" (click)="onClickTopping(topping)">
                                        <span class="wrap-mat-checkbox-label">
                                            {{topping.name | titlecase}} - ${{topping.additional_price}}
                                        </span>
                                    </mat-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="itemDataTopping  && !itemDataVariants">
                    <div class="model-rq" *ngIf="itemDataTopping.options?.length">
                        <div class="item-toppings">
                            <h4><b>Toppings</b></h4>
                            <span>
                                <ng-container *ngIf="itemDataToppingMinSelection > 0">
                                    <h5><span class="alert-color">*</span> Please select {{itemDataToppingMinSelection}} more toppings</h5>
                                </ng-container>
                            </span>
                            <div class="row margin-bottom-20">
                                <div class="col-md-4" *ngFor="let topping of itemDataTopping.options">
                                    <mat-checkbox (change)="onChangeTopping($event)" [checked]="topping.check" (click)="onClickTopping(topping)">
                                        <span class="wrap-mat-checkbox-label">
                                            {{topping.name | titlecase}} - ${{topping.additional_price}}
                                        </span>
                                    </mat-checkbox>
                                    <!-- <input type="checkbox" (change)="onChangeTopping($event.target.checked)" (click)="onClickTopping(topping)">
                                    <label > {{topping.name | titlecase}} - ${{topping.additional_price}}</label> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="!itemDataTopping && itemDataVariants">
                    <ng-container *ngFor="let variant of itemDataVariants">
                        <div class="model-rq" *ngIf="variant.options">
                            <div class="item-variant">
                                <label for=" {{variant.name}}"><h4><b> {{variant.name | titlecase}}</b></h4></label>
                                <div class="row margin-bottom-20">
                                    <div class="col-sm-4" *ngFor="let option of variant.options; let i = index">
                                        <mat-radio-group [name]="variant.name">
                                            <mat-radio-button [value]="option.name" [checked]="option.checked" (change)="onChangeVariant($event)" (click)="onClickVariant(option)">
                                                <span class="wrap-mat-radio-label">
                                                    {{option.name  | titlecase}} - ${{option.additional_price}}
                                                </span>
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </mat-dialog-content>
    
        <mat-dialog-actions>
            <div class="order-footer">
                <div class="row">
                    <div class="col-lg-6  col-sm-6 col-md-6 text-left">
                        <h5 class="model-que"> Quantity </h5>
                        <div class="qtyButtons">
                            <button class="qtyDec" type="button" (click)="addRemoveItem('remove')" [disabled]="noOfItem === 1"></button>                 
                            <input type="text" name="qtyInput" class="adults count_per_guest" value="{{noOfItem}}" readonly >
                            <button class=" qtyInc" type="button" (click)="addRemoveItem('add')"></button>
                        </div>
                    </div>
        
                    <div class="col-lg-6  col-sm-6 col-md-6 dtn-d">
                        <ng-container *ngIf="itemDataInStock">
                            <button type="button" class="button-cart modelcart border item-cart" (click)="onClickCart()" [disabled]="itemDataToppingMinSelection !== 0 "><i class="fa fa-shopping-cart "> </i>  <span class="text-cart"> Update Cart {{ itemDetails?.regular_price !== 0 ? (totalPrice | currency:'USD') : ''}}</span></button>
                        </ng-container>

                        <ng-container *ngIf="!itemDataInStock">
                            <button type="button" class="button-cart modelcart border item-cart stock"><b class="text-cart">Out of Stock</b></button>
                        </ng-container>
                    </div>
                </div>
            </div>
        </mat-dialog-actions>
    </div>
</ng-template>
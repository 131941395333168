export class BusinessModel {
    id?: string;
    uid?: string;
    address?: Address;
    businessHours?: BusinessHours[];
    categories?: string[];
    createdAt?: string;
    description?: string;
    discounts?: string[];
    email?: string;
    fulfillmentTypes?: string[];
    fulfillmentTypesJoin?: string;
    isActive?: boolean;
    isVerified?: boolean;
    lastOrderNo?: number;
    location?: LocationObject;
    logo?: string;
    name?: string;
    phone?: string;
    productInfo?: ProductInfo;
    qrcodeUrl?: string;
    settings?: any[];
    socialLinks?: any[];
    storeGallery?: any[];
    tags?: any[];
    updatedAt?: string;
    varifiedAt?: string;
    status?: string;
    isFavStore?: boolean;
}

export class Address {
    city?: string;
    country?: string;
    latitude?: string;
    longitude?: string;
    state?: string;
    street?: string;
    zipcode?: string;
}

export class BusinessHours {
    close?: string;
    day?: string;
    offday?: string;
    open?: string;
}

export class LocationObject {
    coordinates?: number[];
    type?: string;
}

export class ProductInfo {
    menu?: string[];
    products?: any[];
}

import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatDialogModule } from '@angular/material/dialog';

// for header menu
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';

// header-cart popover
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTabsModule } from '@angular/material/tabs';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { HeaderComponent } from './header/header.component';
import { HeaderCartComponent } from './header-cart/header-cart.component';
import { FooterComponent } from './footer/footer.component';
import { ConfirmationBoxComponent } from './confirmation-box/confirmation-box.component';

import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { ClipboardModule } from 'ngx-clipboard';
import { MatExpansionModule } from '@angular/material/expansion';
import { BusinessDetailComponent } from '../layouts/business/business-detail/business-detail.component';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    HeaderComponent,
    HeaderCartComponent,
    FooterComponent,
    ConfirmationBoxComponent,
    NotFoundPageComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    NgxSpinnerModule,
    MatDialogModule,
    MatMenuModule,
    MatIconModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatInputModule,
    MatSidenavModule,
    MatToolbarModule,
    MatBadgeModule,
    NgbModule,
    ShareButtonsModule,
    LazyLoadImageModule,
    NgxMaskModule.forRoot(),
    ClipboardModule,
    MatTabsModule,
    MatExpansionModule,
    TranslateModule,
    MatRadioModule,
    MatCheckboxModule,
  ],
  providers: [

  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    ReactiveFormsModule,
    FormsModule,
    NotFoundPageComponent,
  ],
  entryComponents: [
    HeaderCartComponent,
    BusinessDetailComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]

})
export class SharedModule { }

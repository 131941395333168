<!-- Header -->
<header id="header-container" class="fixed fullwidth"
    [ngClass]="headerWidth === true?'fixed fullwidth':nonHeadWidth === false?'fullwidth':''">

    <div id="header" *ngIf="sticky===false">
        <div class="container">

            <!-- Left Side Content -->
            <div class="left-side">

                <!-- Logo -->
                <div id="logo">
                    <a (click)="redirectOnClickLogo()">
                        <img src="/assets/images/custom/logo.svg" data-sticky-logo="/assets/images/custom/logo.svg" alt="" title="Chat My Order">
                    </a>    
                </div>

                <!-- Mobile Navigation -->
                <div class="mmenu-trigger" (click)="handelResponsiveMenu()">
                    <button class="hamburger hamburger--collapse" type="button">
                        <span class="hamburger-box">
                            <span class="hamburger-inner"></span>
                        </span>
                    </button>
                </div>

                <!-- testing hamburger -->
                <ng-container *ngIf="mobileMenu && !hamburgerCondition">
                    <div role="navigation" aria-label="main navigation">
                        <div class="navbar-brand">

                            <a (click)="toggleNav()" role="button" #navBurger class="navbar-burger burger"
                                aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
                                <span aria-hidden="true"></span>
                                <span aria-hidden="true"></span>
                                <span aria-hidden="true"></span>
                            </a>
                        </div>

                        <div id="navbarBasicExample" class="navbar-menu" #navMenu>
                            <div class="navbar-start">
                                <ul class="nav-ul">
                                    <!-- <li class="nav-item">
                                        <a class="navbar-item" href="javascript:void(0); " [routerLink]="['/home']"
                                            routerLinkActive="current">
                                            {{'HEADER.HOME' | translate}}
                                        </a>
                                    </li> -->
                                    <li class="nav-item">
                                        <a class="navbar-item" href="javascript:void(0); " [routerLink]="['/business']"
                                            routerLinkActive="current">
                                            {{'HEADER.BUSINESSES' | translate}}
                                        </a>
                                    </li>
                                    <ng-container *ngIf="headerMessage !== 'home' && headerMessage !== 'business'">
                                        <li class="nav-item">
                                            <div class="center-side">
                                                <div class="input-with-icon">
                                                    <i class="im im-icon-Location-2 map-color"></i>
                                                    <input type="text" [(ngModel)]="currentLocation" #customerAddress (keyup)="getAddress()"
                                                        [matAutocomplete]="locationGroup" placeholder="{{'SEARCH.LOCATION' | translate}}" />
                                                    <mat-autocomplete #locationGroup="matAutocomplete">
                                                        <mat-option (click)="onClickCurrentLocation()">
                                                            <i class="fa fa-map-o"> {{'SEARCH.CURRENT_LOCATION' | translate}}</i>
                                                        </mat-option>
                                                        <mat-option *ngFor="let place of places" [value]="place.description"
                                                            (click)="selectLocation(place)">
                                                            {{ place.description }}
                                                        </mat-option>
                                                        <mat-option *ngIf="hasLocation">
                                                            <p style="color: red;">{{'SEARCH.NOT_SERVE' | translate}}</p>
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="nav-item">
                                            <div class="center-side">
                                                <div class="input-with-icon">
                                                    <i class="sl sl-icon-magnifier magnifier-color"></i>
                                                    <input id="myInput" type="text" placeholder="{{'HEADER.HEADER_SEARCH' | translate}}"
                                                        [(ngModel)]="searchLocationValue" (ngModelChange)="onSearchLocation($event)"
                                                        [matAutocomplete]="placesGroup" />
                                                    <mat-autocomplete #placesGroup="matAutocomplete">
                                                        <mat-option *ngFor="let filter of filterList" [value]="filter.name"
                                                            (click)="selectPlace(filter)">
                                                            {{ filter.name }}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </div>
                                            </div>
                                        </li>
                                    </ng-container>
                                </ul>
                            </div>
                        </div>
                    </div>
                </ng-container>


                <!-- Main Navigation -->
                <nav id="navigation" class="style-1 margin-top-10" *ngIf="showHeader===false">
                    <ul id="responsive">
                        <!-- <li><a href="javascript:void(0); " [routerLink]="['/home']" routerLinkActive="current">{{'HEADER.HOME' | translate}}</a>
                        </li> -->
                        <!-- <li><a href="javascript:void(0); " [routerLink]="['/business']"
                                routerLinkActive="current">{{'HEADER.BUSINESSES' | translate}}</a></li> -->
                    </ul>
                </nav>
                <nav id="navigation" class="style-1 margin-top-10" *ngIf="showHeader===true">
                    <ul id="responsive">
                        <!-- <li><a href="javascript:void(0);" [routerLink]="['/home']" routerLinkActive="current" style="background: rgba(2, 119, 189, 0.08);
                                    color: #41d5ff;">{{'HEADER.HOME' | translate}}</a></li> -->
                        <!-- <li><a href="javascript:void(0);" [routerLink]="['/business']"
                                routerLinkActive="current">{{'HEADER.BUSINESSES' | translate}}</a></li> -->
                    </ul>
                </nav>
                <!-- Main Navigation / End -->

                <ng-container class="style-1" *ngIf="headerMessage !== 'home' && headerMessage !== 'business' && headerMessage !== 'signin' && headerMessage !== 'signup'
                    && headerMessage !== 'add-business' && headerMessage !== 'privacy-policy' && headerMessage !== 'terms-condition' && hamburgerCondition">
                    <div class="row margin-top-5">
                        <!-- Location Search -->
                        <div class="center-side">
                            <div class="input-with-icon">
                                <i class="im im-icon-Location-2 map-color"></i>
                                <input type="text" [(ngModel)]="currentLocation" #customerAddress (keyup)="getAddress()"
                                    [matAutocomplete]="locationGroup" placeholder="{{'SEARCH.LOCATION' | translate}}" />
                                <mat-autocomplete #locationGroup="matAutocomplete">
                                    <mat-option (click)="onClickCurrentLocation()">
                                        <i class="fa fa-map-o"> {{'SEARCH.CURRENT_LOCATION' | translate}}</i>
                                    </mat-option>
                                    <mat-option *ngFor="let place of places" [value]="place.description"
                                        (click)="selectLocation(place)">
                                        {{ place.description }}
                                    </mat-option>
                                    <mat-option *ngIf="hasLocation">
                                        <p style="color: red;">{{'SEARCH.NOT_SERVE' | translate}}</p>
                                    </mat-option>
                                </mat-autocomplete>
                            </div>
                        </div>


                        <!-- Other Search -->
                        <div class="center-side">
                            <div class="input-with-icon">
                                <i class="sl sl-icon-magnifier magnifier-color"></i>
                                <input id="myInput" type="text" placeholder="{{'HEADER.HEADER_SEARCH' | translate}}"
                                    [(ngModel)]="searchLocationValue" (keyup)="onSearchLocation($event)"
                                    [matAutocomplete]="placesGroup" />
                                <mat-autocomplete #placesGroup="matAutocomplete">
                                    <mat-option *ngFor="let filter of filterList" [value]="filter.name"
                                        (click)="selectPlace(filter)">
                                        {{ filter.name }}
                                    </mat-option>
                                </mat-autocomplete>
                            </div>
                        </div>
                    </div>
                </ng-container>

            </div>
            <!-- Left Side Content / End -->


            <!-- Right Side Content / End -->
            <div class="right-side">
                <div class="row header-widget" *ngIf="isLogin===false && showHeader===false">
                    <!-- User Menu -->

                    <div class="user-menu newchatsign">
                        <div class="user-name" mat-button [matMenuTriggerFor]="menu1">
                            <a> {{'HEADER.SIGN_IN_UP' | translate}}</a>
                        </div>
                        <mat-menu #menu1="matMenu" class="menu1-panel" xPosition="after">
                            <div data-test="" class="anw9to-5 sdf czqGVU">
                                <div class="anw9to-6 bfWrir">{{'HEADER.CUSTOMER' | translate}} </div>
                                <div class="anw9to-7 dMEFty">
                                    <span class=""><a [routerLink]="['/signin']" href="javascript:void(0);"
                                            class="esebpo-0 anw9to-8 anw9to-9 bNYeLu menu-link" role="button" tabindex="0">{{'SIGN_IN.SUBMIT' | translate}}</a></span>
                                    |
                                    <span class=""><a [routerLink]="['/signup']" href="javascript:void(0);"
                                            class="esebpo-0 anw9to-8 ktAUZJ menu-link" role="button" tabindex="0">{{'SIGN_UP.SUBMIT' | translate}}</a></span>
                                </div>
                            </div>

                            <div data-test="" class="anw9to-5 czqGVU">
                                <div class="anw9to-6 bfWrir">{{'HEADER.BUSINESS' | translate}} </div>
                                <div class="anw9to-7 dMEFty">
                                    <span class=""><a href="javascript:void(0);" href="{{businessLink}}"
                                            class="esebpo-0 anw9to-8 ktAUZJ menu-link" role="button" tabindex="0">{{'SIGN_IN.SUBMIT' | translate}}</a></span>
                                    |
                                    <span class=""><a  href="{{businessRegistLink}}"
                                            class="esebpo-0 anw9to-8 ktAUZJ menu-link" role="button" tabindex="0">{{'SIGN_UP.SUBMIT' | translate}}</a></span>
                                </div>
                            </div>
                        </mat-menu>
                        
                    </div>

                    <!-- <div class="lang-menu newchatsign">
                        <div class="user-name" mat-button [matMenuTriggerFor]="langMenu">
                            <a> {{'HEADER.SELECT_LANG' | translate}}</a>
                        </div>
                        <mat-menu #langMenu="matMenu" class="menu2-panel" xPosition="after">
                            <div data-test="" class="anw9to-5 sdf czqGVU">
                                <div class="anw9to-7 dMEFty">
                                    <span class="">
                                        <a (click)="switchLanguage('en')" href="javascript:void(0);"
                                            class="esebpo-0 anw9to-8 anw9to-9 bNYeLu menu-link" role="button" tabindex="0">English</a>
                                    </span>
                                </div>
                            </div>

                            <div data-test="" class="anw9to-5 czqGVU">
                                <div class="anw9to-7 dMEFty">
                                    <span class="">
                                        <a (click)="switchLanguage('es')" href="javascript:void(0);"
                                            class="esebpo-0 anw9to-8 anw9to-9 bNYeLu menu-link" role="button" tabindex="0">{{'HEADER.SPANISH' | translate}}</a>
                                    </span>
                                </div>
                            </div>
                        </mat-menu>
                    </div> -->
                
                    <!-- <button type="button" data-cart-toggle class="bt" style="margin-top:8px;" (click)="onClickMyCart()">
                        <span class="new-cart"><i class="fa fa-shopping-cart" aria-hidden="true"></i></span> <span
                            class="badge"></span> </button> -->

                    <!-- <button type="button" title="Notifications" data-cart-toggle class="bt" style="margin-top:8px;" mat-button [matMenuTriggerFor]="notif" (click)="onClickNotifications()">
                        <span class="new-cart">
                            <mat-icon [ngStyle]="{'color':'#333'}" [matBadge]="">notification_important</mat-icon>
                        </span>
                    </button>
                    <mat-menu #notif="matMenu">
                        <div class="dashboard-list-box with-icons">
                            <img src="/assets/images/custom/menu/undraw_notifications.png" height="200px" width="">
                                <p style="color: #333; text-align: center;"><b>{{'HEADER.NO_NOTIFICATION' | translate}}</b></p>  
                        </div>
                    </mat-menu> -->
                    <button type="button"  title="My Cart" data-cart-toggle class="bt" style="margin-top:8px;" (click)="onClickMyCart()">
                        <span class="new-cart"><mat-icon [ngStyle]="{'color':'#333'}" [matBadge]="" >shopping_cart</mat-icon></span> 
                    </button>
                </div>
                <div class="header-widget" *ngIf="isLogin===true && showHeader===false">
                    <div class="user-menu">
                        <div class="user-name" mat-button [matMenuTriggerFor]="menu2">
                            <a ><span><div id="avatarName">{{avatar}}</div></span> {{'HEADER.HI' | translate}} {{avatarFirstName}}</a>
                        </div>
                        <mat-menu #menu2="matMenu" class="menu2-panel">
                            <a mat-menu-item [routerLink]="['/account/profile']"><i class="sl sl-icon-user"></i> {{'HEADER.MY_ACCOUNT' | translate}}</a>
                            <a mat-menu-item (click)="logout()"><i class="sl sl-icon-power"></i> {{'HEADER.LOGOUT' | translate}}</a>
                        </mat-menu>
                    </div>

                    <!-- <div class="lang-menu newchatsign">
                        <div class="user-name" mat-button [matMenuTriggerFor]="langMenu">
                            <a> {{'HEADER.SELECT_LANG' | translate}}</a>
                        </div>
                        <mat-menu #langMenu="matMenu" class="menu2-panel" xPosition="after">
                            <div data-test="" class="anw9to-5 sdf czqGVU">
                                <div class="anw9to-7 dMEFty">
                                    <span class="">
                                        <a (click)="switchLanguage('en')" href="javascript:void(0);"
                                            class="esebpo-0 anw9to-8 anw9to-9 bNYeLu menu-link" role="button" tabindex="0">English</a>
                                    </span>
                                </div>
                            </div>

                            <div data-test="" class="anw9to-5 czqGVU">
                                <div class="anw9to-7 dMEFty">
                                    <span class="">
                                        <a (click)="switchLanguage('es')" href="javascript:void(0);"
                                            class="esebpo-0 anw9to-8 anw9to-9 bNYeLu menu-link" role="button" tabindex="0">{{'HEADER.SPANISH' | translate}}</a>
                                    </span>
                                </div>
                            </div>
                        </mat-menu>
                    </div> -->

                    <!-- <button type="button" data-cart-toggle class="bt" style="margin-top:8px;" (click)="onClickMyCart()">
                        <span class="new-cart"><i class="fa fa-shopping-cart" aria-hidden="true"></i></span> <span
                            class="badge">{{cartDetails}}</span> </button> -->
                    <button type="button" title="Notifications" data-cart-toggle class="bt" style="margin-top:8px;" mat-button [matMenuTriggerFor]="notification1" (click)="onClickNotifications()">
                        <span class="new-cart">
                            <mat-icon [ngStyle]="{'color':'#333'}" [matBadge]="notificationListLength">notifications</mat-icon>
                        </span>
                    </button>

                    <mat-menu #notification1="matMenu">
                        <div class="dashboard-list-box with-icons  margin-top-5">
                            <ng-container *ngIf="notificationList?.length">
                                <!-- <div class="row notbox"> -->
                                    <!-- <a class="noti-btn"  (click)="onClickAllMarkAsReadNotification()">All mark as read</a>
                              
                                    <button class="button btn-danger noti-ng" (click)="onClickDeleteAllNotification(deleteNotificationRef)">Delete All</button> -->
                                <!-- </div> -->
                                <div class="parent" *ngFor="let notification of notificationList">
                                    <!-- <div class="left">                                        
                                            <img _ngcontent-idv-c212="" src="/assets/images/custom/logo.svg" class="noti-logo" data-sticky-logo="/assets/images/custom/logo.svg" alt=""  title="Chat My Order">                                      
                                        
                                    </div> -->
                                    <div class="right">
                                        <h6 class="noti">{{notification.title}}      </h6> 
                                        <span class="notip">{{notification.body}}</span> <br/>
                                        <a (click)="markAsReadNotification(notification._id)" *ngIf="!notification.read"><i class="fa fa-check" style=" color: #00a4ef; cursor: pointer;" title="Mark as read"></i></a>&nbsp;&nbsp;
                                        <span style="margin-left: 5px;"><a (click)="deleteNotification(notification._id)"><i class="fa fa-trash" style=" color: red; cursor: pointer;" title="Delete Notification"></i></a></span>
                                        <span style="float: right;font-size: 13px;">{{getNotificationTime(notification.created)}}</span>
                                    </div> 
                                </div>
                                <div class="row notbox text-center">
                                    <button class="button btn-primary" routerLink="/account/notification">More Notifications</button>
                                </div>
                            </ng-container>

                            <ng-container *ngIf="!notificationList?.length">
                                <img src="/assets/images/custom/menu/undraw_notifications.png" height="200px" width="">
                                <p style="color: #333; text-align: center;"><b>{{'HEADER.NO_NOTIFICATION' | translate}}</b></p>  
                            </ng-container>
                        </div>
                    </mat-menu>
                    <button type="button"  title="My Cart" data-cart-toggle class="bt" style="margin-top:8px;" (click)="onClickMyCart()">
                        <span class="new-cart"><mat-icon [ngStyle]="{'color':'#333'}" [matBadge]="cartDetails" >shopping_cart</mat-icon></span> </button>
                            
                </div>
            </div>

        </div>
    </div>
    <!-- Header -->
    <div id="header" #stickyMenu [ngClass]="sticky===true?'cloned sticky':'cloned unsticky'">
        <div class="container">

            <!-- Left Side Content -->
            <div class="left-side">

                <!-- Logo -->
                <div id="logo">
                    <a routerLink="/home"><img src="/assets/images/custom/logo.svg"
                            data-sticky-logo="/assets/images/custom/logo.svg" alt="" title="Chat My Order"></a>
                </div>

                <!-- Mobile Navigation -->
                <div class="mmenu-trigger" (click)="handelResponsiveMenu()">
                    <button class="hamburger hamburger--collapse" type="button">
                        <span class="hamburger-box">
                            <span class="hamburger-inner"></span>
                        </span>
                    </button>
                </div>

                <!-- Main Navigation -->
                <ng-container *ngIf="mobileMenu && !hamburgerCondition">
                    <div role="navigation" aria-label="main navigation">
                        <div class="navbar-brand">

                            <a (click)="toggleNav()" role="button" #navBurger class="navbar-burger burger"
                                aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
                                <span aria-hidden="true"></span>
                                <span aria-hidden="true"></span>
                                <span aria-hidden="true"></span>
                            </a>
                        </div>

                        <div id="navbarBasicExample" class="navbar-menu" #navMenu>
                            <div class="navbar-start">
                                <ul class="nav-ul">
                                    <li class="nav-item">
                                        <a class="navbar-item" href="javascript:void(0); " [routerLink]="['/home']"
                                            routerLinkActive="current">
                                            {{'HEADER.HOME' | translate}}
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="navbar-item" href="javascript:void(0); " [routerLink]="['/business']"
                                            routerLinkActive="current">
                                            {{'HEADER.BUSINESSES' | translate}}
                                        </a>
                                    </li>
                                    <ng-container *ngIf="headerMessage !== 'home' && headerMessage !== 'business'">
                                        <li class="nav-item">
                                            <div class="center-side">
                                                <div class="input-with-icon">
                                                    <i class="sl sl-icon-location-pin map-color"></i>
                                                    <input type="text" [(ngModel)]="currentLocation" #customerAddress (keyup)="getAddress()"
                                                        [matAutocomplete]="locationGroup" placeholder="{{'SEARCH.LOCATION' | translate}}" />
                                                    <mat-autocomplete #locationGroup="matAutocomplete">
                                                        <mat-option (click)="onClickCurrentLocation()">
                                                            <i class="fa fa-map-o"> {{'SEARCH.CURRENT_LOCATION' | translate}}</i>
                                                        </mat-option>
                                                        <mat-option *ngFor="let place of places" [value]="place.description"
                                                            (click)="selectLocation(place)">
                                                            {{ place.description }}
                                                        </mat-option>
                                                        <mat-option *ngIf="hasLocation">
                                                            <p style="color: red;">*We don't serve at your location</p>
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="nav-item">
                                            <div class="center-side">
                                                <div class="input-with-icon">
                                                    <i class="sl sl-icon-magnifier magnifier-color"></i>
                                                    <input id="myInput" type="text" placeholder="{{'HEADER.HEADER_SEARCH' | translate}}"
                                                        [(ngModel)]="searchLocationValue" (keyup)="onSearchLocation($event)"
                                                        [matAutocomplete]="placesGroup" />
                                                    <mat-autocomplete #placesGroup="matAutocomplete">
                                                        <mat-option *ngFor="let filter of filterList" [value]="filter.name"
                                                            (click)="selectPlace(filter)">
                                                            {{ filter.name }}
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                </div>
                                            </div>
                                        </li>
                                    </ng-container>
                                </ul>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <!-- Main Navigation -->
                <nav id="navigation" class="style-1 margin-top-10" *ngIf="showHeader===false">
                    <ul id="responsive">
                        <li><a href="javascript:void(0); " [routerLink]="['/home']" routerLinkActive="current">{{'HEADER.HOME' | translate}}</a>
                        </li>
                        <li><a href="javascript:void(0); " [routerLink]="['/business']"
                                routerLinkActive="current">{{'HEADER.BUSINESSES' | translate}}</a></li>
                    </ul>
                </nav>
                <nav id="navigation" class="style-1 margin-top-10" *ngIf="showHeader===true">
                    <ul id="responsive">
                        <li><a href="javascript:void(0);" [routerLink]="['/home']" routerLinkActive="current" style="background: rgba(2, 119, 189, 0.08);
                                    color: #41d5ff;">{{'HEADER.HOME' | translate}}</a></li>
                        <li><a href="javascript:void(0);" [routerLink]="['/business']"
                                routerLinkActive="current">{{'HEADER.BUSINESSES' | translate}}</a></li>
                    </ul>
                </nav>
                <!-- Main Navigation / End -->

                <ng-container class="style-1" *ngIf="headerMessage !== 'home' && headerMessage !== 'business' && hamburgerCondition">
                    <div class="row margin-top-5">
                        <!-- Location Search -->
                        <div class="center-side">
                            <div class="input-with-icon">
                                <i class="im im-icon-Location-2 map-color"></i>
                                <input type="text" [(ngModel)]="currentLocation" #customerAddress (keyup)="getAddress()"
                                    [matAutocomplete]="locationGroup" placeholder="{{'SEARCH.LOCATION' | translate}}" />
                                <mat-autocomplete #locationGroup="matAutocomplete">
                                    <mat-option (click)="onClickCurrentLocation()">
                                        <i class="fa fa-map-o">{{'SEARCH.CURRENT_LOCATION' | translate}}</i>
                                    </mat-option>
                                    <mat-option *ngFor="let place of places" [value]="place.description"
                                        (click)="selectLocation(place)">
                                        {{ place.description }}
                                    </mat-option>
                                    <mat-option *ngIf="hasLocation">
                                        <p style="color: red;">*We don't serve at your location</p>
                                    </mat-option>
                                </mat-autocomplete>
                            </div>
                        </div>


                        <!-- Other Search -->
                        <div class="center-side">
                            <div class="input-with-icon">
                                <i class="sl sl-icon-magnifier magnifier-color"></i>
                                <input id="myInput" type="text" placeholder="{{'HEADER.HEADER_SEARCH' | translate}}"
                                    [(ngModel)]="searchLocationValue" (keyup)="onSearchLocation($event)"
                                    [matAutocomplete]="placesGroup" />
                                <mat-autocomplete #placesGroup="matAutocomplete">
                                    <mat-option *ngFor="let filter of filterList" [value]="filter.name"
                                        (click)="selectPlace(filter)">
                                        {{ filter.name }}
                                    </mat-option>
                                </mat-autocomplete>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <!-- Left Side Content / End -->


            <!-- Right Side Content / End -->
            <div class="right-side">
                <div class="header-widget" *ngIf="isLogin===false && showHeader===false">
                    <!-- User Menu -->

                    <div class="user-menu newchatsign">
                        <div class="user-name" mat-button [matMenuTriggerFor]="menu1">
                            <a> {{'HEADER.SIGN_IN_UP' | translate}}</a>
                        </div>
                        <mat-menu #menu1="matMenu" class="menu1-panel" xPosition="after">
                            <div data-test="" class="anw9to-5 sdf czqGVU">
                                <div class="anw9to-6 bfWrir">{{'HEADER.CUSTOMER' | translate}} </div>
                                <div class="anw9to-7 dMEFty">
                                    <span class=""><a [routerLink]="['/signin']" href="javascript:void(0);"
                                            class="esebpo-0 anw9to-8 anw9to-9 bNYeLu menu-link" role="button" tabindex="0">{{'SIGN_IN.SUBMIT' | translate}}</a></span>
                                    |
                                    <span class=""><a [routerLink]="['/signup']" href="javascript:void(0);"
                                            class="esebpo-0 anw9to-8 ktAUZJ menu-link" role="button" tabindex="0">{{'SIGN_UP.SUBMIT' | translate}}</a></span>
                                </div>
                            </div>

                            <div data-test="" class="anw9to-5 czqGVU">
                                <div class="anw9to-6 bfWrir">Business </div>
                                <div class="anw9to-7 dMEFty">
                                    <span class=""><a href="javascript:void(0);" href="{{businessLink}}"
                                            class="esebpo-0 anw9to-8 ktAUZJ menu-link" role="button" tabindex="0">{{'SIGN_IN.SUBMIT' | translate}}</a></span>
                                    |
                                    <span class=""><a [routerLink]="['/add-business']"
                                            class="esebpo-0 anw9to-8 ktAUZJ menu-link" role="button" tabindex="0">{{'SIGN_UP.SUBMIT' | translate}}</a></span>
                                </div>
                            </div>
                        </mat-menu>
                    </div>

                    <!-- <div class="lang-menu newchatsign">
                        <div class="user-name" mat-button [matMenuTriggerFor]="langMenu1">
                            <a> {{'HEADER.SELECT_LANG' | translate}}</a>
                        </div>
                        <mat-menu #langMenu1="matMenu" class="menu2-panel" xPosition="after">
                            <div data-test="" class="anw9to-5 sdf czqGVU">
                                <div class="anw9to-7 dMEFty">
                                    <span class="">
                                        <a (click)="switchLanguage('en')" href="javascript:void(0);"
                                            class="esebpo-0 anw9to-8 anw9to-9 bNYeLu menu-link" role="button" tabindex="0">English</a>
                                    </span>
                                </div>
                            </div>

                            <div data-test="" class="anw9to-5 czqGVU">
                                <div class="anw9to-7 dMEFty">
                                    <span class="">
                                        <a (click)="switchLanguage('es')" href="javascript:void(0);"
                                            class="esebpo-0 anw9to-8 anw9to-9 bNYeLu menu-link" role="button" tabindex="0">{{'HEADER.SPANISH' | translate}}</a>
                                    </span>
                                </div>
                            </div>
                        </mat-menu>
                    </div> -->

                    <!-- <button type="button" data-cart-toggle class="bt" style="margin-top:8px;" (click)="onClickMyCart()">
                        <span class="new-cart"><i class="fa fa-shopping-cart" aria-hidden="true"></i></span> <span
                            class="badge"></span> </button> -->
                            
                    <!-- <button type="button" title="Notifications" data-cart-toggle class="bt" style="margin-top:8px;" mat-button [matMenuTriggerFor]="notif1" (click)="onClickNotifications()">
                        <span class="new-cart">
                            <mat-icon [ngStyle]="{'color':'#333'}" [matBadge]="">notification_important</mat-icon>
                        </span>
                    </button>  
                    <mat-menu #notif1="matMenu">
                        <div class="dashboard-list-box with-icons">
                            <img src="/assets/images/custom/menu/undraw_notifications.png" height="200px" width="">
                                <p style="color: #333; text-align: center;"><b>{{'HEADER.NO_NOTIFICATION' | translate}}</b></p>  
                        </div>
                    </mat-menu>                           -->
                    <button type="button" title="My Cart" data-cart-toggle class="bt" style="margin-top:8px;" (click)="onClickMyCart()">
                        <span class="new-cart"><mat-icon [ngStyle]="{'color':'#333'}" [matBadge]="" >shopping_cart</mat-icon></span> </button>

                </div>
                <div class="header-widget" *ngIf="isLogin===true && showHeader===false">
                    <div class="user-menu">
                        <div class="user-name" mat-button [matMenuTriggerFor]="menu4">
                            <a><span><div id="avatarName">{{avatar}}</div></span> Hi! {{avatarFirstName}}</a>
                        </div>
                        <mat-menu #menu4="matMenu" class="menu4-panel">
                            <a mat-menu-item [routerLink]="['/account/profile']"><i class="sl sl-icon-user"></i> {{'HEADER.MY_ACCOUNT' | translate}}</a>
                            <a mat-menu-item (click)="logout()"><i class="sl sl-icon-power"></i> {{'HEADER.LOGOUT' | translate}}</a>
                        </mat-menu>
                    </div>

                    <!-- <div class="lang-menu newchatsign">
                        <div class="user-name" mat-button [matMenuTriggerFor]="langMenu">
                            <a> {{'HEADER.SELECT_LANG' | translate}}</a>
                        </div>
                        <mat-menu #langMenu="matMenu" class="menu2-panel" xPosition="after">
                            <div data-test="" class="anw9to-5 sdf czqGVU">
                                <div class="anw9to-7 dMEFty">
                                    <span class="">
                                        <a (click)="switchLanguage('en')" href="javascript:void(0);"
                                            class="esebpo-0 anw9to-8 anw9to-9 bNYeLu menu-link" role="button" tabindex="0">English</a>
                                    </span>
                                </div>
                            </div>

                            <div data-test="" class="anw9to-5 czqGVU">
                                <div class="anw9to-7 dMEFty">
                                    <span class="">
                                        <a (click)="switchLanguage('es')" href="javascript:void(0);"
                                            class="esebpo-0 anw9to-8 anw9to-9 bNYeLu menu-link" role="button" tabindex="0">{{'HEADER.SPANISH' | translate}}</a>
                                    </span>
                                </div>
                            </div>
                        </mat-menu>
                    </div> -->

                    <!-- <button type="button" data-cart-toggle class="bt" style="margin-top:8px;" (click)="onClickMyCart()">
                        <span class="new-cart"><i class="fa fa-shopping-cart" aria-hidden="true"></i></span> <span
                            class="badge">{{cartDetails}}</span> </button> -->
                    <button type="button" title="Notifications" class="bt" style="margin-top:8px;" mat-button [matMenuTriggerFor]="notification2" (click)="onClickNotifications()">
                        <span class="new-cart">
                            <mat-icon [ngStyle]="{'color':'#333'}" [matBadge]="notificationListLength">notification_important</mat-icon>
                        </span>
                    </button>
                    <mat-menu #notification2="matMenu">
                        <div class="dashboard-list-box with-icons margin-top-5">
                            <ng-container *ngIf="notificationList?.length">
                                
                                <div class="row notbox">
                                    <a class="noti-btn"  (click)="onClickAllMarkAsReadNotification()">All mark as read</a>
                                    <button class="button btn-danger noti-ng"  (click)="onClickDeleteAllNotification(deleteNotificationRef)">Delete All</button>
                                </div>
                                <div class="parent" *ngFor="let notification of notificationList">
                                    <div class="left">                                        
                                            <img _ngcontent-idv-c212="" src="/assets/images/custom/logo.svg" class="noti-logo" data-sticky-logo="/assets/images/custom/logo.svg" alt=""  title="Chat My Order">                                      
                                       
                                    </div>
                                    <div class="right">
                                        <h6 class="noti">{{notification.title}}      </h6> 
                                        <span class="notip">{{notification.body}}</span> <br/>
                                        <a (click)="markAsReadNotification(notification._id)" *ngIf="!notification.read"><i class="fa fa-check" style=" color: #00a4ef; cursor: pointer;" title="Mark as read"></i></a>
                                        <span style="margin-left: 5px;"><a (click)="deleteNotification(notification._id)"><i class="fa fa-trash" style=" color: red; cursor: pointer;" title="Delete Notification"></i></a></span>                            
                                    </div> 
                                  
                                 </div> 
                            </ng-container>                            

                             <ng-container *ngIf="!notificationList?.length">
                                <img src="/assets/images/custom/menu/undraw_notifications.png" height="200px" width="">
                                <p style="color: #333; text-align: center;"><b>{{'HEADER.NO_NOTIFICATION' | translate}}</b></p>  
                            </ng-container>
                        </div>
                    </mat-menu>
                    <button type="button"  title="My Cart" data-cart-toggle class="bt" style="margin-top:8px;" (click)="onClickMyCart()">
                        <span class="new-cart"><mat-icon [ngStyle]="{'color':'#333'}" [matBadge]="cartDetails" >shopping_cart</mat-icon></span> </button>
                </div>
            </div>

        </div>
    </div>
    <!-- Header / End -->
    <div id="gMap" #gMap></div>
</header>

<!-- delete notification confirm box -->
<ng-template #deleteNotificationRef let-data let-ref="dialogRef">
    <div class="confirm-header text-center">
        <div class="confirm-header-title">
            <h4><b>Confirm Delete</b></h4>
        </div>
    </div>
    <div class="confirm-body text-center">
        <p>
            <span class="text-danger">Do you want to delete all notifications?</span>
        </p>
    </div>
    <div class="confirm-footer text-center">
        <button type="button" class="button  btn-outline-secondary" (click)="deleteAllNotifications()">Yes, Confirm</button>
        <button type="button" class="button  btn-danger" (click)="ref.close()">No, Don't </button>
    </div>
</ng-template>
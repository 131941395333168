import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { CommonAPIResponse } from '../../../shared/Models/common.Model';

@Injectable({
    providedIn: 'root'
})
export class BusinessService {
    private apiUrl = environment.apiUrl;

    constructor(private http: HttpClient) { }

    public getFilterListByLocation(model, pageNo, pageSize): Observable<CommonAPIResponse> {
        // let data;
        // if (model.latitude !== undefined && model.query !== undefined) {
        //     data = {
        //         query: model.query,
        //         latitude: model.latitude,
        //         longitude: model.longitude
        //     };
        // } else if (model.query !== undefined && model.latitude === undefined) {
        //     data = {
        //         query: model.query,
        //     };
        // } else if (model.latitude !== undefined && model.query === undefined) {
        //     data = {
        //         latitude: model.latitude,
        //         longitude: model.longitude
        //     };
        // } else {
        //     data = {};
        // }
        // console.log(pageNo);
        // return this.http.post<any>(this.apiUrl + 'listings/filter', data);
        // return this.http.post<any>(this.apiUrl + 'listings/filter?page=' + pageNo + '&per_page=' + pageSize , data);
        return this.http.post<any>(this.apiUrl + 'listings/filter?page=' + pageNo + '&per_page=' + pageSize , model);
    }

    public getFilterByLocation(model): Observable<CommonAPIResponse> {
        let data;
        if (model.latitude !== undefined && model.query !== undefined) {
            data = {
                query: model.query,
                latitude: model.latitude,
                longitude: model.longitude
            };
        } else if (model.query !== undefined && model.latitude === undefined) {
            data = {
                query: model.query,
            };
        } else if (model.latitude !== undefined && model.query === undefined) {
            data = {
                latitude: model.latitude,
                longitude: model.longitude
            };
        } else {
            data = {};
        }
        console.log(data);
        return this.http.post<any>(this.apiUrl + 'listings/filter', data);
    }

    public getBusinessDetailById(id: string): Observable<CommonAPIResponse> {
        return this.http.get<any>(this.apiUrl + 'listings/listing/' + id);
    }

    public getOrderDetailById(id: string): Observable<CommonAPIResponse> {
        return this.http.get<any>(this.apiUrl + 'listings/listing/' + id + '/menu');
    }

    public getItemById(storeId: string, itemId: string): Observable<CommonAPIResponse> {
        return this.http.get<any>(this.apiUrl + 'listings/listing/' + storeId + '/menu_items/' + itemId);
    }

    public getAllFulfillmentTypes(): Observable<CommonAPIResponse> {
        return this.http.get<any>(this.apiUrl + 'listings/fulfillment_types');
    }

    public getAllTags(): Observable<CommonAPIResponse> {
        return this.http.get<any>(this.apiUrl + 'listings/tags');
    }

    public getAllCategories(): Observable<CommonAPIResponse> {
        return this.http.get<any>(this.apiUrl + 'listings/categories');
    }

    public getSearchMenuItems(storeId, searchText): Observable<CommonAPIResponse> {
        return this.http.get<any>(this.apiUrl + '/listings/listing/' + storeId + '/menu_items?search=' + searchText);
    }

    // --------------------------------- Cart API ---------------------------------
    public getCartDetail(): Observable<CommonAPIResponse> {
        return this.http.get<any>(this.apiUrl + 'cart');
    }

    public addCartItemDetailById(storeId: string, itemId: string, data: any): Observable<CommonAPIResponse> {
        return this.http.post<any>(this.apiUrl + 'cart/add_item/' + storeId + '/' + itemId, data);
    }

    public updateCartItemDetailById(storeId: string, itemId: string, data: any): Observable<CommonAPIResponse> {
        return this.http.post<any>(this.apiUrl + 'cart/update_item/' + storeId + '/' + itemId, data);
    }

    public repeatItemDetailById(storeId: string, itemId: string, data: object): Observable<CommonAPIResponse> {
        return this.http.post<any>(this.apiUrl + 'cart/repeat_item/' + storeId + '/' + itemId, null);
    }

    public removeItemDetailById(storeId: string, itemId: string, data: object): Observable<CommonAPIResponse> {
        return this.http.post<any>(this.apiUrl + 'cart/remove_item/' + storeId + '/' + itemId, null);
    }

    public deleteItemCartDetailById(storeId: string, cartItemId: string, data: object): Observable<CommonAPIResponse> {
        return this.http.post<any>(this.apiUrl + 'cart/delete_item/' + storeId + '/' + cartItemId , null);
    }

    public clearItemCartDetailById(data: object): Observable<CommonAPIResponse> {
        return this.http.post<any>(this.apiUrl + 'cart/clear/', null);
    }

    public getDiscount(id: string): Observable<CommonAPIResponse> {
        return this.http.get<any>(this.apiUrl + 'cart/apply_discount/' + id );
    }

    public removeDiscount(): Observable<CommonAPIResponse> {
        return this.http.get<any>(this.apiUrl + 'cart/remove_discount');
    }

    // --------------------------------- Group Order API ---------------------------------
    public getGroupOrderAll(): Observable<CommonAPIResponse> {
        return this.http.get<any>(this.apiUrl + 'group_order/carts');
    }

    public startGroupOrder(storeId, data: object): Observable<CommonAPIResponse> {
        return this.http.post<any>(this.apiUrl + 'group_order/start/' + storeId, data);
    }

    public inviteGroupOrder(id: string, data: object): Observable<CommonAPIResponse> {
        return this.http.post<any>(this.apiUrl + 'group_order/' + id  + '/invite/', data);
    }

    public getGroupOrders(groupId: string): Observable<CommonAPIResponse> {
        return this.http.get<any>(this.apiUrl + 'group_order/' + groupId + '/carts');
    }

    public joinGroupOrders(groupId: string): Observable<CommonAPIResponse> {
        return this.http.get<any>(this.apiUrl + 'group_order/' + groupId + '/join');
    }

    public leaveGroupOrders(groupId: string): Observable<CommonAPIResponse> {
        return this.http.get<any>(this.apiUrl + 'group_order/' + groupId + '/leave');
    }

    public cancelGroupOrders(groupId: string): Observable<CommonAPIResponse> {
        return this.http.get<any>(this.apiUrl + 'group_order/' + groupId + '/cancel');
    }

    public updateGroupOrderType(groupId: string, data: object): Observable<CommonAPIResponse> {
        return this.http.post<any>(this.apiUrl + 'group_order/' + groupId + '/update_order_type', data);
    }

    public addGroupOrderItem(groupId: string, cartId: string, data: object): Observable<CommonAPIResponse> {
        return this.http.post<any>(this.apiUrl + 'group_order/' + groupId + '/add_item/' + cartId, data);
    }

    public deleteGroupOrderItem(groupId: string, cartId: string, cartItemId: string): Observable<CommonAPIResponse> {
        return this.http.post<any>(this.apiUrl + 'group_order/' + groupId + '/delete_item/' + cartId + '/' + cartItemId, null);
    }

    public deleteGroupMember(groupId: string, cartCustomerId: string): Observable<CommonAPIResponse> {
        return this.http.post<any>(this.apiUrl + 'group_order/' + groupId + '/remove_cart/' + cartCustomerId, null);
    }

    public removeGroupOrderItem(groupId: string, cartId: string, cartItemId: string): Observable<CommonAPIResponse> {
        return this.http.post<any>(this.apiUrl + 'group_order/' + groupId + '/remove_item/' + cartId + '/' + cartItemId, null);
    }

    public repeatGroupOrderItem(groupId: string, cartId: string, cartItemId: string): Observable<CommonAPIResponse> {
        return this.http.post<any>(this.apiUrl + 'group_order/' + groupId + '/repeat_item/' + cartId + '/' + cartItemId, null);
    }

    public confirmGroupOrderItem(groupId: string, cartId: string, data: object): Observable<CommonAPIResponse> {
        return this.http.post<any>(this.apiUrl + 'group_order/' + groupId + '/submit_cart/' + cartId, data);
    }

    public applyGroupOrderDiscount(groupId: string, discountId: string): Observable<CommonAPIResponse> {
        return this.http.get<any>(this.apiUrl + 'group_order/' + groupId + '/apply_discount/' + discountId);
    }

    public removeGroupOrderDiscount(groupId: string): Observable<CommonAPIResponse> {
        return this.http.get<any>(this.apiUrl + 'group_order/' + groupId + '/remove_discount');
    }

    public applyGroupOrderCoupon(groupId: string, couponId: string): Observable<CommonAPIResponse> {
        return this.http.get<any>(this.apiUrl + 'group_order/' + groupId + '/apply_coupon/' + couponId);
    }

    public removeGroupOrderCoupon(groupId: string): Observable<CommonAPIResponse> {
        return this.http.get<any>(this.apiUrl + 'group_order/' + groupId + '/remove_coupon');
    }

    public groupOrderPaymentIntent(groupId: string): Observable<CommonAPIResponse> {
        return this.http.post<any>(this.apiUrl + 'group_order/' + groupId + '/checkout', null);
    }

    public groupPlaceOrder(groupId: string, data: object): Observable<CommonAPIResponse> {
        return this.http.post<any>(this.apiUrl + 'group_order/' + groupId + '/place_order', data);
    }

    // --------------------------------- Notification API ---------------------------------
    public getAllNotifications(): Observable<CommonAPIResponse>{
        return this.http.get<any>(this.apiUrl + 'notifications?type=');
    }

    public getSingleNotifications(notificationId: string): Observable<CommonAPIResponse>{
        return this.http.get<any>(this.apiUrl + 'notifications/get/' + notificationId);
    }

    public setAsReadNotifications(notificationId: string): Observable<CommonAPIResponse>{
        return this.http.get<any>(this.apiUrl + 'notifications/read/' + notificationId);
    }

    public getReadAllNotifications(): Observable<CommonAPIResponse>{
        return this.http.get<any>(this.apiUrl + 'notifications/read_all');
    }

    public deleteNotifications(notificationId: string): Observable<CommonAPIResponse>{
        return this.http.get<any>(this.apiUrl + 'notifications/delete/' + notificationId);
    }

    public deleteAllNotifications(): Observable<CommonAPIResponse>{
        return this.http.get<any>(this.apiUrl + 'notifications/delete_all');
    }
}

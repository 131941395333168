import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { CommonAPIResponse } from '../../../shared/Models/common.Model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AppHeaderCartService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  public getCartDetail(): Observable<CommonAPIResponse> {
    return this.http.get<any>(this.apiUrl + 'cart');
  }

  public repeatItemCartDetailById(storeId: string, cartItemId: string, data: object): Observable<CommonAPIResponse> {
    return this.http.post<any>(this.apiUrl + 'cart/repeat_item/' + storeId + '/' + cartItemId, null);
  }

  public removeItemCartDetailById(storeId: string, cartItemId: string, data: object): Observable<CommonAPIResponse> {
    return this.http.post<any>(this.apiUrl + 'cart/remove_item/' + storeId + '/' + cartItemId, null);
  }

  public deleteItemCartDetailById(storeId: string, cartItemId: string, data: object): Observable<CommonAPIResponse> {
    return this.http.post<any>(this.apiUrl + 'cart/delete_item/' + storeId + '/' + cartItemId, null);
  }

  public clearItemCartDetailById(storeId: string, data: object): Observable<CommonAPIResponse> {
    return this.http.post<any>(this.apiUrl + 'cart/clear/' + storeId, null);
  }

  public getCartPaymentIntent(): Observable<CommonAPIResponse> {
    return this.http.post<any>(this.apiUrl + 'cart/checkout', null);
  }

  // -------------------------------- Get Geo Location --------------------------------
  public getGeoLocation(lat: number, lng: number) {
    let location: any;
    if (navigator.geolocation) {
        let geocoder = new google.maps.Geocoder();
        let latlng = new google.maps.LatLng(lat, lng);
        let request = {
          'location': latlng
        };
        geocoder.geocode(request, (results) => {
          if (results[0]) {
            console.log(location);
            location = results[0];
          }
        });  
    }
    return location;
  }

  // ------------------------------------ Get Contact Details ------------------------------------
  public getContactDetails() {
    return this.http.get<any>(this.apiUrl + 'contact');
  }
}


import { Injectable } from '@angular/core';
import Bugsnag from '@bugsnag/js';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class Constants {

    constructor(private router: Router){

    }

    // store status
    public static StoreOpen = 'Store is open';
    public static StoreClose = 'Store is closed';
    public static StoreDelay = 'Restaurant is currently not accepting orders';
    public static StoreOpenCheckout = 'Open';
    public static StoreCloseCheckout = 'Closed';
    public static StoreDelayCheckout = 'Stop Taking Orders';

    public static StoreClosePopup = 'Note: Store is closed';
    public static StoreDelayPopup = 'Note: Restaurant is currently not accepting orders';

    // dataLoad array for order-list
    public static dataLoaderArray = ['1', '2', '3', '4', '5'];
    public static addressLoaderArray = ['1', '2', '3', '4'];

    // toast message
    public static toastSuccess = 'Success';
    public static toastError = 'Failed';
    public static toastInfo = 'Oops!!';
    public static toastInfoMessage = 'The restaurant is currently not delivering to your location';

    // mask pattern
    public static maskMobilePattern = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    public static emailPattern = '[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$';

    // places components
    public static placeComponents: any = {
        locality: 'long_name',
        sublocality_level_1: 'long_name',
        postal_town: 'long_name',
        administrative_area_level_1: 'short_name',
        country: 'short_name',
        postal_code: 'short_name'
    };

    // auto-complete elements
    public static autoCompleteElements: any = {
        locality: 'customer_city',
        sublocality_level_1: 'customer_city',
        postal_town: 'customer_city',
        administrative_area_level_1: 'customer_state',
        country: 'customer_country',
        postal_code: 'customer_zipcode'
    };

    // map options
    public static mapOptions: google.maps.MapOptions = {
        center: new google.maps.LatLng(40.80, -73.70),
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        panControl: false,
        streetViewControl: false,
        gestureHandling: 'cooperative',

        styles: [
            {
                featureType: 'administrative',
                stylers: [
                    {
                        visibility: 'off'
                    }
                ]
            },
            {
                featureType: 'poi',
                stylers: [
                    {
                        visibility: 'simplified'
                    }
                ]
            },
            {
                featureType: 'road',
                elementType: 'labels',
                stylers: [
                    {
                        visibility: 'simplified'
                    }
                ]
            },
            {
                featureType: 'water',
                stylers: [
                    {
                        visibility: 'simplified'
                    }
                ]
            },
            {
                featureType: 'transit',
                stylers: [
                    {
                        visibility: 'simplified'
                    }
                ]
            },
            {
                featureType: 'landscape',
                stylers: [
                    {
                        visibility: 'simplified'
                    }
                ]
            },
            {
                featureType: 'road.highway',
                stylers: [
                    {
                        visibility: 'off'
                    }
                ]
            },
            {
                featureType: 'road.local',
                stylers: [
                    {
                        visibility: 'on'
                    }
                ]
            },
            {
                featureType: 'road.highway',
                elementType: 'geometry',
                stylers: [
                    {
                        visibility: 'on'
                    }
                ]
            },
            {
                featureType: 'water',
                stylers: [
                    {
                        color: '#84afa3'
                    },
                    {
                        lightness: 52
                    }
                ]
            },
            {
                stylers: [
                    {
                        saturation: -17
                    },
                    {
                        gamma: 0.36
                    }
                ]
            },
            {
                featureType: 'transit.line',
                elementType: 'geometry',
                stylers: [
                    {
                        color: '#3f518c'
                    }
                ]
            }
        ]
    };

    // marker option
    public static markerOptions = {
        draggable: false,
        icon: './assets/images/pin.png',
    };

    // place placeholder image
    public static placePlaceholderImage = 'https://chatmyorder.blob.core.windows.net/cmo/store/logo/place-placeholder.jpg';

    // selected tab for business-detail's page nav bars
    public static seletedTab = 'OrderOnline';

    // location option
    public static locationOptions: google.maps.MapOptions = {
        zoom: 15,
        center: new google.maps.LatLng(40.80, -73.70),
        scrollwheel: false,
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        panControl: false,
        // navigationControl: false,
        streetViewControl: false,
        styles: [
            {
                featureType: 'administrative',
                stylers: [
                {
                    visibility: 'off'
                }
                ]
            },
            {
                featureType: 'poi',
                stylers: [
                {
                    visibility: 'simplified'
                }
                ]
            },
            {
                featureType: 'road',
                elementType: 'labels',
                stylers: [
                {
                    visibility: 'simplified'
                }
                ]
            },
            {
                featureType: 'water',
                stylers: [
                {
                    visibility: 'simplified'
                }
                ]
            },
            {
                featureType: 'transit',
                stylers: [
                {
                    visibility: 'simplified'
                }
                ]
            },
            {
                featureType: 'landscape',
                stylers: [
                {
                    visibility: 'simplified'
                }
                ]
            },
            {
                featureType: 'road.highway',
                stylers: [
                {
                    visibility: 'off'
                }
                ]
            },
            {
                featureType: 'road.local',
                stylers: [
                {
                    visibility: 'on'
                }
                ]
            },
            {
                featureType: 'road.highway',
                elementType: 'geometry',
                stylers: [
                {
                    visibility: 'on'
                }
                ]
            },
            {
                featureType: 'water',
                stylers: [
                {
                    color: '#84afa3'
                },
                {
                    lightness: 52
                }
                ]
            },
            {
                stylers: [
                {
                    saturation: -17
                },
                {
                    gamma: 0.36
                }
                ]
            },
            {
                featureType: 'transit.line',
                elementType: 'geometry',
                stylers: [
                {
                    color: '#3f518c'
                }
                ]
            }
        ]
    };

    // -------------------------------- Business Link --------------------------------
    public static businessLink = environment.BUSINESS_PORTAL_URL;
    public static businessRegistLink = environment.BUSINESS_PORTAL_URL + 'auth/register';

    // -------------------------------- Login msg --------------------------------
    public static signInMsg = 'Login successful';

    // -------------------------------- Login msg --------------------------------
    public static addressUpdateMsg = 'Address updated successfully';

    // -------------------------------- Contact us --------------------------------
    public static phoneNo = '+1 (205) 883-2917';
    public static webSite = 'www.chatmyorder.com';
    public static emailAdd = 'sales@chatmyorder.com';
    public static contAddress = '69 W 137th Street, New York, NY 10037';

    public static phoneNoLink = 'tel:+12058832917';
    public static emailAddLink = 'mailto:sales@chatmyorder.com';

    public static playStoreLink = 'www.chatmyorder.com';
    public static appStoreLink = '69 W 137th Street, New York, NY 10037';

    public static nextGenLink = 'http://nextgenuscorp.com/';

    // -------------------------------- Validation --------------------------------
    public static userReq = '* Username is required';
    public static firstName = '* Firstname is required';
    public static lastName = '* Lastname is required';
    public static emailReq = '* Email is required';
    public static emailPat = '* Email is not valid';
    public static mobileReq = '* Mobile is required';
    public static mobileLen = '* Mobile length must be 10 numbers';
    public static passReq = '* Password is required';
    public static passLen = '* Password length must be at least 6 characters long';

    public static curReq = '* Current Password is required';
    public static newReq = '* New Password is required';
    public static newLen = '* New Password length must be at least 6 characters long';
    public static newCur = '* New password can not be same as current password';
    public static conReq = '* Confirm Password is required';
    public static conMatch = '* Confirm Password do not match';

    public static labelReq = '* Label Name is required';
    public static streetReq = '* Street Name is required';
    public static cityReq = '* City Name is required';
    public static stateReq = '* State Name is required';
    public static countryReq = '* Country Name is required';
    public static zipReq = '* Zipcode is required';

    // -------------------------------- Validation --------------------------------
    public static sessionExpire = 'Please login for continue';
    public static sessionExpireHeader = 'Sorry!!!';

    // -------------------------------- Validation Image Message --------------------------------
    public static headerCartEmptyMsg = 'Your Cart is empty';
    public static orderListEmptyMsg = 'Your order list is empty';
    public static addressEmptyMsg = 'No address available';
    public static businessEmptyMsg = 'Oops!!! search again';

    public static userOrderPageSize = 3;
    public static businessPageSize = 10;

    // -------------------------------- Letters only method --------------------------------
    public static latterOnly(event): boolean{
        console.log(event);
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode !== 32 && (charCode < 65 || charCode > 90) && (charCode < 97 || charCode > 122)) {
            console.log(charCode);
            return false;
        }
        return true;
    }

    // -------------------------------- Number only method --------------------------------
    public static numberOnly(event): boolean{
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    // ----------------------------------- Method for display variant and topping details -----------------------------------
    public static getOrderVariantToppingDetail(od, name){
        const top: any = [];
        if (name === 'topping'){
            od.toppings.forEach(data => {
                top.push(data.name);
            });
        } else {
            od.variants.forEach(data => {
                const temp = data.name + ' : ' + data.option.name;
                top.push(temp);
            });
        }
        return top.join('; ');
    }

    // ----------------------------------- Method for display variant and topping total -----------------------------------
    public static getOrderVariantToppingTotal(od, name){
        let top = 0;
        if (name === 'topping'){
            od.toppings.forEach(data => {
                top = top + data.additional_price;
            });
        } else {
            od.variants.forEach(data => {
                top = top + data.option.additional_price;
            });
        }
        return top;
    }

    // -------------------------------- Find Distance between  --------------------------------
    public static getDistanceBetweenPoints(lat1, lng1, lat2, lng2){

        const RADIANS: number = 180 / 3.14159265;

        const lt1 = lat1 / RADIANS;
        const lg1 = lng1 / RADIANS;
        const lt2 = lat2 / RADIANS;
        const lg2 = lng2 / RADIANS;

        const result = (3958.8) * Math.acos(Math.sin(lt1) * Math.sin(lt2) + Math.cos(lt1) * Math.cos(lt2) * Math.cos(lg2 - lg1));

        return result;
    }

    // -------------------------------- Avatar call  --------------------------------
    public static avatraCall(proRes) {
        if (proRes.data.firstname || proRes.data.lastname){
            // get first and last name for my account image
            const firstname = proRes.data.firstname;
            const lastname = proRes.data.lastname;
            const initials = firstname.charAt(0).toUpperCase() + '' + lastname.charAt(0).toUpperCase();
            console.log(initials);
            localStorage.setItem('cmo:account-avatar', initials);
            localStorage.setItem('cmo:profile-firstname', proRes.data.firstname);

        } else if (proRes.firstname || proRes.lastname){
            const firstname = proRes.data.firstname;
            const lastname = proRes.data.lastname;
            const initials = firstname.charAt(0).toUpperCase() + '' + lastname.charAt(0).toUpperCase();
            console.log(initials);
            localStorage.setItem('cmo:account-avatar', initials);
            localStorage.setItem('cmo:profile-firstname', proRes.data.firstname);

        } else {
            const initials = 'NU';
            const profileFirstname = 'User';
            localStorage.setItem('cmo:account-avatar', initials);
            localStorage.setItem('cmo:profile-firstname', profileFirstname);
        }
    }

    
}

